import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { State } from "@progress/kendo-data-query";
import AdminService from "../../services/AdminService";
import { DialogContent } from "@material-ui/core";
import AdvancedGrid from "../grid/AdvancedGrid";
import Client from "../../models/Client";
import { IResponseModel } from "../../models/ResponseInfo";
import { GridCellProps, GridColumnProps, Grid, GridColumn, GridFilterChangeEvent } from "@progress/kendo-react-grid";
import { NavLink, useHistory } from "react-router-dom";
interface IProps {
    chooseClient(client: Client): void
}

type Props = IProps;

const ReviewImport = (props: Props) => {

    const [loading, setLoading] = React.useState<boolean>(false);
    const [clients, setClients] = React.useState<Client[]>([]);
    const [count, setCount] = React.useState(0);
    const [dataState, setDataState] = React.useState<State>({ take: 100, skip: 0 });
    const history = useHistory();
    const getClientList = (dataState: State) => {
        setLoading(true);
        const result = AdminService.getClients(dataState.skip, dataState.take, dataState.filter)
            .then(function (response) {
                setLoading(false);
                const clientData = response!.data as IResponseModel<Client>;
                if (clientData != null) {
                    setClients(clientData.queryResult as Client[]);
                    setCount(clientData.totalCount)
                }
            })
    }
    const clientNameCell = (gridCellProps: GridCellProps) => {
        return (
            <td>
                <label onClick={(e) => {
                    const client = { id: gridCellProps.dataItem["id"], clientName: gridCellProps.dataItem["clientName"] } as Client;
                    if (props.chooseClient) {
                        props.chooseClient(client);
                    }
                }}>
                    {gridCellProps.dataItem["clientName"]}
                </label></td>
        )
    }
    const gridFilterChange = (event: GridFilterChangeEvent) => {
        const newState = { ...dataState, skip: 0, take: 100, filter: event.filter };
        setDataState(newState);
    };
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );
    const changePagination = (skip: number, take: number) => {
        setDataState({ ...dataState, skip: skip, take: take });
    }
    useEffect(() => {
        getClientList(dataState);
    }, [dataState]);
    // const cancelUpload = () => { setVisible1(false); }
    const columns: Array<GridColumnProps> = [
        { field: "clientName", title: "Client Name", editable: false, sortable: false, cell: clientNameCell, filterable:true }
    ];
    return (
        <div>
            <DialogContent>
                <div className="client-list">
                    {loading && loadingPanel}
                    <AdvancedGrid data={clients} columns={columns} Title="Select a Clients" changePagination={changePagination} dataState={dataState} totalCount={count} filterable={true}
                        filterOperators={{
                            text: [{ text: "grid.filterContainsOperator", operator: "contains" }]
                        }}
                        onFilterChange={gridFilterChange}/>
                </div>
            </DialogContent>
        </div>

    )
}
export default ReviewImport;