import { GridCellProps, GridRowProps } from '@progress/kendo-react-grid';
//import { Product } from './interfaces';
import * as React from 'react';
import CommunicatorIndexList from '../../models/CommunicatorIndexList';

interface CellRenderProps<T> {
    originalProps: GridCellProps,
    td: React.ReactElement<HTMLTableCellElement>,
    enterEdit: (dataItem: T, field: string | undefined) => void,
    editField: string | undefined,
}

interface RowRenderProps {
    originalProps: GridRowProps,
    tr: React.ReactElement<HTMLTableRowElement>,
    exitEdit: (id: number) => void,
    editField: string | undefined
}

export const CellRender = <T extends object> (props: CellRenderProps<T>) => {
    const dataItem = props.originalProps.dataItem;
    const cellField = props.originalProps.field;
    const inEditField = dataItem[props.editField || ''];
    const additionalProps = cellField && cellField === inEditField ?
        {
            ref: (td: any) => {
                const input = td && td.querySelector('input');
                const activeElement = document.activeElement;

                if (!input ||
                    !activeElement ||
                    input === activeElement ||
                    !activeElement.contains(input)) {
                    return;
                }

                if (input.type === 'checkbox') {
                    input.focus();
                } else {
                    input.select();
                }
            }
        } : {
            onClick: () => { props.enterEdit(dataItem, cellField); }
        };

    const clonedProps: any = { ...props.td.props, ...additionalProps };
    return React.cloneElement(props.td, clonedProps, props.td.props.children);
}

export const RowRender = (props: RowRenderProps) => {
    const trProps = {
        ...props.tr.props,
        onBlur: () => {
            props.exitEdit(props.originalProps.dataItem.id);
        }
    };
    return React.cloneElement(props.tr, { ...trProps }, props.tr.props.children);
}