import React from "react";
import ReactDOM from "react-dom";
import { Form, Field } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Upload } from "@progress/kendo-react-upload";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import axios from "axios";
import $ from 'jquery';
import { DialogContent } from "@material-ui/core";
import AdminService from "../../services/AdminService";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import bgMessages from '../../Localization/en.json';
import ReviewImport from "../files/ReviewImport";
import FileUploadResponse from "../../models/FileUploadResponse";
import ConfirmationWarningImport from "./ConfirmationWarningImport";
loadMessages(bgMessages, 'en-US');

interface IProps {
    pageType: string,
    clientId: number,
    setVisible: (value: boolean) => void;
    sendStatus?: (isImpSuccess: boolean) => void;
    gdOption: string;
}
interface LocaleInterface {
    language: string;
    locale: string;
}
type Props = IProps;

const FileUpload = (props: Props) => {
    const [fileuploadResponse, setfileuploadResponse] = React.useState<FileUploadResponse>();
    const [disableSubmit, setDisableSubmit] = React.useState<boolean>(true);
    const [visible, setVisible] = React.useState<boolean>(false);
    const [confirmMsg, setConfirmMsg] = React.useState<string>();
    const [validFileName, setValidFileName] = React.useState<string>();
    const UploadInput = (fieldRenderProps: any) => {
        const onChangeHandler = (event: any) => {
            fieldRenderProps.onChange({ value: event.newState });
            setDisableSubmit(false);
            $("#uploadSuccessLbl").css("display", "none");
        };
        const onRemoveHandler = (event: any) => {
            fieldRenderProps.onChange({ value: event.newState });
            setDisableSubmit(true);
        };
        const onProgressHandler = (event: any) => {
            fieldRenderProps.onChange({ value: event.newState });
        };
        const locales: LocaleInterface[] = [
            {
                language: "en-US",
                locale: "en",
            },
        ];

        const [currentLocale, setCurrentLocale] = React.useState<LocaleInterface>(
            locales[0]
        );

        return (
            <LocalizationProvider language={currentLocale.language}>
                <IntlProvider locale={currentLocale.locale} >
                    <Upload
                        batch={false}
                        multiple={false}
                        restrictions={{
                            allowedExtensions: [".csv"],
                        }}
                        autoUpload={false}
                        showActionButtons={false}
                        files={fieldRenderProps.value}
                        onAdd={onChangeHandler}
                        onRemove={onRemoveHandler}
                        onProgress={onProgressHandler} />
                </IntlProvider>
            </LocalizationProvider>
        );
    };
    const handleSubmit = (dataItem: any) => {
        setDisableSubmit(true);
        $('.fileloading').addClass('k-i-loading');
        $('.fileloading').removeClass('k-i-export');
        const { files } = dataItem;
        const formData = new FormData();
        formData.append('formFile', files[0].getRawFile());
        formData.append('fileName', files[0].name);
        // submit your formData
        const result = AdminService.uploadFile(formData, props.clientId, props.pageType, props.gdOption)
            .then(function (response: any) {
                if (response.data.isSuccess) {
                    // Based on response data check its length before replacing the existing list
                    if (response.data.hasThreshold) {
                        setVisible(true);
                        setConfirmMsg(response.data.message);
                        setValidFileName(response.data.validFileName)
                    }
                    else {
                        props.sendStatus && props.sendStatus(true);

                    }
                }
                else {
                    $('.k-progressbar').addClass('k-fileprogressbar');

                    console.log(response);
                    setfileuploadResponse(response.data);

                    $("#uploadSuccessLbl").css("display", "block");
                    $('.fileloading').removeClass('k-i-loading');
                    $('.fileloading').addClass('k-i-export');
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    };
    return (
        <Form
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
                <form onSubmit={formRenderProps.onSubmit} className={"k-form"}>
                    <DialogContent>
                        <fieldset>
                            <div className="mb-3">
                                Select a file to upload
                                <Field name={"files"} component={UploadInput} />
                            </div>
                        </fieldset>
                        <div>
                            {fileuploadResponse && (fileuploadResponse.isSuccess ?
                                <span id="uploadSuccessLbl" style={{ color: "green", display: "none" }}> <label>File Uploaded Succesfully</label></span> :
                                (fileuploadResponse.totalValidRecs ==-1 ? (<span id="uploadSuccessLbl" style={{ color: "red", display: "none" }}> <label>{fileuploadResponse.messages}</label></span>) :
                                    (<ReviewImport sendStatus={props.sendStatus} totalRecs={fileuploadResponse.totalRecs} totalValidRecs={fileuploadResponse.totalValidRecs} totalNotValidRecs={fileuploadResponse.totalNotValidRecs}
                                        NotValidRecsMsg={fileuploadResponse.messages} errorFileName={fileuploadResponse.errorFileName} validFileName={fileuploadResponse.validFileName} pageType={props.pageType} clientId={props.clientId} setVisible={props.setVisible} gdOption={props.gdOption} />)))}
                        </div>
                        {visible && <ConfirmationWarningImport confirmMsg={confirmMsg} gdOption={props.gdOption} validFileName={validFileName} setVisible={props.setVisible} sendStatus={props.sendStatus} pageType={props.pageType} />}
                    </DialogContent>
                    <DialogActionsBar layout={"end"}>

                        <Button
                            type={"submit"}
                            themeColor={"primary"}
                            disabled={disableSubmit}>
                            <span className="k-icon k-i-export k-icon-16 fileloading" style={{ marginBottom: 5 }} /> Upload
                        </Button>
                    </DialogActionsBar>
                </form>
            )}
        />
    );
};
export default FileUpload;
