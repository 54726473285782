import * as React from "react";

import {
    DropDownList,
    DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";

interface DropdownFilterCellProps<T> extends GridFilterCellProps {
    data: Array<T>;
    textField?: string;
    defaultSelectedOption?: T;
}

export const DropdownFilterCell = <T,> (props: DropdownFilterCellProps<T>) => {
    let hasValue: any = (value:any) => Boolean(value );

    const onChange = (event: DropDownListChangeEvent) => {
        hasValue = hasValue(event.target.value);
        props.onChange({
            value: hasValue ? event.target.value : "",
            operator: hasValue ? "eq" : "",
            syntheticEvent: event.syntheticEvent,
        });
    };

    const onClearButtonClick = (event:any) => {
        event.preventDefault();
        props.onChange({
            value: "",
            operator: "",
            syntheticEvent: event,
        });
    };
    return (
        <div className="k-filtercell pad">
            <span>
            <DropDownList
                data={props.data}
                onChange={onChange}
                value={props.value || props.defaultSelectedOption}
                textField={props.textField}
                dataItemKey={props.textField}
            />
            <Button
                title="Clear"
                disabled={!hasValue(props.value)}
                onClick={onClearButtonClick}
                icon="filter-clear"
                />
            </span>
        </div>
    );
};
