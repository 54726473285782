import * as React from 'react';
import { Route } from 'react-router-dom';
import Layout from './components/Layout';
import { Routing } from "./Routing";
import { RouteType } from "./models/Routing";
import "./App.scss";
import Login from './components/Login/login';
import { useHistory }  from "react-router-dom";
import AuthVerify from './services/AuthVerify';


declare global {
    interface Window {
        ApplicationVariables: any;
    }
}
export default () => {
    const history = useHistory();
    if (AuthVerify()) {
        return <Layout>
        </Layout>
    } else {
        localStorage.clear();
        history.push('/login');
        return <Login />
    }
};

