import { ResponseMessages, ServiceBase, ServiceResponseJson } from "./ServiceBase";
import axios from "axios";
import CommunicatorIndexList, { IDocumentsFieldMapping } from "../models/CommunicatorIndexList";
import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import GlobalDomainList from "../models/GlobalDomainList";
import Project from "../models/Project";
import ResponseInfo from "../models/ResponseInfo";
import ClientDomainList from "../models/ClientDomainList";
import authHeader from "./authHeader";
import ClientCommunicatorList from "../models/ClientCommunicatorList";

const UploadCSVMessages: ResponseMessages = {
    FailedMessage: "Upload CSV failed.",
    SuccessMessage: "Upload CSV succeeded."
}

const GetGlobalDomainListMessages: ResponseMessages = {
    FailedMessage: "Get Global Domain List failed.",
    SuccessMessage: "Get Global Domain List succeeded."
};

const ApiUrls = {
    globalDomainListApiUrl: 'globalDomains/import',
    readGlobalDomainListApiUrl: 'globalDomains',
    clientDomainListApiUrl: 'clientDomains/import',
    clientDomainApiUrl: 'clientDomains',
    clientCommunicatorsListApiUrl: 'clientCommunicators/import',
    readClientCommunicatorListApiUrl: 'clientCommunicators',
    documentListApiUrl: 'documents/getFile',
    documentListImportApiUrl: 'documents/import',
    processCommunicatorListApiUrl: 'projects/buildCommIndex',
    globalDLProceedApiUrl: 'globalDomains/resumeImport',
    downloadErrorLogFile: 'globalDomains/DownloadErrorLogFile',
    clientDLProceedApiUrl: 'clientDomains/resumeImport',
    downloadCDLErrorLogFile: 'clientDomains/DownloadErrorLogFile',
    communicatorIndicesApiUrl: 'documents/CommunicatorIndices',
    getMatchCommunicatorIndex: 'documents/getMatchCommunicatorIndex',
    exportDocumentData: 'documents/Export',
    getClients: 'clients',
    getAppVersion: 'version',
    projectslistApiUrl: 'projects',
    globalDomainApiUrl: 'globalDomains',
    createClient: 'clients/Create',
    createProject: 'projects',
    deleteGlobalDomainApiUrl: 'globalDomains/remove',
    deleteClientDomainApiUrl: 'clientDomains/remove',
    getClientDomainApiUrl: 'clientDomains/GetClientDomain',
    createAccessToken: 'users/authenticate', 
   
}
const CreateSessionMessages: ResponseMessages = {
    FailedMessage: "Failed to create access token.",
    SuccessMessage: "Create access token call succeeded."
}
export default class AdminService extends ServiceBase {

    static urls = {
        getUnMatchedData: 'documents/UnMatchedData',
        exportDocumentData: 'documents/Export',
        readClientDomainListApiUrl: 'clientDomains',
        communicatorIndicesApiUrl: 'documents/CommunicatorIndices',
        getMatchCommunicatorIndex: 'documents/getMatchCommunicatorIndex',
        beginChunkImport: 'imports/begin',
        endChunkImport: 'imports/finalize',
        uploadChunk: 'imports',
        readClientCommunicatorListApiUrl: 'clientCommunicators',
        getProgressStatus: 'jobs/get',
        getImportDetails: 'imports',
        downloadCIErrorFile: 'jobs/DownloadFile',
        showDownloadIcon: 'jobs/DownloadIcon',
        getFileName: 'jobs/GetFileName',        
        getByProjectId: 'jobs/getByProjectId',
        downloadSavedCategoryFile: 'documents/GetDocuments',
        documentCIsApiUrl: 'documents/DocumentCommunicatorIndices',
        MatchedReferencesList: 'documents/MatchedReferencesList',
        getByProjectIdStatus: 'jobs/getByProjectIdStatus',
    }

    static async getData(url: string) {
        let documentData: ResponseInfo = new ResponseInfo();
        const res = await axios.get(AdminService.getBaseGlobalDomainUri() + url, { headers: { Authorization: authHeader() } })
            .then(function (response: any) {
                const responseData = response.data as ResponseInfo
                if (responseData != null && responseData.isSuccess) {
                    documentData = responseData;
                }
            })
            .catch(((error: any) => {
                if (error.response.status == 401) {
                    this.handleError(error);
                    return Promise.reject(error);
                }
                else {
                    documentData.errorMessage = error;
                    documentData.data = undefined;
                }
            }));
        return documentData;
    }
    static async postData(url: string, formData: FormData) {
        const headers = {
            'Content-Type': 'multipart/form-data', 'Authorization': authHeader()
        };
        const res = await axios.post(AdminService.getBaseGlobalDomainUri() + url, formData, { headers })
            .catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));
        return res;
    }

    static async postWithParameter(url: string, body: any) {
        let fullUrl = AdminService.getBaseGlobalDomainUri() + url;
        const headers = {
            'Content-Type': 'application/json', 'Authorization': authHeader()
        };
        
        const res = await axios.post(fullUrl, body, { headers })
            .catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));
        return res;
    }

    static async uploadFile(formData: FormData, clientId: number, pageType?: string, gdOption?:string) {
        let url = "";
        switch (pageType) {
            case "GlobalDomain":
                {
                    url = AdminService.getBaseGlobalDomainUri() + ApiUrls.globalDomainListApiUrl + "/" + gdOption;
                    break;
                }
            case "ClientDomain":
                {
                    url = AdminService.getBaseGlobalDomainUri() + ApiUrls.clientDomainListApiUrl +"/"+clientId;
                    break;
                }
            case "ClientCommunicator":
                {
                    url = AdminService.getBaseGlobalDomainUri() + ApiUrls.clientCommunicatorsListApiUrl + "/" + clientId;
                    break;
                }
            case "InputDocument":
                {
                    url = AdminService.getBaseGlobalDomainUri() + ApiUrls.documentListApiUrl;
                    break;
                }
        }
        const res = axios.post(url, formData, 

            {
                headers: {
                    'Content-Type': 'multipart/form-data', 'Authorization': authHeader()
                }
            }).catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));

        return res;
    }
    static async uploadFieldMapping(projectId: number, clientId: number, formData: FormData) {
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.documentListImportApiUrl + "/" + projectId + "/" + clientId;
        const headers = {
            'Content-Type': 'multipart/form-data', 'Authorization': authHeader()
        };
        const res = await axios.post(url,
            formData,
            { headers }
        ).catch(((error: any) => {
            this.handleError(error);
            console.log(error);
            return Promise.reject(error);
        }));
        return res;
    }

    static async createAccessToken(userName: string, password: string) {
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.createAccessToken;
        const result: ServiceResponseJson = await fetch(url, {
            body: this.toPostBody({ userName, password }),
            ...this.fetchPostOptions()
        })
            .then(async (response) => await this.handleResponseJson(response, CreateSessionMessages))
            .catch(async (response) => await this.handleResponseJson(response, CreateSessionMessages)); return result;

    }
   
    static async createClient(data:FormData) {
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.createClient;
        const headers = {
            'Content-Type': 'application/json', 'Authorization': authHeader()
        };
        const res = await axios.post(url, data, { headers })
            .then(response =>  response)
            .catch(((error) => {
                this.handleError(error);
                console.log(error)
                return Promise.reject(error);
            }));
        return res;
    }
   static async createProject(project:Project) {
       let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.createProject;
       const headers = {
           'Content-Type': 'application/json', 'Authorization': authHeader()
       };
       const res = await axios.post(url, project, { headers })
           .catch(((error: any) => {
               this.handleError(error);
               console.log(error);
               return Promise.reject(error);
           }));
        return res;
    }
    static async updateCommunicatorIndices(communicatorIndex: CommunicatorIndexList) {
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.communicatorIndicesApiUrl;
        const headers = {
            'Content-Type': 'application/json', 'Authorization': authHeader()
        };
        const res = await axios.post(url,
            JSON.stringify(communicatorIndex),
            { headers }
        ).catch(((error: any) => {
            this.handleError(error);
            console.log(error);
            return Promise.reject(error);
        }));
        return res;
    }
    static async exportDocumentData(projectId: number) {
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.exportDocumentData + "?projectId=" + projectId;
        const res = await axios.get(url, { headers: { Authorization: authHeader() } })
            .catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));

        return res;
    }
    static async exportGlobalDomainList() {
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.readGlobalDomainListApiUrl;
        const res = await axios.get(url, { headers: { Authorization: authHeader() } })
            .catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));

        return res;
    }

    static async getClientDomainList(clientId:number) {
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.clientDomainApiUrl + "?clientId=" + clientId;
        const res = await axios.get(url, { headers: { Authorization: authHeader() } })
            .catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));

        return res;
    }
    static async getClientCommunicatorList(clientId: number) {
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.readClientCommunicatorListApiUrl + "?clientId=" + clientId;
        const res = await axios.get(url, { headers: { Authorization: authHeader() } })
            .catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));

        return res;
    }

    static async getMatchCommunicatorList(projectId: number, clientId: number) {
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.getMatchCommunicatorIndex + "?projectId=" + projectId + "&clientId=" + clientId;
        const res = await axios.get(url, { headers: { Authorization: authHeader() } })
            .catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));

        return res;
    }
  
    static async resumeImport(pageType: string, fileName: string, clientId: number, gdOption?: string) {
        const headers = {
            'Content-Type': 'application/json', 'Authorization': authHeader()
        };
        let url = "";
        switch (pageType) {
            case "GlobalDomain":
                {
                    url = AdminService.getBaseGlobalDomainUri() + ApiUrls.globalDLProceedApiUrl + "?fileName=" + fileName + "&gdOption=" + gdOption;
                    break;
                }
            case "ClientDomain":
                {
                    url = AdminService.getBaseGlobalDomainUri() + ApiUrls.clientDLProceedApiUrl + "?clientId=" + clientId+ "&fileName=" + fileName;
                    break;
                }               
        }
        const res = await axios.post(url, fileName, { headers })
            .catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));

        return res;
    }
    static downloadErrorLogFile(pageType: string, fileName: string) {
        let url = "";
        switch (pageType) {
            case "GlobalDomain":
                {
                    url = AdminService.getBaseGlobalDomainUri() + ApiUrls.downloadErrorLogFile + "?fileName=" + fileName;
                    break;
                }
            case "ClientDomain":
                {
                    url = AdminService.getBaseGlobalDomainUri() + ApiUrls.downloadCDLErrorLogFile + "?fileName=" + fileName;
                    break;
                }
        }
        return url;
    }
    static async getCommunicatorIndex(projectId: number, skip?: number, take?: number, sortBy?: Array<SortDescriptor>) {
        const sortJSON = JSON.stringify(sortBy) || "";
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.communicatorIndicesApiUrl + "?projectId=" + projectId + "&skip=" + skip + "&take=" + take + "&sortBy=" + sortJSON;
        const res = await axios.get(url, { headers: { Authorization: authHeader() } })
            .catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));

        return res;
    }

    static async getProjectsList(projectId?: number) {
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.projectslistApiUrl + "/" + projectId;
        const res = await axios.get(url, { headers: { Authorization: authHeader() } })
            .catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));

        return res;
    }
    static async getClients(skip?: number, take?: number, filter?: CompositeFilterDescriptor) {
        const filterJSON = filter ? JSON.stringify([filter]) : "";
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.getClients + "?skip=" + skip + "&take=" + take+ "&filter=" + filterJSON;
        const res = await axios.get(url, { headers: { Authorization: authHeader() } })
            .catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));

        return res;
    }

    static async getAppVersion() {
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.getAppVersion;
        const v = await axios.get(url)
            .catch(((error:any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));
        return v;
    }

    static async getGlobalDomain(skip?: number, take?: number, sortBy?: Array<SortDescriptor>, filter?: CompositeFilterDescriptor) {
        const sortJSON = JSON.stringify(sortBy) || "";
        const filterJSON = filter ? JSON.stringify([filter]) : "";
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.globalDomainApiUrl + "?skip=" + skip + "&take=" + take + "&sortBy=" + sortJSON + "&filter=" + filterJSON;
        const res = await axios.get(url, { headers: { Authorization: authHeader() } })
            .catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));

        return res;
    }

    static async createGlobalDomain(globalDomain: GlobalDomainList) {
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.globalDomainApiUrl;
        const headers = {
            'Content-Type': 'application/json', 'Authorization': authHeader()
        };
        const res = await axios.post(url,
            JSON.stringify(globalDomain),
            { headers }
        ).catch(((error: any) => {
            this.handleError(error);
            console.log(error);
            return Promise.reject(error);
        }));
        return res;
    }

    static async deleteGlobalDomain(globalId?: number) {
        const headers = {
            'Content-Type': 'application/json', 'Authorization': authHeader()
        };
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.deleteGlobalDomainApiUrl + "?globalId=" + globalId;
        const res = await axios.delete(url, { headers }).catch(((error: any) => {
            this.handleError(error);
            console.log(error);
            return Promise.reject(error);
        }));

        return res;
    }

    static async getClientDomain(clientId: number, skip?: number, take?: number, sortBy?: Array<SortDescriptor>, filter?: CompositeFilterDescriptor) {
        const sortJSON = JSON.stringify(sortBy) || "";
        const filterJSON = filter ? JSON.stringify([filter]) : "";
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.getClientDomainApiUrl + "?clientId=" + clientId + "&skip=" + skip + "&take=" + take + "&sortBy=" + sortJSON + "&filter=" + filterJSON;
        const res = await axios.get(url, { headers: { Authorization: authHeader() } })
            .catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));

        return res;
    }

    static async createClientDomain(clientDomain: ClientDomainList) {
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.clientDomainApiUrl;
        const headers = {
            'Content-Type': 'application/json', 'Authorization': authHeader()
        };
        const res = await axios.post(url,
            JSON.stringify(clientDomain),
            { headers }
        ).catch(((error: any) => {
            this.handleError(error);
            console.log(error);
            return Promise.reject(error);
        }));
        return res;
    }

    static async deleteClientDomain(clientId?: number) {

        const headers = {
            'Content-Type': 'application/json', 'Authorization': authHeader()
        };
        
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.deleteClientDomainApiUrl + "?clientId=" + clientId;
        const res = await axios.delete(url, { headers })
            .catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));

        return res;
    }

    static async createClientCommunicatorList(clientCommunicatorList: ClientCommunicatorList) {
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.readClientCommunicatorListApiUrl;
        const headers = {
            'Content-Type': 'application/json', 'Authorization': authHeader()
        };
        const res = await axios.post(url,
            JSON.stringify(clientCommunicatorList),
            { headers }
        ).catch(((error: any) => {
            this.handleError(error);
            console.log(error);
            return Promise.reject(error);
        }));
        return res;
    }

    static async deleteClientCommunicatorList(clientId?: number) {

        const headers = {
            'Content-Type': 'application/json', 'Authorization': authHeader()
        };
        let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.readClientCommunicatorListApiUrl + "/" + clientId;
        const res = await axios.delete(url, { headers })
            .catch(((error: any) => {
                this.handleError(error);
                console.log(error);
                return Promise.reject(error);
            }));

        return res;
    }

    static baseUrl: string;
    static getBaseGlobalDomainUri() {

        var environment = process.env.REACT_APP_ENV as string;

        if (environment.toLowerCase() === 'local')
            return (process.env.REACT_APP_API_URL as string);

        if (!this.baseUrl)
            this.baseUrl = (new URL(window.location.href).origin + "/api/");

        return this.baseUrl;
    }
    static getCategories() {
       const   localizedData = [
            { text: "Non-Legal", value: "Non-Legal" },
            { text: "Legal", value: "Legal" },
            { text: "Generic", value: "Generic" },
            { text: "Government", value: "Government" },
            { text: "Unknown", value: "Unknown" },
        ];
        return localizedData;
    }
    static getClassification() {
        const localizedData = [
            { text: "Internal", value: "Internal" },
            { text: "Unknown", value: "Unknown" },
            { text: "External", value: "External" },
            { text: "Generic", value: "Generic" },
            { text: "Law Firm-Unknown", value: "Law Firm-Unknown" },
            { text: "Law Firm-Opposing", value: "Law Firm-Opposing" },
            { text: "Law Firm-Friendly", value: "Law Firm-Friendly" },
            { text: "Law Firm-Neutral", value: "Law Firm-Neutral" },
        ];
        return localizedData;
    }
    static getPrivilegeActor() {
        const localizedData = [
            { text: "Yes", value: true },
            { text: "No", value: false },
           
        ];
        return localizedData;
    }
}