import React, { useState, useEffect} from "react";
import * as ReactDOM from 'react-dom';
import { NavLink, useHistory, Redirect, Link } from "react-router-dom";
import { Form, Field, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import Project from '../../models/Project';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import DialogClient from "../clientdomain/DialogClientList";
import {
    FormDatePicker, FormNumericTextBox, FormInput,
    FormCheckbox, FormMaskedTextBox, FormTextArea
} from '../common/form-components';

import {
    termsValidator, emailValidator, nameValidator, projectNameValidator,
    phoneValidator, guestsValidator, nightsValidator, clientNameValidator,descriptionValidator,
    arrivalDateValidator
} from '../common/validators'
import AdminService from '../../services/AdminService';
import { Grid } from "@material-ui/core";
import { link } from 'fs';
import Client from '../../models/Client';
import $ from 'jquery';
const CustomTitleBar = () => {
    return (
        <div className="custom-title">
            <span><strong>Clients</strong></span>
        </div>
    );
};
interface ProjectInterface {
    projectName: string,
    startDate: Date,
    additionalInfo: string
}
const SaveProject = (props: any) => {
    const history = useHistory();
    const formRef = React.useRef<any>(null);
    const { id } = props.match.params;
    const [loadClientsDialog, setLoadClientsDialog] = React.useState(false);
    const [selectedClient, setSelectedClient] = React.useState<Client>();
    const [selectedProject, setSelectedProject] = React.useState<ProjectInterface>({ projectName: "", startDate: new Date(), additionalInfo: "" });

    const closeReviewDialog = () => {
        setLoadClientsDialog(false);
    }
    const SelectClient = () => {
        setLoadClientsDialog(true);
    }
    const chooseClient = (client: Client) => { 
        setSelectedClient(client);
        setLoadClientsDialog(false);
    }

    const handleProject = (e: any) => {
        setSelectedProject({...selectedProject,projectName: e.value});
    };

    const handleStartDate = (e: any) => {
        setSelectedProject({ ...selectedProject, startDate: e.value});
    };
    
    const handleAdditionalInfo = (e: any) => {
        setSelectedProject({ ...selectedProject, additionalInfo: e.value});
    };
    useEffect(() => {
        $(".k-datepicker #startDate").prop("readOnly", "readOnly");
    });
    const handleSubmit = async (e:any) => {
        try {
            
            const dataItem = new Project();
            dataItem.clientId = selectedClient && selectedClient.id || 0;
            dataItem.projectName = e.values.projectName;
            dataItem.startDate = e.values.startDate;
            dataItem.endDate = e.values.startDate;
            dataItem.ProjectDescription = e.values.additionalInfo;
            formRef.current && formRef.current.resetForm();
            let res = AdminService.createProject(dataItem)
            if ((await res).status === 200) {
                alert("Project created successfully");
                history.push("/ProjectsList")
            } else {
                alert("Some error occured while creating Project");
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <>
            <div>
                <div className="k-widget k-grid" role="grid">
                    <div className="row">
                        <div className="col-sm-4 text-left p-3">
                            <strong><h5>Create New Project</h5></strong>
                        </div>
                    </div>
                    <Form ref={formRef} onSubmitClick={handleSubmit}
                        
                        initialValues={{ clientName: selectedClient ? selectedClient.clientName : "", projectName: selectedProject.projectName, startDate: selectedProject.startDate, additionalInfo: selectedProject.additionalInfo }}
                        key={JSON.stringify(selectedClient)}
                        render={(formRenderProps: FormRenderProps) => (
                            <FormElement className="innerLayout">
                                <fieldset className={'k-form-fieldset'}>
                                    <Grid container>
                                        <Grid item sm={4} xs={8}>
                                            <div className="field-hint">* Required Fields</div>
                                            <Field
                                                id={'projectName'}
                                                name={'projectName'}
                                                label={'* Project Name'}
                                                component={FormInput}
                                                validator={projectNameValidator}
                                                value={selectedProject.projectName}
                                                onChange={handleProject} />
                                            <Field
                                                id={"startDate"}
                                                name={"startDate"}
                                                label={"Start Date"}
                                                component={FormDatePicker} wrapperStyle={{ width: "30% !important" }}
                                                value={selectedProject.startDate}
                                                onChange={handleStartDate}/>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ padding: 0, borderBottom: 0,width:310 }}>
                                                            <Field
                                                                id={'clientName'}
                                                                name={"clientName"}
                                                                label={'* Client Name'}
                                                                component={FormInput}
                                                                disabled={true}
                                                                initialvalues={selectedClient}
                                                                placeholder={"Select Client"}
                                                                validator={clientNameValidator}
                                                                value={selectedClient ? selectedClient.clientName : ""}/>
                                                  </td>
                                                    <td style={{ padding: 0, paddingTop: 30,borderBottom:0}}>
                                                        <Button onClick={SelectClient} type={'button'} themeColor={'primary'}>
                                                            <span className={'k-icon k-i-plus-circle'}></span>
                                                        </Button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>                                            
                                            {loadClientsDialog && (<Dialog title={<CustomTitleBar />} onClose={closeReviewDialog} width={600} height={520}>
                                                <div>
                                                    <DialogClient chooseClient={chooseClient} />
                                                </div>
                                            </Dialog>)}
                                        </Grid>
                                        <Grid item sm={1} xs={1} />
                                        <Grid item sm={4} xs={8}>
                                            <Field
                                                id={'additionalInfo'}
                                                name={'additionalInfo'}
                                                label={'Additional Info'}
                                                optional={true}
                                                component={FormTextArea}
                                                validator={descriptionValidator}
                                                hint={"0-500 characters allowed"}
                                                value={selectedProject.additionalInfo}
                                                onChange={handleAdditionalInfo}/>
                                        </Grid>
                                    </Grid>
                                </fieldset>
                                <div className="col-sm-6 text-right">
                                    <Button onClick={() => history.push("/ProjectsList")}>
                                        CANCEL
                                    </Button>
                                    <Button
                                        themeColor={'primary'}  disabled={!formRenderProps.valid}>SAVE
                                    </Button>
                                </div>
                            </FormElement>
                        )}
                    />
                </div>
            </div>
        </>
    );
};
export default SaveProject