import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Form, Field } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Upload } from "@progress/kendo-react-upload";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DialogContent } from "@material-ui/core";
import AdminService from "../../services/AdminService";
import { UploadFileInfo, UploadFileStatus } from "@progress/kendo-react-upload";
import WarningIcon from "@material-ui/icons/Warning";
import ResponseInfo, { IResponseModel } from "../../models/ResponseInfo";
import { CSVLink } from "react-csv";
import Document  from "../../models/Document";
import Jobs from "../../models/Jobs";

interface IProps {    
    projectId: number,
    unMatchedDoamins: string[];
    projectName: string;
    cancelConfirmExport: () => void;
    confirmExportJob: (jobId: number) => void;
}
type Props = IProps;
const ConfirmExport = (props: Props) => {
    const [unMatchedData, setUnMatchedData] = React.useState<string[]>([]);
    const [documentData, setDocumentData] = React.useState<Document[]>([]);
    const intervalRef = useRef<any>(null);
    const csvRefLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const headers = [
        { label: "ControlNumber", key: "controlNumber" },
        { label: "Categories", key: "docCategories" },
        { label: "Classifications", key: "docClassifications" },
        { label: "PrivilegeActor", key: "privilegeActor" },
        { label: "EpidCategory", key: "epidCategory" }
    ];
    const closeConfirmDialog = () => {
        props.cancelConfirmExport();
    }

    const proceedExport = async () => {
        const responseData = await AdminService.getData(AdminService.urls.exportDocumentData + "/" + props.projectId + "?hasUnmatchedDomains=true")
        if (responseData != null && responseData.data1 != null) {
            var job = responseData.data1 as Jobs;
            props.confirmExportJob(job.id);
        }
    }
    return (
        <div>
            <DialogContent>
                <div className="review-import" style={{ height:'400px'}} >
                    <p><b>NOTE:</b></p>
                    <p>
                        The following domains do not have a category or classification.
                        By clicking on "CONFIRM", the system will process the documents by treating all the unmatched domains as Unknowns.
                    </p>
                    <br />
                    <div className="validation-summary">
                        <div className="validation-message-container">
                            {props.unMatchedDoamins.map((document, Index) =>
                               <p key={Index.toString()}><span key={Index.toString()}><WarningIcon /> &nbsp;</span>{document}</p>
                            )}
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActionsBar layout={"end"}>
                <CSVLink ref={csvRefLink} filename={props.projectName+".csv"} headers={headers} data={documentData}>
                </CSVLink>
                <Button themeColor={"primary"} onClick={proceedExport}> Confirm</Button>
                <Button themeColor={"primary"} onClick={closeConfirmDialog}> Cancel </Button>
            </DialogActionsBar>
        </div>
    )
}
export default ConfirmExport;