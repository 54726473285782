import DialogContent from "@material-ui/core/DialogContent";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React from "react";

interface IProps {
    inValid: string[],
    fieldMappingHide: () => void,
    uploadFilesAny: () => void
}
const CustomTitleBar = () => {
    return (
        <h2 className="custom-title">
            Warning
        </h2>
    );
};
type Props = IProps;
const FieldMappingWithWarning = (props: Props) => {
    const [visible, setVisible] = React.useState(true);
    const closeReviewDialog = () => {
        setVisible(false);
    }
    const cancel = () => {
        props.fieldMappingHide();
    }
    return (
        <div>
            {visible && (<Dialog title={<CustomTitleBar />} onClose={closeReviewDialog} width={600} >
                <div className="k-form">
                    <DialogContent >
                        <div>
                            <strong> We are unable to map the following documents according to the criteria you have set.You can either skip the unavailable documents by clicking "UPLOAD ANYWAY" or cancel the upload by clicking "CANCEL UPLOAD" </strong>
                            <ul>
                                {props.inValid && props.inValid.map((item) =>
                                    <li>
                                        {item}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </DialogContent>
                    <DialogActionsBar layout={"end"}>
                        <Button themeColor={"primary"} type="button" onClick={cancel}> Cancel</Button>
                        <Button themeColor={"primary"} onClick={props.uploadFilesAny} type="button"> Upload Anyway</Button>
                    </DialogActionsBar>
                </div>
            </Dialog>)}
        </div>

    )
}
export default FieldMappingWithWarning