export default class Project {
    public id: number = 0;
    public projectId: string = "";
    public projectName: string = "";
    public startDate!: string;
    public endDate!: string;
    public status: string = "Active";
    public clientName: string = "";
    public clientId: number = 1;
    public ProjectDescription: string = "";
}