import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { adminRoutes, Routes } from "../../../models/Routing";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "./MainDrawer.scss";
import { Drawer, DrawerContent, DrawerItemProps } from "@progress/kendo-react-layout";
import { DrawerSelectEvent } from "@progress/kendo-react-layout/dist/npm/drawer/interfaces/DrawerSelectEvent";
import { Slide } from "@progress/kendo-react-animation";
import useStyles from "../../../components/common/useStyles";
import DoubleArrowOutlinedIcon from "@material-ui/icons/DoubleArrowOutlined";
import "@progress/kendo-theme-material/dist/all.css";
import { withRouter } from "react-router-dom";
import AuthVerify from "../../../services/AuthVerify";

interface IMainDrawerItem extends DrawerItemProps {
    route?: Routes;
    onSelect?: () => void;
    displayorderindex: number;
}
interface IProps {
    history: any;
}
type Props = IProps & { children?: React.ReactNode };
export const MainDrawer = (props: Props) => {
    const [Panel, setPanel] = useState(null);
    const navItemsDictionary: Record<string, IMainDrawerItem> = {
        [Routes.ProjectsList]: { text: "Projects", icon: "fas fa-briefcase", displayorderindex: 0, route: Routes.ProjectsList, selected: true, subroute: Routes.Project },
        [Routes.ClientList]: { text: "Clients List", icon: "fas fa-list", displayorderindex: 1, route: Routes.ClientList, subroute: Routes.ClientDomain },
        [Routes.GlobalDomain]: { text: "Global Domains", icon: "fas fa-globe", displayorderindex: 2, route: Routes.GlobalDomain, subroute:"" }
    };
    const [expanded, setExpanded] = useState(true);
    const [selectedId, setSelectedId] = useState<number>(0);
    const [navItems, setNavItemsState] = useState({} as Record<string, IMainDrawerItem>);
    const handleOnClick = () => {
        setExpanded(prevState => !prevState);
    };
    
    const handleOnSelect = (event: DrawerSelectEvent) => {
        const navItem = navItems[event.itemTarget.props.route];
        const navRoute = adminRoutes[navItem.route || ""];
        if (AuthVerify()) {
            props.history.push(navRoute.path);
            setSelectedId(event.itemIndex);
        }
        else {
            localStorage.clear();
            window.location.href = "/login";
        }
    };
    const setNavItems = () => {
        const navItems: Record<string, IMainDrawerItem> = {};

        Object.keys(navItemsDictionary).forEach(key => {
            navItems[key] = navItemsDictionary[key];
        });
        setNavItemsState(navItems);
        setSelectedNavItem();
    };
    const setSelectedNavItem = () => {
        const currentLocationPath = (props.history.location.pathname as string).toLowerCase();
        var selectedIndex:number=0;
        var isMenuFund:boolean=false;
        Object.keys(navItemsDictionary).forEach(key => {
            if (currentLocationPath.indexOf(navItemsDictionary[key].route!.toLowerCase() || "") > 0 || currentLocationPath.indexOf(navItemsDictionary[key].subroute!.toLowerCase() || "") > 0)
                selectedIndex = navItemsDictionary[key].displayorderindex;   
                isMenuFund=true;        
        });
        if (!isMenuFund && currentLocationPath.indexOf("client")>0)
            selectedIndex = 1;
        setSelectedId(selectedIndex);
    };
    useEffect(() => {
        setNavItems();
    }, []);
   
    return (
        <React.Fragment>
            <div className="main-drawer-wrapper">
                <div className="main-drawer-container">
                    <Drawer
                        className={`main-drawer ${!expanded ? "collapsed" : ""}`}
                        expanded={expanded}
                        position={"start"}
                        mode={"push"}
                        mini={true}
                        miniWidth={70}
                        width={210}
                        items={Object.keys(navItems).map((key, index) => ({ ...navItems[key], selected: index === selectedId }))}
                        onSelect={handleOnSelect}
                        animation={{ duration: 200 }}>
                        <DrawerContent>
                            <div className="expand-collapse-icon-button" aria-label="close" onClick={handleOnClick}>
                                <DoubleArrowOutlinedIcon className="expand-collapse-icon" />
                            </div>
                            {/*<Slide*/}
                            {/*    direction="right"*/}
                            {/*    transitionEnterDuration={200}*/}
                            {/*    transitionExitDuration={200}*/}
                            {/*    className="sub-panel-slide-container">*/}
                            {/*    {Panel}*/}
                            {/*</Slide>*/}
                            <Container maxWidth={false} className="content-wrapper">
                                <div className={useStyles().root}>
                                    <Grid className="content-container">{props.children}</Grid>
                                </div>
                            </Container>
                        </DrawerContent>
                    </Drawer>
                </div>
                {/*<Slide direction="up" transitionEnterDuration={300} transitionExitDuration={300} className="slide-container">*/}
                {/*    {props.buttons && props.buttons.length > 0 ? (*/}
                {/*        <ActionBar*/}
                {/*            pageTitle={props.pageTitle}*/}
                {/*            breadCrumbTitle={props.breadCrumbTitle}*/}
                {/*            breadCrumbUrl={props.breadCrumbUrl}*/}
                {/*            buttons={props.buttons}*/}
                {/*        />*/}
                {/*    ) : null}*/}
                {/*</Slide>*/}
            </div>
        </React.Fragment>
    );
};

export default withRouter(MainDrawer);