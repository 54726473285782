import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { NavLink, useHistory, Redirect } from "react-router-dom";
import { Form, Field, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import "./SaveClient.scss";
import {
    FormDatePicker, FormNumericTextBox, FormInput,
    FormCheckbox, FormMaskedTextBox, FormTextArea
} from '../common/form-components';

import {
    termsValidator, emailValidator, nameValidator, clientNameValidator, descriptionValidator,
    phoneValidator, guestsValidator, nightsValidator,
    arrivalDateValidator
} from '../common/validators'
import AdminService from '../../services/AdminService';

const SaveClient = () => {
    const history = useHistory();
    const formRef = React.useRef<any>(null);
const handleSubmit = async (e:any) => {
    try {
        const dataItem=new FormData();
        dataItem.append("ClientName", e.clientName);
        dataItem.append("ClientDescription", e.AdditionalInfo);
        formRef.current && formRef.current.resetForm();
      let res = AdminService.createClient(dataItem)
      if ((await res).status === 200) {
          alert("Client created successfully");
            history.push("/ClientList")
          //<Redirect to="/ClientList"/>
      } else {
        alert("Some error occured while creating Client");
      }
    } catch (err) {
      console.log(err);
    }
  }; 
return (
<>
        <div className="AddClient">
            <div className="k-widget k-grid" role="grid">
                <div className="row">
                    <div className="col-sm-4 text-left p-3">
                        <strong><h5>Add Client</h5></strong>

                    </div>
                </div>
                <Form
            ref={formRef}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
                
                        <FormElement className="innerLayout">
                    <fieldset className={'k-form-fieldset'}>
                        <div className="field-hint">* Required Fields</div>
                        <Field
                            id={'clientName'}
                            name={'clientName'}
                            label={'* Client Name'}
                            component={FormInput}
                            validator={clientNameValidator}
                        />
                        <Field
                            id={'addlInfo'}
                            name={'AdditionalInfo'}
                            label={'Additional Info'}
                            optional={true}
                            component={FormTextArea}
                            validator={ descriptionValidator }
                            hint={"0-500 characters allowed"}
                        />   
                    </fieldset>
                    <div className="col-sm-6 text-right">
                        <Button onClick={() => history.push("/ClientList")}>
                            CANCEL
                        </Button>
                        <Button
                            themeColor={'primary'}
                            type={'submit'}
                            disabled={!formRenderProps.allowSubmit}>SAVE
                        </Button>

                    </div>
                        </FormElement>
                    
                   
                    
                
             )}
        />
            </div>
        </div>
</>
    );
};
export default SaveClient