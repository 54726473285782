import React, { Component, useRef, useEffect } from "react";

export default class FrequentFunctions {

    static handleSplChars = (str: string) => {
        if (str != undefined && str != null && str != '') {
            str = str.replace(/"/g, '""');
        }
        return str;
    }
};
 FrequentFunctions