import { State } from "@progress/kendo-data-query";
import { GridCellProps, GridColumnProps } from "@progress/kendo-react-grid";
import React, { useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Project from "../../models/Project"
import ProjectService from "../../services/ProjectService";
import AdvancedGrid from "../grid/AdvancedGrid";
import { formatDate } from "@telerik/kendo-intl";
import { IResponseModel } from "../../models/ResponseInfo";
import { Button } from "@progress/kendo-react-buttons";
import "./Project.scss";
const ProjectsList = () => {
    const [projectsList, setprojectsList] = React.useState<Project[]>([]);
    const [dataState, setDataState] = React.useState<State>({ take: 100, skip: 0 });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [count, setCount] = React.useState(0);
    const history = useHistory();

    const Title = "Projects";

    const projectNameCell = (gridCellProps: GridCellProps) => {
        return (
            <td><NavLink to={`/Project/${gridCellProps.dataItem["id"]}`}>{gridCellProps.dataItem["projectName"]}</NavLink></td>
        )
    }
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );
    const formatStartDate = (gridCellProps: GridCellProps) => {
        if (gridCellProps.dataItem.startDate !== '') {
            return <td>{formatDate(new Date(gridCellProps.dataItem.startDate), "MM/dd/yyyy")}</td>

        }
        return <td>{gridCellProps.dataItem.startDate}</td>
    }
    const formatEndDate = (gridCellProps: GridCellProps) => {
        if (gridCellProps.dataItem.endDate !== '') {
            return <td>{formatDate(new Date(gridCellProps.dataItem.endDate), "MM/dd/yyyy")}</td>

        }
        return <td>{gridCellProps.dataItem.startDate}</td>
    }
    useEffect(() => {
        getProjectsList(dataState.skip, dataState.take);
    }, [dataState, setprojectsList]);
    const columns: Array<GridColumnProps> = [

        { field: "projectName", title: "Project Name", editable: false, cell: projectNameCell },
        { field: "clientName", title: "Client", editable: false },
        { field: "startDate", title: "Start Date", editable: false, sortable: false, cell: formatStartDate },
        { field: "endDate", title: "End Date", editable: false, cell: formatEndDate },
        { field: "status", title: "Status", editable: false }
    ];
    const changePagination = (skip: number, take: number) => {
        setDataState({ ...dataState, skip: skip, take: take });
    }
    const getProjectsList = (skip?: number, take?: number) => {
        setLoading(true);
        const result = ProjectService.getProjectsList(skip, take)
            .then(function (response) {
                console.log(response);
                setLoading(false);
                const communicatorData = response!.data as IResponseModel<Project>;
                if (communicatorData != null) {
                    setprojectsList(communicatorData.queryResult);
                    setCount(communicatorData.totalCount)
                }
            })
    }

    return (
        <>
            <div className="row k-widget" role="grid">
                <div className="col-sm-4 text-left p-3">
                    <strong>{Title}</strong>
                </div>
                <div className="col-sm-8 text-right p-3">
                    <Button themeColor={"primary"} onClick={() => history.push("/saveproject")}>+ Project</Button>
                </div>
            </div>
            <div className="project-list">
                {loading && loadingPanel}
                <AdvancedGrid data={projectsList} columns={columns} Title={Title} changePagination={changePagination} dataState={dataState} totalCount={count} filterable={false} />
            </div>

        </>
    )
}
export default ProjectsList