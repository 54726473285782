import * as React from "react";
import "./header.scss";
import { Container } from "@material-ui/core";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownButton } from "@progress/kendo-react-buttons";
import UserContainer from "../UserContainer";

interface IHeaderProps {
    logo?: string;
    logoUrl?: string;
    logoAlt?: string;
}

const HeaderComponent: React.FunctionComponent<IHeaderProps> = props => {
    return (
        <div className="header-bar">
            <Container maxWidth={false}>
                <nav className="header-nav-main">
                    <span className="header-nav-main-left header-menu">
                        <a href={props.logoUrl}>
                            <img
                                className="header-nav-logo"
                                src={props.logo}
                                alt={props.logoAlt}
                            />
                        </a>
                    </span>
                    <span className="header-nav-main-right header-menu">
                        <UserContainer />
                    </span>
                </nav>
            </Container >
        </div>
        
    );
};

HeaderComponent.defaultProps = {
    logo: require("../../../images/PrivAILogo3.png"),
    logoUrl: "#",
    logoAlt: "Epiq PrivAI"
};
export { HeaderComponent };
