import { getter } from '@progress/kendo-react-common';

const emailRegex: RegExp = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex: RegExp = new RegExp(/^[0-9 ()+-]+$/);
const ccardRegex: RegExp = new RegExp(/^[0-9-]+$/);
const cvcRegex: RegExp = new RegExp(/^[0-9]+$/);

export const termsValidator = (value: string) => value ? "" : "It's required to agree with Terms and Conditions.";
export const emailValidator = (value: string) => !value ?
    "Email field is required." :
    (emailRegex.test(value) ? "" : "Email is not in a valid format.");
export const nameValidator = (value: string) => !value ?
    "Full Name is required" :
    value.length < 7 ? "Full Name should be at least 7 characters long." : "";
export const clientNameValidator = (value: string) => !value ?
    "Client Name is required" :
    value.length > 255 ? "Client name should have max 255 characters long." : "";
export const projectNameValidator = (value: string) => !value ?
    "Project Name is required" :
    value.length > 255 ? "Project name should have max 255 characters long." : "";

export const phoneValidator = (value: string) => !value ?
    "Phone number is required." :
    phoneRegex.test(value) ? "" : "Not a valid phone number.";
export const cardValidator = (value: string) => !value ?
    "Credit card number is required. " :
    ccardRegex.test(value) ? "" : "Not a valid credit card number format.";
export const cvcValidator = (value: string) => !value ?
    "CVC code is required," :
    cvcRegex.test(value) || value.length !== 3 ? "" : "Not a valid CVC code format.";
export const guestsValidator = (value:any) => !value ?
    "Number of guests is required" :
    value < 5 ? "" : "Maximum 5 guests";

export const descriptionValidator = (value: string) => !value ? "" : value.length <= 500?"":"Maxmum 500 characters are allowed.";
export const nightsValidator = (value: string) => value ? "" : "Number of Nights is required.";
export const arrivalDateValidator = (value: string) => value ? "" : "Arrival Date is required.";
export const colorValidator = (value: string) => value ? "" : "Color is required.";
export const requiredValidator = (value: string) => value ? "" : "Error: This field is required.";
export const passwordValidator = (value: string) => value && value.length > 8 ? '' : 'Password must be at least 8 symbols.';
export const addressValidator = (value: string) => value ? "" : "Address is required.";
export const startDateValidator = (value: string) => value ? "" : "Start Date is required.";

const clientNameGetter = getter('clientname');
const emailGetter = getter('email');
const projectNameGetter = getter('projectname');

export const formValidator = (values: any) => {
    const clientName = clientNameGetter(values);
const projectName = projectNameGetter(values);
    const emailValue = emailGetter(values);

    if (clientName && emailValue && emailRegex.test(emailValue)) {
        return {};
    }

    return {
        VALIDATION_SUMMARY: 'Please fill in the following fields.',
        ['clientName']: !clientName ? 'Client Name is required.' : '',
 ['projectName']: !projectName ? 'Project Name is required.' : '',
        ['email']: emailValue && emailRegex.test(emailValue) ? '' : 'Email is required and should be in a valid format.'
    };
};