
export default class ResponseInfo {
    public isSuccess: boolean = false;
    public errorMessage: string = "";
    public data?: any[];
    public totalCount: number = 0;
    public data1?: any;
}
export interface IResponseModel<T> {
     queryResult: T[];
     totalCount: number;
}
