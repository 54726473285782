
export function localStorageGetItem<T>(key: string, intialValue: T): T {
    const storedValue = localStorage.getItem(key);

    if (storedValue) {
        try {
            return JSON.parse(storedValue);
        } catch (error) {
            console.error(`Error parsing stored value for key '${key}'`, error);
        }
    }
    return intialValue;
}

export function localStorageSetItem(key: string, value: any): void {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error(`Error setting value for key '${key}'`, error);
    }
}
export function localStorageRemoveItem(key: string): void {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error(`Error removing item with key '${key}'`, error);
    }
}