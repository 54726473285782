import GlobalDomain from "../components/globaldomain/GlobalDomain";
import Project from "../components/projects/Project";
import ClientDomain from "../components/clientdomain/ClientDomain";
import ClientList from "../components/clientdomain/ClientList";
import ProjectsList from "../components/projects/ProjectsList";
import AddClient from "../components/clientdomain/SaveClient";
import AddProject from "../components/projects/SaveProject";
import Login from "../components/Login/login";

export enum Routes {
    AddClient="AddClient",
    ClientList = "ClientList",
    ClientDomain = "ClientDomain",
    GlobalDomain = 'GlobalDomain',
    Project = "Project",
    ProjectsList = "ProjectsList",
    AddProject="AddProject",
    Default = "default",
    Login = "Login"
}
export enum RouteType {
    NotAuthenticated
}

export interface IRoute {
    id: Routes;
    exact?: boolean;
    path?: string;
    lookupSegment?: string;
    component: any;
}

export const adminRoutes: Record<string, IRoute> = {
    [Routes.Default]: {
        id: Routes.Default,
        exact: true,
        path: "/",
        component: Login
    },
    [Routes.Login]: {
        id: Routes.Login,
        exact: false,
        path: "/login",
        component: Login
    },
    [Routes.GlobalDomain]: {
        id: Routes.GlobalDomain,
        exact: false,
        path: "/globaldomain",
        component: GlobalDomain
    },
    [Routes.ClientList]: {
        id: Routes.ClientList,
        exact: false,
        path: "/clientlist",
        component: ClientList
    },
    [Routes.AddClient]: {
        id: Routes.AddClient,
        exact: false,
        path: "/saveclient",
        component: AddClient
    },
    [Routes.AddProject]: {
        id: Routes.AddProject,
        exact: false,
        path: "/saveproject",
        component: AddProject
    },
    [Routes.ClientDomain]: {
        id: Routes.ClientDomain,
        exact: false,
        path: "/clientdomain/:id",
        component: ClientDomain
    },
    [Routes.ProjectsList]: {
        id: Routes.ProjectsList,
        exact: false,
        path: "/projectslist",
        component: ProjectsList
    },
    [Routes.Project]: {
        id: Routes.Project,
        exact: false,
        path: "/project/:id",
        component: Project
    }
}