import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Form, Field } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Upload } from "@progress/kendo-react-upload";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DialogContent } from "@material-ui/core";
import AdminService from "../../services/AdminService";
import { UploadFileInfo, UploadFileStatus } from "@progress/kendo-react-upload";
import "./ReviewImport.scss";
import ErrorIcon from "@material-ui/icons/Error";
import FileUploadResponse from "../../models/FileUploadResponse";
import authHeader from "../../services/authHeader";

interface IProps {
    totalRecs?: number,
    totalValidRecs?: number,
    NotValidRecsMsg?: string[],
    errorFileName: string,
    validFileName: string,
    pageType: string,
    sendStatus?: (isImpSuccess: boolean) => void,
    clientId: number,
    totalNotValidRecs?: number
    setVisible: (value: boolean) => void;
    gdOption: string;

}
type Props = IProps;
const CustomTitleBar = () => {
    return (
        <div className="custom-title">
            <span><strong>Warning!</strong></span>
        </div>
    );
};

const ReviewImport = (props: Props) => {
    const [visible1, setVisible1] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    const closeReviewDialog = () => {
        setVisible1(false);
    }
    const downloadValidationResult = async () => {
        const downloadUrl = AdminService.downloadErrorLogFile(props.pageType, props.errorFileName)
        await fetch(downloadUrl, { method: 'get', headers: { 'Authorization': authHeader() } }).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'ErrorFile.csv';
                alink.click();
            })
        })
    }

    const uploadFileAnyWay = () => {
        setLoading(true);
        const result = AdminService.resumeImport(props.pageType, props.validFileName, props.clientId, props.gdOption).then(function (response) {
            const res = response != null ? response.data as unknown as FileUploadResponse : '';
            if (res != '' && res.isSuccess == true) {
                props.sendStatus && props.sendStatus(true);
                alert("File processed successfuly");
                setLoading(false);
            }
            else {
                alert("Not Processed");
                setLoading(false);
            }
        })

    }
    const cancelUpload = () => { setVisible1(false); props.setVisible(false); }
    return (
        <div>
            {visible1 && (<Dialog title={<CustomTitleBar />} onClose={closeReviewDialog} width={600} height={400}>
                <DialogContent>
                    <div className="review-import">
                        <p><b>NOTE:</b></p>
                        {props.totalRecs == props.totalNotValidRecs ?
                            <p>
                                The document may contain missing values or values not allowed by the system.
                                You can view the log for each ingested row by downloading it.
                            </p> : ""}
                        {props.totalRecs !== props.totalNotValidRecs ?
                            <p>
                                The document may contain missing values or values not allowed by the system.
                                You can view the log for each ingested row by downloading it.
                                Click "UPLOAD ANYWAY" to upload the document disregarding the unavailable rows.
                            </p> : ""}
                        <br />
                        <div className="validation-summary">
                            <div className="validation-message-container">
                                <p><strong>Validation Summary: {props.totalValidRecs} of {props.totalRecs} rows are available to import</strong></p>
                                {props.NotValidRecsMsg !== undefined && props.NotValidRecsMsg.map((errorMsg, Index) =>
                                    <p key={Index.toString()}><span className="errorIconRed" key={Index.toString()}><ErrorIcon /> &nbsp;</span>{errorMsg}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActionsBar layout={"end"}>
                    <Button onClick={downloadValidationResult}> Download Log</Button>
                    {props.totalRecs !== props.totalNotValidRecs ? <Button themeColor={"primary"} onClick={uploadFileAnyWay}> <span className={`k-icon ${loading ? "k-i-loading" : "k-i-export"} k-icon-16`} style={{ marginBottom: 5 }} />Upload Anyway</Button> : ""}
                    {props.totalRecs == props.totalNotValidRecs ? <Button themeColor={"primary"} onClick={cancelUpload}> Cancel Upload</Button> : ""}
                </DialogActionsBar>
            </Dialog>)}
        </div>

    )
}
export default ReviewImport;