import React, { Component, useRef, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { CSVLink } from "react-csv";
import GlobalDomainList from "../../models/GlobalDomainList";
import AdminService from "../../services/AdminService";
import '@progress/kendo-theme-material/dist/all.css';
import "./GlobalDomain.scss";
import FileUpload from "../files/FileUploadWithReviewImport";
import * as ReactDOM from "react-dom";
import { Label, Error } from "@progress/kendo-react-labels";
import { DialogContent } from '@material-ui/core';
import ConfirmImport from "../files/ConfirmImport";
import GlobalDomainResponseModel from "../../models/GlobalDomainResponseModel";
import { SortDescriptor, State } from "@progress/kendo-data-query";
import AdvancedGrid, { IGridParams } from "../grid/AdvancedGrid";
import { GridCellProps, GridColumnProps, GridFilterChangeEvent, GridItemChangeEvent, GridRowProps,Grid } from "@progress/kendo-react-grid";
import CommandCell from "../grid/columns/CommandCell";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { TextBox } from "@progress/kendo-react-inputs";
import FrequentFunctions  from "./../common/FrequentFunctions";
import OptionImport from "../files/OptionImport";


const CustomTitleBar = () => {
    return (
        <div className="custom-title" >
            <span > <strong>File Upload Wizard</strong></span>
        </div>
    );
};

const GlobalDomain = () => {
    const [backupItems, setBackupItems] = React.useState<GlobalDomainList[]>([]);
    const [visible, setVisible] = React.useState<boolean>(false);
    const [disableAdd, setDisableAdd] = React.useState<boolean>(true);
    const [isConfirm, setIsConfirm] = React.useState<boolean>(false);
    const [globalDomainListData, setGlobalDomainListData] = React.useState<GlobalDomainList[]>([]);
    const [gdOption, setGdOption] = React.useState<string>("new");
    const editField = "inEdit";
    const csvRefLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const headersDownload = ["Domain", "Category", "OrganizationName", "GlobalNotation", "SearchTerms"]
    const headers = [
        { label: "Domain", key: "domain" },
        { label: "Category", key: "category" },
        { label: "OrganizationName", key: "organizationName" },
        { label: "GlobalNotation", key: "globalNotation" },
        { label: "SearchTerms", key: "searchTerms" }
    ];
    const CustomCommandCell = (props: any) => (<CommandCell {...props}
        edit={enterEdit}
        remove={remove}
        add={add}
        discard={discard}
        update={update}
        cancel={cancel}
        editField={editField}
        disableAdd={disablePlusButton(props.dataItem) }
    />);
    const minMaxValidation = (value: string, min: number, max: number) => {
        if (value.length < min || value.length > max) {
            return false;
        }
        return true;
    };
    const validationCell = React.useMemo(() => (props: GridCellProps) => {

        const { dataItem } = props;
        const field = props.field || "";
        const dataValue = dataItem[field] === null ? "" : dataItem[field];
        return (
            <td className="k-grid-edit-cell">

                {dataItem.inEdit ? (
                    <div className="textValidationCell">
                        <TextBox
                            className="k-textbox"
                            maxLength={255}
                            value={dataValue}
                            onChange={(e) => {
                                if (props.onChange) {
                                    props.onChange({
                                        dataItem: props.dataItem,
                                        dataIndex: props.dataIndex,
                                        field: props.field,
                                        syntheticEvent: e.syntheticEvent,
                                        value: e.target.value,
                                    });
                                }
                            }}
                        />
                        {!minMaxValidation(dataValue, 0, 255) && (
                            <Error>Max characters allowed 255</Error>)}
                    </div>
                ) : (
                    dataValue && dataValue.toString()
                )}

            </td>
        );
    }, []);
    const validationDomainCell = React.useMemo(() => (props: GridCellProps) => {

        const { dataItem } = props;
        const field = props.field || "";
        const dataValue = dataItem[field] === null ? "" : dataItem[field];
        return (
            <td className="k-grid-edit-cell">
                {dataItem.inEdit ? (
                    <div className="textValidationCell">
                        <TextBox
                            required={true }
                            className="k-textbox"
                            maxLength={255}
                            value={dataValue}
                            onChange={(e) => {
                                if (props.onChange) {
                                    props.onChange({
                                        dataItem: props.dataItem,
                                        dataIndex: props.dataIndex,
                                        field: props.field,
                                        syntheticEvent: e.syntheticEvent,
                                        value: e.target.value,
                                    });
                                    disablePlusButton({ ...props.dataItem, domain: e.target.value });
                                }
                            }}
                        />

                        {!minMaxValidation(dataValue, 0, 255) &&(
                            <Error>Max characters allowed 255</Error>)}
                    </div>
                ) : (
                        dataValue && dataValue.toString()
                )}

            </td>
        );
    }, []);
    const disablePlusButton = (item: any) => {
        if (item.domain && item.category) {
            setDisableAdd(false);
            return false;
        } else {
            setDisableAdd(true);
            return true;
        }
    }

    const validationSearchTermCell = React.useMemo(() => (props: GridCellProps) => {

        const { dataItem } = props;
        const field = props.field || "";
        const dataValue = dataItem[field] === null ? "" : dataItem[field];
        return (
            <td className="k-grid-edit-cell">

                {dataItem.inEdit ? (
                    <div className="textValidationCell">
                        <TextBox
                            className="k-textbox"
                            value={dataValue}
                            onChange={(e) => {
                                if (props.onChange) {
                                    props.onChange({
                                        dataItem: props.dataItem,
                                        dataIndex: props.dataIndex,
                                        field: props.field,
                                        syntheticEvent: e.syntheticEvent,
                                        value: e.target.value,
                                    });
                                }
                            }}
                        />
                    </div>
                ) : (
                        dataValue && dataValue.toString()
                )}

            </td>
        );
    }, []);
    const categoryDropDownCell = (props: GridCellProps) => {
        const localizedData = AdminService.getCategories();

        const handleChange = (e: DropDownListChangeEvent) => {
            if (props.onChange) {
                props.onChange({
                    dataIndex: props.dataIndex,
                    dataItem: props.dataItem,
                    field: props.field,
                    syntheticEvent: e.syntheticEvent,
                    value: e.target.value.value,
                });
                disablePlusButton({ ...props.dataItem, category: e.target.value.value });
            }
        };
        const { dataItem } = props;
        const field = props.field || "";
        const dataValue = dataItem[field] === null ? "" : dataItem[field];
        return (
            <td>
                {dataItem.inEdit ? (
                    <DropDownList
                        required
                        style={{ width: "100px" }}
                        onChange={handleChange}
                        value={localizedData.find((c) => c.value === dataValue)}
                        data={localizedData}
                        textField="text"
                    />
                ) : (
                    dataValue.toString()
                )}
            </td>
        );

    };
    
    const columns: Array<GridColumnProps> = [
        { field: "domain", title: "Domain", editable: true, sortable: true, filterable: true, cell: validationDomainCell },
        { field: "category", title: "Category", editable: true, sortable: true, filterable: true, cell: categoryDropDownCell },
        { field: "organizationName", title: "Organization Name", editable: true, sortable: true, filterable: true, cell: validationCell },
        { field: "globalNotation", title: "Global Notation", editable: true, sortable: true, filterable: true, cell: validationCell },
        { field: "searchTerms", title: "Search Terms", editable: true, sortable: true, filterable: true, cell: validationSearchTermCell },
        { width: 150, cell: CustomCommandCell, filterable: false, sortable: false, headerClassName: "no-sort" }
    ];

    const [GlobalDomainList, setGlobalDomainList] = React.useState<GlobalDomainList[]>([]);
    const [dataState, setDataState] = React.useState<IGridParams>({ take: 100, skip: 0, sort: [{ field: "domain", dir: "asc" }] });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [count, setCount] = React.useState(0);
    const EDIT_FIELD = "inEdit";
    const csvLink = { filename: "", headers: headersDownload, data: "" }
    const Title = "GlobalDomain";
    const gridRef = useRef<Grid>(null);
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );
    const getData = (opType?: string) => {
        const clientDomainResult = AdminService.exportGlobalDomainList();
        clientDomainResult.then((domains) => {
            const domainsData = domains!.data as GlobalDomainResponseModel;
            setGlobalDomainListData(domainsData.exportQueryResult)
            if (opType === "export") {
                if (domainsData && domainsData.totalCount > 0) {
                    var response = domainsData.exportQueryResult;
                 var newData=   response.map((item,i) => {
                     item.category = FrequentFunctions.handleSplChars(item.category),
                         item.domain = FrequentFunctions.handleSplChars(item.domain),
                         item.globalNotation = FrequentFunctions.handleSplChars(item.globalNotation),
                         item.organizationName = FrequentFunctions.handleSplChars(item.organizationName),
                         item.searchTerms = FrequentFunctions.handleSplChars(item.searchTerms)
                        return item;
                    });
                    setGlobalDomainListData(newData);
                    csvRefLink && csvRefLink.current && csvRefLink.current.link.click();
                }
                else
                    alert("No data is available to export");
            }
        });
    }
  
    const gridFilterChange = (event: GridFilterChangeEvent) => {
        const newState = { ...dataState, skip: 0, take: 100, filter: event.filter };
        setDataState(newState);
    };
    const changePagination = (skip: number, take: number) =>
    {
        gridRef.current && gridRef.current.scrollIntoView({ rowIndex: 1 });
        setDataState({ ...dataState, skip: skip, take: take });
    }
    const getGlobalData = (dataState: State, opType?: string) => {
        setLoading(true);
        const clientDomainResult = AdminService.getGlobalDomain(dataState.skip, dataState.take, dataState.sort, dataState.filter);
        clientDomainResult.then((domains) => {
            setLoading(false);
            const domainsData = domains!.data as GlobalDomainResponseModel;
            setGlobalDomainListData(domainsData.exportQueryResult as GlobalDomainList[])
            if (opType === "export") {
                if (domainsData.exportQueryResult && domainsData.exportQueryResult.length > 0) {
                    csvRefLink && csvRefLink.current && csvRefLink.current.link.click();
                }
                else
                    alert("No data is available to export");
            }
            if (domainsData != null) {
                setGlobalDomainList(domainsData.queryResult);
                setBackupItems(domainsData.queryResult);
                setCount(domainsData.totalCount);
            }
        });
    }
    const getSortBy = (sort: Array<SortDescriptor>) => {
        setDataState({ ...dataState, skip: 0, take: 100, sort: sort });
    }
    const confirmImport = () => {
        if (globalDomainListData.length > 0) {
            setIsConfirm(true);
        }
        else
            setVisible(true);
    }

    const exportData = () => {
        getData("export");

    }
    const closeDialog = () => {
        setVisible(false)
    }
    useEffect(() => {
        getGlobalData(dataState);
    }, [dataState, setGlobalDomainList]);
    const sendStatus = (isImpSuccess: boolean) => {
        if (isImpSuccess)
            setTimeout(() => { setVisible(false); }, 1000);
        getGlobalData(dataState);
    }
    const newUpload = (newUploadSuccess: string) => {
        setVisible(true);
        setGdOption(newUploadSuccess);
    }
    const remove = (dataItem: GlobalDomainList) => {
        const newData = deleteItem(dataItem);

    };

    const deleteItem = (dataItem: GlobalDomainList) => {
        setLoading(true);
        const result = AdminService.deleteGlobalDomain(dataItem.id)
            .then(function (response: any) {
                setLoading(false);
                const _items = [...backupItems];
                let index = _items.findIndex((record) => record.id === dataItem.id);
                _items.splice(index, 1);
                setBackupItems([..._items]);
                setGlobalDomainList([..._items]);
                getGlobalData(dataState);
            })

    };
    const add = (dataItem: GlobalDomainList) => {
        dataItem.inEdit = true;
        const newData = insertItem(dataItem);
    };
    const insertItem = (dataItem: GlobalDomainList) => {
        setLoading(true);
        const addGlobalDomainResult = AdminService.createGlobalDomain(dataItem)
            .then(function (response: any) {
                setLoading(false);
                console.log(response);
                const globalDomainData = response.data;
                if (!globalDomainData.inValid) {
                    dataItem.inEdit = false;
                    dataItem.id = globalDomainData.data.id;
                    const _items = [...GlobalDomainList];

                    let index = _items.findIndex((p) => p === dataItem);
                    if (index >= 0) {
                        _items[index] = { ...dataItem };
                    }
                    setBackupItems([..._items]);
                    setGlobalDomainList([..._items]);
                    getGlobalData(dataState);
                }
                else {

                    alert(globalDomainData.message);
                }
            })
    };
    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || "";
        const newData = GlobalDomainList.map((item) =>
            item.id === event.dataItem.id
                ? { ...item, [field]: event.value }
                : item
        );

        setGlobalDomainList(newData);
    };
    const discard = (dataItem: GlobalDomainList) => {
        const newData = [...GlobalDomainList];
        newData.splice(0, 1);
        setGlobalDomainList(newData);
    };
    const update = (dataItem: GlobalDomainList) => {
        setLoading(true);
        const result = AdminService.createGlobalDomain(dataItem)
            .then(function (response: any) {
                setLoading(false);
                const globalDomainData = response.data;
                if (!globalDomainData.inValid) {
                    dataItem.inEdit = false;
                    const newData = [...GlobalDomainList]
                    let index = newData.findIndex((record) => record.id === dataItem.id);
                    newData[index] = dataItem;
                    setGlobalDomainList(newData);
                    setBackupItems(newData);
                }
                else {
                    alert(globalDomainData.message);
                }
            })
    };
    const updateItem = (dataItem: GlobalDomainList) => {
        let index = GlobalDomainList.findIndex((record) => record.id === dataItem.id);
        GlobalDomainList[index] = dataItem;
        return GlobalDomainList;
    };
    const cancel = (dataItem: GlobalDomainList) => {
        const originalItem = backupItems.find(
            (p: any) => p.id === dataItem.id
        );
        const newData = GlobalDomainList.map((item) =>
            originalItem ? (item.id === originalItem.id ? originalItem : item) : item
        );

        setGlobalDomainList(newData);
    };
    const enterEdit = (dataItem: GlobalDomainList) => {     
        let newData = GlobalDomainList.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
        );
        //if (dataItem.domain && dataItem.category) {
        //    setDisableAdd(false);
        //} else {
        //    setDisableAdd(true);
        //}
        setGlobalDomainList(newData);
    };

    const addNew = () => {
        gridRef.current && gridRef.current.scrollIntoView({ rowIndex: 1 }); 
        const newDataItem: GlobalDomainList = {
            inEdit: true,
            domain: "",
            category: "",
            globalNotation: "",
            searchTerms: "",
            organizationName: ""
        };

        setGlobalDomainList([newDataItem, ...backupItems]);
    };
    return (
        <>
            <div className="k-widget k-grid" role="grid">
                <div className="row">
                    <div className="col-sm-4 text-left p-3"><Label><strong>Global Domains List</strong></Label></div>
                    <div className="col-md-8 text-right global-domain-btn-wrapper">
                        <Button onClick={addNew}
                            themeColor={"primary"}>
                            Add Domain
                        </Button>
                        <CSVLink headers={headersDownload} filename={"Global Domains List.csv"} data="">
                            <Button
                                themeColor={"primary"}
                                className="btndownload" >
                                Download Template
                            </Button>
                        </CSVLink>
                        <Button onClick={confirmImport}
                            themeColor={"primary"}>
                            Import
                        </Button>
                        {visible && <Dialog title={<CustomTitleBar />} onClose={closeDialog}>
                            <div>
                                <FileUpload pageType="GlobalDomain" clientId={0} setVisible={setVisible} sendStatus={sendStatus} gdOption={gdOption} />
                            </div>
                        </Dialog>}
                        <CSVLink ref={csvRefLink} filename={"Global Domains List.csv"} headers={headers} data={globalDomainListData}>
                        </CSVLink>
                        <Button onClick={exportData}
                            themeColor={"primary"}
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">
                            Export
                        </Button>
                        {isConfirm && <Dialog onClose={closeDialog}>
                            <OptionImport pageType="GDL" setVisible={setVisible} setIsConfirm={setIsConfirm} newUpload={newUpload} gdOption={gdOption} closeConfirmDialog={closeDialog } />
                        </Dialog>}
                    </div>
                </div>
            </div>
            <div className="global-domain">
                {loading && loadingPanel}
                <AdvancedGrid data={GlobalDomainList} columns={columns} gridRef={gridRef}
                    Title={Title} changePagination={changePagination}
                    dataState={dataState} totalCount={count} sortBy={getSortBy} editField={editField}
                    itemChange={itemChange}
                    filterOperators={{
                        text: [{ text: "grid.filterContainsOperator", operator: "contains" }]
                    }}
                    onFilterChange={gridFilterChange} />

            </div>
        </>

    );
};
export default GlobalDomain