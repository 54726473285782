import React, { useState, useEffect } from "react";
import "./login.scss";
import logo from "../../images/PrivAILoginLogo.png";
import AdminService from "../../services/AdminService";
import ProjectsList from "../projects/ProjectsList";
import { useHistory } from "react-router-dom";
import App from "../../App";
import { localStorageGetItem, localStorageSetItem } from "../../store/LocalStore";


const Login = (props: any) => {
	
	const history = useHistory();
	const [userName, setUserName] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [passwordError, setPasswordError] = useState("");
	const [visible, setVisible] = useState<boolean>(false);
	useEffect(() => {
		const user = localStorageGetItem("accessToken", "");
		if (user) {
			setVisible(true);
		}
		else {
			setVisible(false);
		}
	}, [visible]);
	const handleSubmit = async (e: any) => {
		e.preventDefault();
		if (password === '' && userName === '') {
			return setPasswordError("Invalid email/password");
		}
		else {
			const result = await AdminService.createAccessToken(userName, password)
			if (result.ok && result.data.data1) {
				history.push("/projectslist");
				localStorageSetItem("userSession", JSON.stringify(result.data.data1));
				localStorageSetItem("accessToken", JSON.stringify(result.data.data1.accessToken));
				setVisible(true);
			}
			else {
				return setPasswordError("Invalid email/password");
			}
			return result.data;
		}
	}
	const getCurrentUser = () => {
		const userStr = localStorageGetItem("accessToken", "");
		if (userStr) return JSON.parse(userStr);

		return null;
	}
	const handlePasswordChange = (event: any) => {
		const { value } = event.target;
		setPassword(value);
		setPasswordError("");
	}
	return (
		<div>
			{!visible && (<div className="login-page">
				<div>
					<div className="login-box">
						<div className="login-area">
							<div className="login-logo-section">
								<a href="/"><img className="login-logo" src={logo} /></a>
							</div>
							<section className="login-section">

								<form onSubmit={handleSubmit}>
									<div className="auth-container">
										<div className="login-label">
											Login
										</div>
										<div className="login-content">
											<div className="field-input">
												<label>Email</label>
												<div>
													<input type="text" className="input-text" value={userName} onChange={(e) => setUserName(e.target.value)} />
												</div>
											</div>
											<br />
											<div className="field-input">
												<label>Password</label>
												<div>
													<input type="password" className="input-text" value={password} onChange={handlePasswordChange} />
													{passwordError && <span className="error">{passwordError}</span>}
													{/* <input type="password" className="input-text"></input>*/}
												</div>
											</div>
											<div className="footers">
												<button type="submit" className="primary-button" >Log in</button>
											</div>
										</div>

									</div>
								</form>
							</section>
						</div>
					</div>
					<div className="login-footer">
						<footer className="login-footer">
							<div><a href="https://www.epiqglobal.com/en-us/disclaimer" target="_blank">Disclaimer</a></div>
							<div><a href="https://www.epiqglobal.com/en-us/terms-of-use" target="_blank">Terms of Use</a></div>
							<div><a href="https://www.epiqglobal.com/en-us/privacy-statement" target="_blank">Privacy Statement</a></div>
							<div><a href="https://www.epiqglobal.com/en-us/epiqadmin/footer/cookie-notice" target="_blank">Cookie Notice</a></div>
							<div className="login-footer-right">
								<div className="copy-right">
									© {(new Date()).getFullYear()} Epiq. All rights reserved.
								</div>
							</div>
						</footer>
					</div>
				</div>
			</div>)}
			{visible && (<App />)}
		</div>
	);
}
export default Login;
