import { DialogContent } from "@material-ui/core";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { RadioButton, RadioButtonChangeEvent } from "@progress/kendo-react-inputs";
import React, { useEffect } from "react";
import ConfirmImport from "./ConfirmImport";
import { Input } from "@progress/kendo-react-inputs";
import AdminService from "../../services/AdminService";
import FileUploadResponse from "../../models/FileUploadResponse";

interface IProps {
    pageType?: string;
    setVisible?: (value: boolean) => void;
    gdOption?: string;
    confirmMsg?: string;
    validFileName?: string;
    sendStatus?: (isImpSuccess: boolean) => void;
}
type Props = IProps;
const CustomTitleBar = () => {
    return (
        <div className="custom-title">
            <span><strong>Confirmation</strong></span>
        </div>
    );
};
const ConfirmationWarningImport = (props: any) => {
    const [btnConfirm, setBtnConfirm] = React.useState(false);
    const clickOk = () => {
        setBtnConfirm(true);
        const result = AdminService.resumeImport(props.pageType, props.validFileName, props.clientId, props.gdOption).then(function (response) {
            const res = response != null ? response.data as unknown as FileUploadResponse : '';
            setBtnConfirm(false);
            if (res != '' && res.isSuccess == true) {
                props.sendStatus && props.sendStatus(true);
                alert("File processed successfuly");
            }
            else
                alert("Not Processed")
        })
        
    }
    const clickCancel = () => {
        props.setVisible(false);
    }


    const closeDialog = () => {
        props.setVisible(false);
    }
    

    return (
        <div>
        <Dialog title={<CustomTitleBar />} onClose={closeDialog} width={600} height={280}>
                <DialogContent><p>{props.confirmMsg}</p>
            <div className="mb-3">
              <Input
                name="username"
                style={{ width: "100%" }}
                label="Please confirm"
                pattern={"[A-Za-z]+"}
                minLength={2}
                required={true}
              />
            </div>
            </DialogContent>

                <DialogActionsBar layout={"end"}>
                    <Button themeColor={"primary"} onClick={clickOk} disabled={btnConfirm }>
                    Confirm
                </Button>
                <Button themeColor={"primary"} onClick={clickCancel}>
                    Cancel
                </Button>
                </DialogActionsBar>
            </Dialog>
        </div>
    );
}
export default ConfirmationWarningImport;