import { localStorageGetItem } from "../store/LocalStore";

export default function authHeader() {
    const userStr = localStorageGetItem("accessToken", "");
    let accessToken = null;
    if (userStr)
        accessToken = JSON.parse(userStr);

    if (accessToken) {
        return 'Bearer ' + accessToken ;
    } else {
        return '';
    }
}