import React, { useState, useEffect, useRef } from 'react';
import * as ReactDOM from "react-dom";
import { CSVLink } from "react-csv";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import AdminService from "../../services/AdminService";
import '@progress/kendo-theme-material/dist/all.css';
import "./ClientDomain.scss";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import FileUploadWithImport from "../files/FileUploadWithReviewImport";
import ClientDomainList from '../../models/ClientDomainList';
import ConfirmImport from "../files/ConfirmImport";
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { toNumber } from 'lodash';
import { Grid } from "@material-ui/core";
import { useHistory, useParams, useLocation } from 'react-router-dom';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AdvancedGrid, { IGridParams } from '../grid/AdvancedGrid';
import { GridCellProps, GridColumnProps, GridFilterChangeEvent, GridItemChangeEvent,Grid as ReactGrid} from '@progress/kendo-react-grid';
import {  Error } from '@progress/kendo-react-labels';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import CommandCell from '../grid/columns/CommandCell';
import { TextBox } from '@progress/kendo-react-inputs';
import ClientCommuniatorList from './ClientCommunicatorList';
import FrequentFunctions from "./../common/FrequentFunctions";
import AuthVerify from '../../services/AuthVerify';
interface IProps { match: any }
type Props = IProps;
export const BackButton = () => {
    let history = useHistory();
    const verify = () => {
        if (AuthVerify()) {
            history.goBack()
        }
        else {
            localStorage.clear();
            window.location.href = "/login";
        }
    }
    return (
        <>
            <div>
                <button onClick={() => verify()}><span className="k-icon k-icon-22 icon-color-c k-i-arrow-chevron-left" /></button>
            </div>
        </>
    );
};
const CustomTitleBar = () => {
    return (
        <div className="custom-title">
            <span><strong>File Upload Wizard</strong></span>
        </div>
    );
};
const ClientDomain = (props: Props) => {
    const [backupItems, setBackupItems] = React.useState<ClientDomainList[]>([]);
    const [selected, setSelected] = React.useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isConfirm, setIsConfirm] = React.useState<boolean>(false);
    const [visible, setVisible] = React.useState<boolean>(false);
    const [dataState, setDataState] = React.useState<IGridParams>({ take: 100, skip: 0, sort: [{ field: "domain", dir: "asc" }] });
    
    const [count, setCount] = React.useState(0);
    const [disableAdd, setDisableAdd] = React.useState<boolean>(true);
    const editField = "inEdit";
    const csvRefLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };
    const [ClientDomainList, setClientDomainList] = React.useState<ClientDomainList[]>([]);
    const [exportCDL, setExportCDL] = React.useState<ClientDomainList[]>([]);
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );
    const location: any = useLocation();
    const clientName = location.state != null ? location.state.name : " ";
    const headersDownload = ["Domain", "Classification", "ClientNotation"]
    const gridRef = useRef<ReactGrid>(null);
    const headers = [
        { label: "Domain", key: "domain" },
        { label: "Category", key: "category" },
        { label: "Classification", key: "classification" },
        { label: "ClientNotation", key: "clientNotation" },
        { label: "SearchTerms", key: "searchTerms" }
    ]
    const clientId: number = toNumber(props.match.params['id']);
    const confirmImportfn = () => {
        if (ClientDomainList.length > 0) {
            setIsConfirm(true);
        }
        else
            setVisible(true)
    }

    
    const exportData = async () => {
        const responseData = await AdminService.getData(AdminService.urls.readClientDomainListApiUrl + "?clientId=" + clientId + "&skip=0" + "&take=" + count);
        if (responseData != null && responseData.totalCount > 0) {
            var response = responseData.data as ClientDomainList[];
            var newData = response.map((item, i) => {
                item.category = FrequentFunctions.handleSplChars(item.category),
                item.domain = FrequentFunctions.handleSplChars(item.domain),
                item.classification = FrequentFunctions.handleSplChars(item.classification),
                item.clientNotation = FrequentFunctions.handleSplChars(item.clientNotation),
                item.searchTerms = FrequentFunctions.handleSplChars(item.searchTerms)
                return item;
            });
            setExportCDL(newData);
            csvRefLink && csvRefLink.current && csvRefLink.current.link.click();
        }
        else
            alert("No data is available to export");
    }
    
    const confirmImportClose = (value: boolean) => {
        setIsConfirm(false);
    }
    const closeDialog = () => {
        setVisible(false)
    }
    const sendStatus = (isImpSuccess: boolean) => {
        if (isImpSuccess)
            setTimeout(() => { setVisible(false); }, 1000);
        getClientDomainData(dataState);
    }
    useEffect(() => {
        getClientDomainData(dataState);
    }, [dataState, setClientDomainList]);

 
    const CustomCommandCell = (props: any) => (<CommandCell {...props}
        edit={enterEdit}
        remove={remove}
        add={add}
        discard={discard}
        update={update}
        cancel={cancel}
        editField={editField}
        disableAdd={disablePlusButton(props.dataItem)}
    />);
    const minMaxValidation = (value: string, min: number, max: number) => {
        if (value.length < min || value.length > max) {
            return false;
        }
        return true;
    };
    const validationNotationCell = React.useMemo(() => (props: GridCellProps) => {

        const { dataItem } = props;
        const field = props.field || "";
        const dataValue = dataItem[field] === null ? "" : dataItem[field];
        return (
            <td className="k-grid-edit-cell">

                {dataItem.inEdit ? (
                    <div className="validationNotationCell">
                        <TextBox
                            className="k-textbox"
                            maxLength={500}
                            value={dataValue}
                            onChange={(e) => {
                                if (props.onChange) {
                                    props.onChange({
                                        dataItem: props.dataItem,
                                        dataIndex: props.dataIndex,
                                        field: props.field,
                                        syntheticEvent: e.syntheticEvent,
                                        value: e.target.value,
                                    });
                                }
                            }}
                        />
                        {!minMaxValidation(dataValue, 0, 500) && (
                            <Error>Max characters allowed 500</Error>)}
                    </div>
                ) : (
                    dataValue && dataValue.toString()
                )}

            </td>
        );
    }, []);
    const validationDomainCell = React.useMemo(() => (props: GridCellProps) => {

        const { dataItem } = props;
        const field = props.field || "";
        const dataValue = dataItem[field] === null ? "" : dataItem[field];
        return (
            <td className="k-grid-edit-cell">
                {dataItem.inEdit ? (
                    <div className="textDomainCell">
                        <TextBox
                            required={true}
                            className="k-textbox"
                            maxLength={255}
                            value={dataValue}
                            onChange={(e) => {
                                if (props.onChange) {
                                    props.onChange({
                                        dataItem: props.dataItem,
                                        dataIndex: props.dataIndex,
                                        field: props.field,
                                        syntheticEvent: e.syntheticEvent,
                                        value: e.target.value,
                                    });
                                    disablePlusButton({ ...props.dataItem, domain: e.target.value });
                                }
                            }}
                        />

                        {!minMaxValidation(dataValue, 0, 255) && (
                            <Error>Max characters allowed 255</Error>)}
                    </div>
                ) : (
                    dataValue && dataValue.toString()
                )}

            </td>
        );
    }, []);
    const disablePlusButton = (item: any) => {
        if (item.domain && item.classification) {
            setDisableAdd(false);
            return false;
        } else {
            setDisableAdd(true);
            return true;
        }
    }

    const validationCell = React.useMemo(() => (props: GridCellProps) => {

        const { dataItem } = props;
        const field = props.field || "";
        const dataValue = dataItem[field] === null ? "" : dataItem[field];
        return (
            <td className="k-grid-edit-cell">

                {dataItem.inEdit ? (
                    <div className="textValidationCell">
                        <TextBox
                            disabled={true }
                            className="k-textbox"
                            value={dataValue}
                            onChange={(e) => {
                                if (props.onChange) {
                                    props.onChange({
                                        dataItem: props.dataItem,
                                        dataIndex: props.dataIndex,
                                        field: props.field,
                                        syntheticEvent: e.syntheticEvent,
                                        value: e.target.value,
                                    });
                                }
                            }}
                        />
                    </div>
                ) : (
                    dataValue && dataValue.toString()
                )}

            </td>
        );
    }, []);
    const classificationDropDownCell = (props: GridCellProps) => {
        const localizedData = AdminService.getClassification();

        const handleChange = (e: DropDownListChangeEvent) => {
            if (props.onChange) {
                props.onChange({
                    dataIndex: props.dataIndex,
                    dataItem: props.dataItem,
                    field: props.field,
                    syntheticEvent: e.syntheticEvent,
                    value: e.target.value.value,
                });
                disablePlusButton({ ...props.dataItem, classification: e.target.value.value });
            }
        };
        const { dataItem } = props;
        const field = props.field || "";
        const dataValue = dataItem[field] === null ? "" : dataItem[field];
        return (
            <td>
                {dataItem.inEdit ? (
                    <DropDownList
                        required
                        style={{ width: "100px" }}
                        onChange={handleChange}
                        value={localizedData.find((c) => c.value === dataValue)}
                        data={localizedData}
                        textField="text"
                    />
                ) : (
                    dataValue.toString()
                )}
            </td>
        );

    };
    const columns: Array<GridColumnProps> = [

        { field: "domain", title: "Domain", editable: false, sortable: true, filterable: true, cell: validationDomainCell },
        { field: "category", title: "Category", editable: false, sortable: true, filterable: true, cell: validationCell },
        { field: "classification", title: "Classification", editable: false, sortable: true, filterable: true, cell: classificationDropDownCell },
        { field: "clientNotation", title: "Client Notation", editable: false, sortable: true, filterable: true, cell: validationNotationCell },
        { field: "searchTerms", title: "Search Terms", editable: false, sortable: true, filterable: true, cell: validationCell },
        { width: 150, cell: CustomCommandCell, filterable: false, sortable: false, headerClassName: "no-sort" }
    ];

    const gridFilterChange = (event: GridFilterChangeEvent) => {
        const newState = { ...dataState, skip: 0, take: 100, filter: event.filter };
        setDataState(newState);
    };
    const changePagination = (skip: number, take: number) => {
        gridRef.current && gridRef.current.scrollIntoView({ rowIndex: 1 });
        setDataState({ ...dataState, skip: skip, take: take });
    }
    const getClientDomainData = async (dataState: State) => {
        setLoading(true);
        const sortJSON = JSON.stringify(dataState.sort) || "";
        const filterJSON = dataState.filter ? JSON.stringify([dataState.filter]) : "";
        const responseData = await AdminService.getData(AdminService.urls.readClientDomainListApiUrl + "?clientId=" + clientId + "&skip=" + dataState.skip + "&take=" + dataState.take +  "&sortBy=" + sortJSON + "&filter=" + filterJSON);
        setLoading(false);
        if (responseData != null && responseData.totalCount > 0) {
            const domainsData = responseData.data as unknown as ClientDomainList[];
            if (domainsData != null) {
                setClientDomainList(domainsData);
                setBackupItems(domainsData);
                setCount(responseData.totalCount);
            }
        }
    }
    const remove = (dataItem: ClientDomainList) => {
        const newData = deleteItem(dataItem);

    };

    const deleteItem = (dataItem: ClientDomainList) => {
        setLoading(true);
        const result = AdminService.deleteClientDomain(dataItem.id)
            .then(function (response: any) {
                setLoading(false);
                const _items = [...backupItems];
                let index = _items.findIndex((record) => record.id === dataItem.id);
                _items.splice(index, 1);
                setBackupItems([..._items]);
                setClientDomainList([..._items]);
                getClientDomainData(dataState);
            })

    };
    const add = (dataItem: ClientDomainList) => {
        dataItem.inEdit = true;
        const newData = insertItem(dataItem);
    };
    const  insertItem = (dataItem: ClientDomainList) => {
        setLoading(true);
        const addClientDomainResult = AdminService.createClientDomain(dataItem)
            .then(async function (response: any) {
                setLoading(false);
                console.log(response);
                const clientDomainData = response.data;
                if (!clientDomainData.inValid) {
                    await getClientDomainData(dataState);
                    dataItem.inEdit = false;
                    dataItem.id = clientDomainData.data.id;
                    const _items = [...ClientDomainList];

                    let index = _items.findIndex((p) => p === dataItem);
                    if (index >= 0) {
                        _items[index] = { ...dataItem };
                    }
                    setBackupItems([..._items]);
                    setClientDomainList([..._items]);
                    getClientDomainData(dataState);
                }
                else {

                    alert(clientDomainData.message);
                }
            })
    };
    const getSortBy = (sort: Array<SortDescriptor>) => {
        setDataState({ ...dataState, skip: 0, take: 100, sort: sort });
    }
    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || "";
        const newData = ClientDomainList.map((item) =>
            item.id === event.dataItem.id
                ? { ...item, [field]: event.value }
                : item
        );

        setClientDomainList(newData);
    };
    const discard = (dataItem: ClientDomainList) => {
        const newData = [...ClientDomainList];
        newData.splice(0, 1);
        setClientDomainList(newData);
    };
    const update = (dataItem: ClientDomainList) => {
        setLoading(true);
        const result = AdminService.createClientDomain(dataItem)
            .then(function (response: any) {
                setLoading(false);
                const clientDomainData = response.data;
                if (!clientDomainData.inValid) {
                    dataItem.inEdit = false;
                    const newData=[...ClientDomainList]
                    let index = newData.findIndex((record) => record.id === dataItem.id);
                    newData[index] = dataItem;
                    setClientDomainList(newData);
                    setBackupItems(newData);
                }
                else {
                    alert(clientDomainData.message);
                }
            })
    };
    const updateItem = (dataItem: ClientDomainList) => {
        let index = ClientDomainList.findIndex((record) => record.id === dataItem.id);
        ClientDomainList[index] = dataItem;
        return ClientDomainList;
    };
    const cancel = (dataItem: ClientDomainList) => {
        const originalItem = backupItems.find(
            (p: any) => p.id === dataItem.id
        );
        const newData = ClientDomainList.map((item) =>
            originalItem ? (item.id === originalItem.id ? originalItem : item) : item
        );

        setClientDomainList(newData);
    };
    const enterEdit = (dataItem: ClientDomainList) => {
        let newData = ClientDomainList.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
        );
        if (dataItem.domain && dataItem.classification) {
            setDisableAdd(false);
        } else {
            setDisableAdd(true);
        }
        setClientDomainList(newData);
    };

    const addNew = () => {
        gridRef.current && gridRef.current.scrollIntoView({ rowIndex: 1 }); 
        const newDataItem: ClientDomainList = {
            inEdit: true,
            domain: "",
            category:"",
            classification: "",
            clientNotation: "",
            searchTerms: "",
            clientId: clientId
        };

        setClientDomainList([newDataItem, ...backupItems]);
    };
    return (
        <div id="clientDomain">
            <div className="grid-wrapper prj-grid-wrapper papergrid-space">
                <Grid container alignItems="center">
                    <Grid className="button-wrapper">
                        <BackButton />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <h3 className="section-head">{clientName} </h3>
                    </Grid>
                </Grid>
            </div>
            <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title="Client Domains List" >
                    <div className="k-widget k-grid" role="grid">
                            <div className="col-md-12 text-right client-list-btn-wrapper">
                                <Button onClick={addNew}
                                    themeColor={"primary"}>
                                    Add Domain
                                </Button>
                                <CSVLink headers={headersDownload} filename={"Client Domains List.csv"} data="">
                                    <Button
                                        themeColor={"primary"}>
                                        Download Template
                                    </Button>
                                </CSVLink>
                                <Button onClick={confirmImportfn}
                                    themeColor={"primary"}>
                                    Import
                                </Button>
                                {visible && <Dialog title={<CustomTitleBar />} onClose={closeDialog}>
                                    <div>
                                        <FileUploadWithImport pageType="ClientDomain" clientId={clientId} setVisible={setVisible} sendStatus={sendStatus} gdOption="" />
                                    </div>
                            </Dialog>}
                            <CSVLink ref={csvRefLink} filename={"Client Domains List.csv"} headers={headers} data={exportCDL}>
                                </CSVLink>
                                <Button onClick={exportData}
                                    themeColor={"primary"}
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">
                                    Export
                                </Button>
                                {isConfirm && <Dialog onClose={closeDialog}>
                                <ConfirmImport pageType="CDL" setVisible={setVisible} confirmImportClose={confirmImportClose} />
                                </Dialog>}
                            </div>
                    </div>
                    <div className="client-domain">
                        {loading && loadingPanel}
                        <AdvancedGrid data={ClientDomainList} columns={columns}
                            gridRef={gridRef}
                            changePagination={changePagination} totalCount={count}
                            dataState={dataState}
                            itemChange={itemChange}
                            sortBy={getSortBy}
                            editField={editField} filterOperators={{
                                text: [{ text: "grid.filterContainsOperator", operator: "contains" }]
                            }}
                            onFilterChange={gridFilterChange}/>

                    </div>
                </TabStripTab>
                <TabStripTab title="Client Communicators List">
                    <ClientCommuniatorList clientId={clientId} />
                </TabStripTab>

            </TabStrip>
        </div>
    );
};
export default ClientDomain
