import * as React from 'react';
import { Container } from 'reactstrap';
import { HeaderComponent } from './common/header/header';
import { FooterComponent } from './common/footer/footer';
import MainDrawer from "../components/edge/nav/MainDrawer";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import GlobalDomain from "./globaldomain/GlobalDomain";
import ClientDomain from './clientdomain/ClientDomain';
import Project from './projects/Project';
import "./Layout.scss";
import ProjectsList from './projects/ProjectsList';
import ClientList from './clientdomain/ClientList';
import SaveClient from './clientdomain/SaveClient';
import SaveProject from './projects/SaveProject';
import Login from './Login/login';
import CIExport from './files/CIExport';


export default (props: { children?: React.ReactNode }) => (
        <React.Fragment>
            <HeaderComponent />
            <FooterComponent />
            <BrowserRouter>
                <MainDrawer>
                    <Switch>
                        <Route exact={true} path="/" component={ProjectsList} />
                        <Route exact={true} path="/login" component={Login} />
                        <Route exact={true} path="/ProjectsList" component={ProjectsList} />
                        <Route exact={true} path="/ClientList" component={ClientList} />
                        <Route exact={true} path="/GlobalDomain" component={GlobalDomain} />
                        <Route exact={true} path="/Project/:id" component={Project} />
                        <Route exact={true} path="/ClientDomain/:id" component={ClientDomain} />
                        <Route exact={true} path="/SaveClient" component={SaveClient} />
                        <Route exact={true} path="/SaveProject" component={SaveProject} />
                        <Route exact={true} path="/CIExport" component={CIExport} />
                    </Switch>
                </MainDrawer>
            </BrowserRouter>
        </React.Fragment>
    );
