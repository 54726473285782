

export  interface IFieldMapping {
    ControlNumber: string;
    GroupIdentifier: string;
    EmailFrom: string;
    EmailTo: string;
    EmailCc: string;
    EmailBcc: string;
}

export default class FieldMappingFileInfo {
    public file!: string;
    public headers!: string[];
    public uid!: string;
}