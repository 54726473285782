import React, { useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DialogContent } from "@material-ui/core";
import { Grid, GridColumnProps, GridFilterCellProps, GridFilterChangeEvent, GridItemChangeEvent } from "@progress/kendo-react-grid";
import AdvancedGrid from "../grid/AdvancedGrid";
import AdminService from "../../services/AdminService";
import CommunicatorIndexList from "../../models/CommunicatorIndexList";
import ResponseInfo, { IResponseModel } from "../../models/ResponseInfo";
import { composeSortDescriptors, CompositeFilterDescriptor, FilterDescriptor, State } from '@progress/kendo-data-query';
import CommunicatorIndexResponseModel from "../../models/CommunicatorIndexResponseModel";
import { CellRender, RowRender } from "../grid/Renderers";
import { GridCellProps, GridRowProps } from '@progress/kendo-react-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { CSVLink } from "react-csv";
import FileUpload from "../files/FileUploadWithFieldMapping";
import { columnInterface } from "../../models/columnInterface";
import ProjectsListResponseModel from "../../models/ProjectsListResponseModel";
import ProjectInfo from "./ProjectInfo";
import Project from "../../models/Project";
import ConfirmExport from "./ConfirmExport";
import Jobs from "../../models/Jobs";
import FrequentFunctions from "../common/FrequentFunctions";
import { DropdownFilterCell } from "../common/DropdownFilterCellProps";
import { RadioButton, RadioButtonChangeEvent } from "@progress/kendo-react-inputs";
import CIExport from "../files/CIExport";

const CustomTitleBar = () => {
    return (
        <div className="custom-title">
            <span>
                <strong>File Upload Wizard</strong>
            </span>
        </div>
    );
};

const ProjectDocs = (props: any) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [dialogVisible, setDialogVisible] = React.useState(false);
    const [cIExportDialogVisible, setCIExportDialogVisible] = React.useState(false);
    const [unmatchedData, setUnmatchedData] = React.useState<string[]>([]);
    const [isImportSuccess, setIsImportSuccess] = React.useState<boolean>(false);
    const [communicatorIndex, setCommunicatorIndex] = React.useState<CommunicatorIndexList[]>([]);
    const sortBy = "desc"
    const [dataState, setDataState] =
        React.useState<State>({ take: 100, skip: 0, sort: [{ field: "docCount", dir: "desc" }] });
    const [count, setCount] = React.useState(0);
    const csvRefLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const csvRefExportCategorizationLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const csvCIRefLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const [documentData, setDocumentData] = React.useState<Document[]>([]);
    const [ciData, setCIData] = React.useState<CommunicatorIndexList[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [projectsList, setProjectsList] = React.useState<Project>({} as Project);
    const EDIT_FIELD = "inEdit";
    const Title = "Communicator Index"
    const { id } = props.match.params;
    const [clientIdParam, setClientIdParam] = React.useState<number>(0);
    const [jobId, setJobId] = React.useState<number>(0);
    const [progressVisible, setProgressVisible] = React.useState(false);
    const [showBKJobInfo, setShowBKJobInfo] = React.useState(false);
    const downloadExportCatRef = useRef<boolean>(false);
    const [selectedValue, setSelectedValue] = React.useState("metadataDocuments");
    const [syncLoading, setSyncLoading] = React.useState(false);
    const [generateCategoryLoading, setGenerateCategoryLoading] = React.useState(false);
    const [showSpan, setShowSpan] = React.useState(false);
    const [showGenerateSpan, setShowGenerateSpan] = React.useState(false);
    const [btnGenerateCategory, setBtnGenerateCategory] = React.useState(false);
    const gridRef = useRef<Grid>(null);

    React.useEffect(() => {
        getCommunicatorIndex(id, dataState.skip, dataState.take, dataState.sort);
    }, [dataState, setCommunicatorIndex]);

    React.useEffect(() => {
        getProjectsList(id);
    }, [setProjectsList]);

    React.useEffect(() => {
        if (jobId == 0) {
            fetchJobFromImport();
        }
    }, [jobId]);

    const toggleDialog = () => {
        setVisible(true);
    }

    const closeDialog = () => {
        setVisible(false);
    }

    const privilegeOptions = ["Yes", "No", "blank"];
    const PrivilegeFilterCell: any = (props: GridFilterCellProps) => (
        <DropdownFilterCell
            {...props}
            data={privilegeOptions}
        />
    );
    const closeConfirmDialog = () => {
        setDialogVisible(false);
        setCIExportDialogVisible(false);
    }

    const headers = [
        { label: "ControlNumber", key: "controlNumber" },
        { label: "Categories", key: "docCategories" },
        { label: "Classifications", key: "docClassifications" },
        { label: "PrivilegeActor", key: "privilegeActor" },
        { label: "EpidCategory", key: "epidCategory" }
    ];
    const ciHeaders = [
        { key: "originalValue", label: "Original Value" },
        { key: "emailAddress", label: "Email Address" },
        { key: "docCount", label: "Doc Count" },
        { key: "exchangeAddress", label: "Exchange Address" },
        { key: "domain", label: "Domain" },
        { key: "lastName", label: "Last Name" },
        { key: "firstName", label: "First Name" },
        { key: "title", label: "Title" },
        { key: "privilegeActor", label: "Privilege Actor" },
        { key: "category", label: "Category" },
        { key: "classification", label: "Classification" },
        { key: "clientNotation", label: "Notation" }
    ];
    const columns: Array<GridColumnProps> = [
        { field: "originalValue", width: 400, title: "Original Value", editable: false },
        { field: "emailAddress", width: 250, title: "Email Address", editable: false },
        { field: "docCount", width: 150, title: "Count", editable: false },
        { field: "exchangeAddress", width: 200, title: "Exchange Address", editable: false },
        { field: "domain", width: 200, title: "Domain", editable: false },
        { field: "lastName", width: 200, title: "Last Name" },
        { field: "firstName", width: 200, title: "First Name" },
        { field: "title", width: 200, title: "Title", editable: false },
        { field: "privilegeActor", width: 200, title: "Privilege Actor", editable: false, filter: 'boolean', filterCell: PrivilegeFilterCell, sortable: false },
        { field: "category", width: 200, title: "Category", editable: false },
        { field: "classification", width: 200, title: "Classification", editable: false },
        { field: "clientNotation", width: 200, title: "Notation", editable: false }
    ];

    const updateLoading = (value: boolean) => {
        setLoading(value);
    }
    const closeBKJobInfo = () => {
        setShowBKJobInfo(false);
    }
    const sendStatus = (isImpSuccess: boolean) => {
        setVisible(false);
        setProgressVisible(true);
        setIsImportSuccess(isImpSuccess);
        getCommunicatorIndex(id, dataState.skip, dataState.take, dataState.sort);
    }
    const onJobComplete = () => {
        setBtnGenerateCategory(false);
        setShowGenerateSpan(false);
        setShowSpan(false);
        setSyncLoading(false);
        setGenerateCategoryLoading(false);
        setProgressVisible(false);
        getCommunicatorIndex(id, dataState.skip, dataState.take, dataState.sort);
    }
    const makeProgressVisible = (visible: boolean) => {
        setProgressVisible(visible);
    }
    const jobProgress = (id: number) => {
        setJobId(id);
        setShowBKJobInfo(true);
    }
    const handleChange = React.useCallback(
        (e: RadioButtonChangeEvent) => {
            
            if (e.value === "metadataDocuments")
                setSelectedValue(e.value);
            else
                setSelectedValue(e.value);
        },
        [setSelectedValue]
    );
    const updateNameCommunicatorIndex = (communicatorIndex: CommunicatorIndexList) => {
        setLoading(true);
        const result = AdminService.updateCommunicatorIndices(communicatorIndex)
            .then(function (response: any) {
                setLoading(false);
                const responseData = response.data as ResponseInfo;
                if (responseData != null && responseData.isSuccess) {
                    getCommunicatorIndex(id, dataState.skip, dataState.take, dataState.sort);
                }
            });
    }

    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );

    const gridFilterChange = (event: GridFilterChangeEvent) => {
        const newState = { ...dataState, skip: 0, take: 100, filter: event.filter };
        setDataState(newState);
    };
    const changePagination = (skip: number, take: number, newCommunicatorIndexList?: CommunicatorIndexList[]) => {
        console.log(newCommunicatorIndexList);
        if (newCommunicatorIndexList) {
            setCommunicatorIndex([...newCommunicatorIndexList])
        } else {
            gridRef.current && gridRef.current.scrollIntoView({ rowIndex: 1 });
            setDataState({ ...dataState, skip: skip, take: take });
        }
    }

    const getSortBy = (sort: Array<SortDescriptor>) => {
        const sortFields = sort.length > 0 ? sort : [{ field: "docCount", dir: "asc" } as SortDescriptor];
        setDataState({ ...dataState, skip: 0, take: 100, sort: sortFields });
    }

    const getCommunicatorIndex = async (id: number, skip?: number, take?: number, sortBy?: Array<SortDescriptor>, filter?: Array<CompositeFilterDescriptor>) => {
        const filters = [] as Array<CompositeFilterDescriptor>;
        if (dataState.filter) {
            filters.push({ logic: "and", filters: dataState.filter.filters });
        }
        const modifiedFilters = filters.map(compositeFilter => {
            const privilegeActor = compositeFilter.filters.find(
                filter => (filter as FilterDescriptor).field === "privilegeActor"
            ) as FilterDescriptor;

            if (privilegeActor) {
                const value = privilegeActor.value === 'Yes' ? true : privilegeActor.value === 'blank' ? 'blank' : false;
                return {
                    ...compositeFilter,
                    filters: [
                        {
                            ...privilegeActor,
                            value: value
                        }
                    ]
                };
            }

            return compositeFilter;
        });

        setLoading(true);
        const sortJSON = JSON.stringify(sortBy) || "";
        const filterJSON = JSON.stringify(modifiedFilters) || "";
        const responseData = await AdminService.getData(AdminService.urls.communicatorIndicesApiUrl +
            "/" +
            id +
            "?skip=" +
            skip +
            "&take=" +
            take +
            "&sortBy=" +
            sortJSON +
            "&filterBy=" +
            filterJSON);
        setLoading(false);
        if (responseData != null) {
            setCommunicatorIndex(responseData.data as CommunicatorIndexList[]);
            setCount(responseData.totalCount)
        }
    }

    const getProjectsList = (id?: number) => {
        const result = AdminService.getProjectsList(id)
            .then(function (response) {
                console.log(response);
                const projectData = response!.data as Project;
                if (projectData != null) {
                    setProjectsList(projectData);
                    let clientId = projectData.clientId;
                    setClientIdParam(clientId)
                }
            });
    }
    const fetchJobFromImport = async () => {
        try {
            const responseData = await AdminService.getData(AdminService.urls.getByProjectId + "/" + id)
            if (responseData && responseData.data1) {
                setJobId(responseData.data1.jobId);
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    const cancelConfirmExport = () => {
        setBtnGenerateCategory(false);
        setSyncLoading(false);
        setShowGenerateSpan(false);
        setDialogVisible(false);
    }
    const getMatchCommunicatorIndex = async () => {
        setShowSpan(true);
        setSyncLoading(true);
        const responseData = await AdminService.getData(AdminService.urls.getMatchCommunicatorIndex +
            "?projectId=" +
            id +
            "&clientId=" +
            clientIdParam);
        if (responseData != null && responseData.data1 != null) {
            var job = responseData.data1 as Jobs;
            confirmExportJob(job.id);
        }
    }

    const enterEdit = (dataItem: CommunicatorIndexList, field: string | undefined) => {
        const newData = communicatorIndex.map((item) => ({
            ...item,
            [EDIT_FIELD]: item.id === dataItem.id ? field : undefined,
        }));

        console.log(newData);
        changePagination && changePagination(dataState.skip || 0, dataState.take || 100, newData);
        setCommunicatorIndex(newData);
    };

    const itemChange = (event: GridItemChangeEvent) => {
        let field = event.field || "";
        event.dataItem[field] = event.value;
        let newData = communicatorIndex.map((item: any) => {
            if (item.id === event.dataItem.id) {
                item[field] = event.value;
            }
            return item;
        });
        setCommunicatorIndex(newData);
    };
    const sendCIExport = (isImpSuccess: boolean) => {
        setCIExportDialogVisible(false);
    }
    const exitEdit = (id: number) => {
        const communicatorIndexItem = communicatorIndex.filter(x => x.id === id);
        if (communicatorIndexItem && communicatorIndexItem[0].inEdit) {
            ciRecord(communicatorIndexItem[0]);
        }
        const newData = communicatorIndex.map((item) => ({
            ...item,
            [EDIT_FIELD]: undefined
        }));

        setCommunicatorIndex(newData);
    };

    const ciRecord = (communicatorIndexItem: CommunicatorIndexList) => {
        updateNameCommunicatorIndex && updateNameCommunicatorIndex(communicatorIndexItem);
    };

    const customCellRender: any = (
        td: React.ReactElement<HTMLTableCellElement>,
        props: GridCellProps
    ) => (
        <CellRender
            originalProps={props}
            td={td}
            enterEdit={enterEdit}
            editField={EDIT_FIELD} />
    );

    const customRowRender: any = (
        tr: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps
    ) => (
        <RowRender
            originalProps={props}
            tr={tr}
            exitEdit={exitEdit}
            editField={EDIT_FIELD} />
    );
    const ExportCIData = async () => {
        const sortJSON = JSON.stringify(dataState.sort) || "";
        const responseData = await AdminService.getData(AdminService.urls.communicatorIndicesApiUrl +
            "/" + id + "?skip=0" + "&take=" + count + "&sortBy=" + sortJSON);
        try {
            if (responseData != null && responseData.data != null) {
                setLoading(false);
                var resCIData = responseData.data as CommunicatorIndexList[];
                if (resCIData.length > 0) {
                    var newData = resCIData.map((item, i) => {
                        item.originalValue = FrequentFunctions.handleSplChars(item.originalValue);
                        return item;
                    })
                    setCIData(newData);
                    csvCIRefLink && csvCIRefLink.current && csvCIRefLink.current.link.click();
                } else alert("No data is available to export");
            }
        }
        catch (err) {
            console.log(err);
            alert("No data is available to export");
        }
    }
    const ShowExportDialog = async () => {
        setCIExportDialogVisible(true);
    }

    const ExportDocumentData = async () => {
        if (count > 0) {
            setBtnGenerateCategory(true);
            setGenerateCategoryLoading(true);
            setShowGenerateSpan(true);
            const responseData = await AdminService.getData(AdminService.urls.getUnMatchedData + "/" + id);
            if (responseData != null && responseData.data != null) {
                var domains = responseData.data as string[];
                if (domains.length > 0) {
                    setLoading(false);
                    setDialogVisible(true);
                    setUnmatchedData(domains);
                } else {
                    const responseData =
                        await AdminService.getData(AdminService.urls.exportDocumentData +
                            "/" +
                            id +
                            "?hasUnmatchedDomains=false");
                    if (responseData != null && responseData.data1 != null) {
                        var job = responseData.data1 as Jobs;
                        confirmExportJob(job.id);
                    }
                }
            }
        }
        else {
            alert("No data is available to export");
        }
    }
    const getSavedEpidCategoryDownloaded = async () => {
        if (downloadExportCatRef.current) {
            const responseData = await AdminService.getData(AdminService.urls.downloadSavedCategoryFile + "/" + id)
            if (responseData != null && responseData.data != null) {
                var documentData = responseData.data as Document[];
                setDocumentData(documentData);
                if (documentData.length > 0) {
                    csvRefLink && csvRefLink.current && csvRefLink.current.link.click();
                }
                else alert("No data is available to export");
            }
        }
    }
    const confirmExportJob = async (jobId: number) => {
        setJobId(jobId);
        setDialogVisible(false);
        downloadExportCatRef.current = true;
    }
    return (
        <>
            <div>

                <ProjectInfo project={projectsList}
                    jobId={jobId}
                    progressVisible={progressVisible}
                    sendStatus={sendStatus}
                    makeProgressVisible={makeProgressVisible}
                    onJobComplete={onJobComplete}
                    projectid={id}
                    getSavedEpidCategoryDownloaded={getSavedEpidCategoryDownloaded}
                    totalCount={count}
                    
                />
            </div>
            <div className="k-widget k-grid" role="grid">
                <div className="row">
                    <div className="col-sm-4 text-left p-3">
                        <strong>{Title}</strong>
                    </div>
                    <div className="col-sm-8 text-right project-list-wrapper">
                        <Button onClick={getMatchCommunicatorIndex}
                            themeColor={"primary"} disabled={syncLoading}>
                            {showSpan &&
                                <span className={`k-icon ${syncLoading ? "k-i-loading" : ""} k-icon-16`} style={{ marginBottom: 5 }} />}
                            Sync
                        </Button>
                        <Button onClick={toggleDialog}
                            themeColor={"primary"}>
                            Import
                        </Button>
                        {showBKJobInfo && (<Dialog title={<CustomTitleBar />} onClose={closeBKJobInfo} width={600} >
                            <DialogContent >
                                <p>The file(s) have been uploaded. The data will now be validated, processed and ingested in the background and progress can be seen from the progress bar at the top. The data will appear on the screen as soon as the process is complete.</p>
                            </DialogContent>
                            <DialogActionsBar layout={"end"}>
                                <Button themeColor={"primary"} onClick={closeBKJobInfo} type="button"> OK</Button>
                            </DialogActionsBar>
                        </Dialog>)}
                        {
                            visible &&
                            <Dialog title={<CustomTitleBar />} onClose={closeDialog}>
                                    <div className="option-details">
                                    <RadioButton
                                        name="import"
                                        value="metadataDocuments"
                                        checked={selectedValue === "metadataDocuments"}
                                        label="Metadata documents"
                                        onChange={handleChange}
                                    />
                                        <br />
                                        <br />
                                    <RadioButton
                                        name="import"
                                        value="communicatorIndexDocument"
                                        checked={selectedValue === "communicatorIndexDocument"}
                                        label="Communicator Index document"
                                        onChange={handleChange}
                                    />
                                    </div>
                                    <div>
                                    <FileUpload
                                            pageType="InputDocument"
                                            updateLoading={updateLoading}
                                            sendStatus={sendStatus}
                                            closeDialog={closeDialog}
                                            projectId={id}
                                            clientId={clientIdParam}
                                            jobProgress={jobProgress}
                                            selectedValue={selectedValue}
                                    />

                                </div>
                            </Dialog>
                        }
                        <CSVLink
                            ref={csvCIRefLink}
                            filename={projectsList.projectName + "_Communicator Index" + ".csv"}
                            headers={ciHeaders}
                            data={ciData}>
                        </CSVLink>
                        <Button onClick={ShowExportDialog} themeColor={"primary"}>
                            Export
                        </Button>
                        {
                            cIExportDialogVisible &&
                            <Dialog
                                title={<div className="custom-title"><span><strong>CI Export Preference</strong></span></div>}
                                onClose={closeConfirmDialog}
                                width={500}>
                                    <CIExport
                                        projectId={id}
                                        projectName={projectsList.projectName}
                                        dataState={dataState}
                                        count={count}
                                        cIExportDialogVisible={sendCIExport} />
                            </Dialog>}
                        <CSVLink
                            ref={csvRefLink}
                            filename={projectsList.projectName + ".csv"}
                            headers={headers}
                            data={documentData}>
                        </CSVLink>
                        <Button onClick={ExportDocumentData} themeColor={"primary"} disabled={btnGenerateCategory}>
                            {showGenerateSpan &&
                                <span className={`k-icon ${generateCategoryLoading ? "k-i-loading" : ""} k-icon-16`} style={{ marginBottom: 5 }} />}
                            Generate Categorization
                        </Button>
                        {
                            dialogVisible &&
                            <Dialog
                                title={<div className="custom-title"><span><strong>Warning</strong></span></div>}
                                onClose={closeConfirmDialog}
                                width={600}>
                                <ConfirmExport
                                    projectId={id}
                                    unMatchedDoamins={unmatchedData}
                                    projectName={projectsList.projectName}
                                    cancelConfirmExport={cancelConfirmExport}
                                    confirmExportJob={confirmExportJob} />
                            </Dialog>}
                    </div>
                </div>
            </div>
            <div className="project-details">
                {loading && loadingPanel}
                <AdvancedGrid
                    gridRef={gridRef}
                    data={communicatorIndex}
                    columns={columns}
                    Title={Title}
                    changePagination={changePagination}
                    updateNameCommunicatorIndex={updateNameCommunicatorIndex}
                    dataState={dataState}
                    totalCount={count}
                    cellRender={customCellRender}
                    rowRender={customRowRender}
                    itemChange={itemChange}
                    sortBy={getSortBy}
                    filterable={true}
                    filterOperators={{
                        text: [{ text: "grid.filterContainsOperator", operator: "contains" }]
                    }}
                    onFilterChange={gridFilterChange} />

            </div>
            <div className="col-sm-6 text-right">

            </div>
        </>
    )
}
export default ProjectDocs