import { ServiceBase, ServiceResponseJson } from "./ServiceBase";
import AdminService from "./AdminService";

import axios from "axios";
import authHeader from "./authHeader";

const ApiUrls = {
    projectsListApiUrl: 'projects',
}
export default class ProjectService extends ServiceBase {

    static async getProjectsList(skip?: number, take?: number) {
        const headers = {
            'Content-Type': 'application/json', 'Authorization': authHeader()
        };
            let url = AdminService.getBaseGlobalDomainUri() + ApiUrls.projectsListApiUrl + "?skip=" + skip + "&take=" + take;
            const res = await axios.get(url, { headers })
                .catch(( (error: any) => {
                    this.handleError(error);
                    console.log(error);
                    return Promise.reject(error);
                }));

            return res;

        }
 
}