import React from "react";

const AuthVerify = () => {
    const token = localStorage.getItem("accessToken");
    const decodedJwt = JSON.parse(atob(token!.split(".")[1]));
    if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
    }
    else {
        return true;
    }
};

export default AuthVerify;