import { State } from "@progress/kendo-data-query";
import { GridCellProps, GridColumnProps,Grid, GridColumn} from "@progress/kendo-react-grid";
import React, { useState, useEffect } from "react";
import { NavLink,useHistory } from "react-router-dom";
import { columnInterface } from "../../models/columnInterface";
import ProjectList from "../../models/Client"
import AdminService from "../../services/AdminService";
import AdvancedGrid from "../grid/AdvancedGrid";
import ClientDomain from "./ClientDomain";
import Client from "../../models/Client";
import { Button } from "@progress/kendo-react-buttons";
import "./ClientList.scss";
import { IResponseModel } from "../../models/ResponseInfo";

const ClientList = () => {
    const history = useHistory();
    const [clients, setClients] = React.useState<Client[]>([]);
    const [dataState, setDataState] = React.useState<State>({ take: 100, skip: 0 });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [count, setCount] = React.useState(0);

    const Title = "Clients";
    const clientNameCell = (gridCellProps: GridCellProps) => {
        const id = gridCellProps.dataItem["id"];
        const clientName = gridCellProps.dataItem["clientName"];
        return (
            <td><NavLink to={{ pathname: `/clientdomain/${gridCellProps.dataItem["id"]}`, state: { name: clientName} }}>{gridCellProps.dataItem["clientName"]}</NavLink></td>
        )
    }
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );
    useEffect(() => {
        getClientList(dataState.skip, dataState.take);
    }, [dataState,setClients]);
    const columns: Array<GridColumnProps> = [
        { field: "id", width:150, title: "Client ID", editable: false, sortable: false },
        { field: "clientName", title: "Client Name", editable: false, cell: clientNameCell, sortable:false }
    ];
    const changePagination = (skip: number, take: number) => {
        setDataState({ ...dataState, skip: skip, take: take });
    }
    const getClientList = (skip?: number, take?: number) => {
        setLoading(true);
        const result = AdminService.getClients(skip, take)
            .then(function (response) {
                setLoading(false);
                const clientData = response!.data as IResponseModel<Client>;
                if (clientData != null) {
                    setClients(clientData.queryResult);
                    setCount(clientData.totalCount)
                }
            })
    }
    return (
        <>
            <div className="k-widget k-grid" role="grid">
           <div className="row">
                <div className="col-sm-4 text-left p-3">
                        <strong>{Title}</strong>

                </div>
                <div className="col-sm-8 text-right p-3">
                    <Button themeColor={"primary"} onClick={() => history.push("/saveclient")}>+ Client</Button>   
                    </div>
                </div>
                </div>
            <div className="client-list">
                {loading && loadingPanel}
                <AdvancedGrid data={clients} columns={columns} Title={Title} changePagination={changePagination} dataState={dataState} totalCount={count} filterable={false } />
            </div>
        </>
    )
}
export default ClientList