import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import CommunicatorIndexList from '../../models/CommunicatorIndexList';
import "./AdvancedGrid.scss";
import { Grid, GridCellProps, GridColumn as Column, GridColumnProps, GridPagerSettings, GridProps, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';

export interface IGridParams {
    skip?: number;
    take?: number;
    sort?: Array<SortDescriptor>;
    filter?: CompositeFilterDescriptor;
    filters?: Array<CompositeFilterDescriptor>;
}

interface IProps<T> extends GridProps {
    changePagination?: (skip: number, take: number) => void;
    updateNameCommunicatorIndex?: (newUpdateCommunicatorIndexList: CommunicatorIndexList) => void;
    dataState?: any;
    data?: Array<T>;
    totalCount?: number;
    cellRender?: any;
    rowRender?: any;
    itemChange?: any;
    sortBy?: (sort: Array<SortDescriptor>) => void;
    columns: Array<GridColumnProps>;
    Title?: string;
    gridRef?: any;
}
type Props<T> = IProps<T>;

const initialSort: Array<SortDescriptor> = [{ field: 'docCount', dir: 'desc' }]

const ADJUST_PADDING: number = 4;
const COLUMN_MIN: number = 4;
const AdvancedGrid = <T,>(props: Props<T>) => {
    const [selected, setSelected] = React.useState<number>(0);
    const minGridWidth = React.useRef<number>(0);
    const grid = React.useRef<any>(null);
    const [applyMinWidth, setApplyMinWidth] = React.useState(false);
    const [gridCurrent, setGridCurrent] = React.useState(0);
    const [sort, setSort] = React.useState(initialSort);
    const EDIT_FIELD = "inEdit";

    const dataStateChange = (e: any) => {
        console.log(e.dataState);
        props.changePagination && props.changePagination(e.dataState.skip, e.dataState.take);
    }
    const pagerSettings: GridPagerSettings = {
        info: true,
        type: 'numeric',
        pageSizes: [50, 100, 200, 500]
    }
    React.useEffect(() => {
        grid.current = document.querySelector('.k-grid');
        window.addEventListener('resize', handleResize);
        props.columns.map((item: GridColumnProps) => item.width !== undefined ? minGridWidth.current += item.width as number : minGridWidth.current);
        setGridCurrent(grid.current.offsetWidth);
        setApplyMinWidth(grid.current.offsetWidth < minGridWidth.current);
    }, [])

    const CustomTitleBar = () => {
        return (
            <div>
                <span>
                    <strong>{props.Title } ({props.totalCount })</strong></span>
            </div>
        );
    };
    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };
    const handleResize = () => {
        if (grid.current.offsetWidth < minGridWidth && !applyMinWidth) {
            setApplyMinWidth(true);
        } else if (grid.current.offsetWidth > minGridWidth) {
            setGridCurrent(grid.current.offsetWidth);
            setApplyMinWidth(false);
        }
    }

    const setWidth = (minWidth: number | undefined) => {
        minWidth === undefined ? minWidth = 0 : null;
        let width = applyMinWidth ? minWidth :
            minWidth + (gridCurrent - minGridWidth.current) / props.columns.length;
        width < COLUMN_MIN ? width : width -= ADJUST_PADDING;
        return width;
    }

    return (
        <div className="advanced-grid-wrapper">
            <Grid
                        ref={props.gridRef}
                        data={props.data}
                        dataItemKey={"id"}
                        //sortable={{ allowUnsort: true, mode: "multiple" }}
                        sortable={true }
                        sort={sort}
                        resizable={true}
                        total={props.totalCount}
                        pageSize={50}
                        pageable={pagerSettings}
                        {...props.dataState}
                        onItemChange={props.itemChange}
                        cellRender={props.cellRender}
                        rowRender={props.rowRender}
                        onDataStateChange={dataStateChange}
                        onSortChange={(e: GridSortChangeEvent) => {
                            props.sortBy && props.sortBy(e.sort);
                            setSort(e.sort);
                        }}
                        editField={EDIT_FIELD}
                        filterable={true}
                        {...props}
                    >
                        {
                            props.columns.map((column: GridColumnProps, index: number) => {
                                return <Column {...column} field={column.field} title={column.title} editable={column.editable} key={index}  sortable={column.sortable} />;
                            })
                        }
                    </Grid>
        </div>
    );
}

export default AdvancedGrid;