import React, { useState, useEffect } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DialogContent } from "@material-ui/core";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import AdminService from "../../services/AdminService";
import "./FieldMapping.scss";
import FieldMappingFileInfo, { IFieldMapping } from "../../models/FieldMapping";
import { Popup } from "@progress/kendo-react-popup";
import FieldMappingWithWarning from "./FieldMappingWithWarning";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import ResponseInfo from "../../models/ResponseInfo";
import authHeader from "../../services/authHeader";

interface IProps {
    data: string[],
    pageType?: string,
    sendStatus?: (isImpSuccess: boolean) => void,
    fieldMappingHide: () => void,
    closefieldMapping: () => void,
    updateLoading: (value: boolean) => void,
    projectId: number,
    clientId: number,
    filesHeaders: string[],
    fileMapping?: FieldMappingFileInfo[],
    files: Array<UploadFileInfo>,
    updateFileProgress: (fileName: string, progressValue: number) => void,
    jobProgress: (value: number) => void
}
type Props = IProps;
const CustomTitleBar = () => {
    return (
        <h2 className="custom-title">
            Field Mapping
        </h2>
    );
};

const FieldMapping = (props: Props) => {
    const anchor = React.useRef<HTMLButtonElement | null>(null);
    const [visible, setVisible] = React.useState(true);
    const [ValueControlNumber, setValueControlNumber] = React.useState<string>("");
    const [ValueGroupIdentifier, setValueGroupIdentifier] = React.useState<string>("");
    const [ValueFrom, setValueFrom] = React.useState<string>("");
    const [ValueTo, setValueTo] = React.useState<string>("");
    const [ValueCc, setValueCc] = React.useState<string>("");
    const [ValueBcc, setValueBcc] = React.useState<string>("");
    const [show, setShow] = React.useState(false);
    const [inValidFiles, setInValidFiles] = React.useState([] as Array<string>);
    const [files, setFiles] = React.useState<Array<UploadFileInfo>>([]);
    const closeReviewDialog = () => {
        setVisible(false);
        setShow(false);
    }
    useEffect(() => {
        setFiles(props.files);
        setValueControlNumber(props.data.find(x => x.toLowerCase() === "controlnumber") || "");
        setValueGroupIdentifier(props.data.find(x => x.toLowerCase() === "groupidentifier") || "");
        setValueFrom(props.data.find(x => x.toLowerCase() === "from") || "");
        setValueTo(props.data.find(x => x.toLowerCase() === "to") || "");
        setValueCc(props.data.find(x => x.toLowerCase() === "cc") || "");
        setValueBcc(props.data.find(x => x.toLowerCase() === "bcc") || "");

    }, [props.files]);
    const sizes = props.data;
    const handleChangeControlNumber = (event: DropDownListChangeEvent) => {
        setValueControlNumber(event.target.value);
    };
    const handleChangeGroupIdentifier = (event: DropDownListChangeEvent) => {
        setValueGroupIdentifier(event.target.value);
    };
    const handleChangeFrom = (event: DropDownListChangeEvent) => {
        setValueFrom(event.target.value);
    };
    const handleChangeTo = (event: DropDownListChangeEvent) => {
        setValueTo(event.target.value);
    };
    const handleChangeCc = (event: DropDownListChangeEvent) => {
        setValueCc(event.target.value);
    };
    const handleChangeBcc = (event: DropDownListChangeEvent) => {
        setValueBcc(event.target.value);
    };
    const cancel = () => {
        props.fieldMappingHide();
    }
    const fieldMapping: IFieldMapping = {
        ControlNumber: ValueControlNumber,
        GroupIdentifier: ValueGroupIdentifier,
        EmailFrom: ValueFrom,
        EmailTo: ValueTo,
        EmailCc: ValueCc,
        EmailBcc: ValueBcc
    }
    const uploadSubmit = () => {
        const fieldMappingString = JSON.stringify(Object.values(fieldMapping).sort());
        let filteredFiles = props.files;
        props.fileMapping && props.fileMapping.forEach((item) => {
            if (JSON.stringify(item.headers.sort()) !== fieldMappingString) {
                inValidFiles.push(item.file);
                filteredFiles = filteredFiles.filter((obj) => { return obj.uid !== item.uid; });

                setInValidFiles(inValidFiles);
            }
        });
        setFiles(filteredFiles);
        if (inValidFiles.length > 0) {
            setShow(true);
        }
        else {
            uploadFilesAny();
        }
    }

    const uploadFilesAny = async () => {
        const jsonObj: any = {
            "type": 2,
            "name": "upload",
            "context": {
                "clientid": (props.clientId).toString(),
                "projectid": (props.projectId).toString()
            }
        };
        const metadata :any = { "fieldmapping": fieldMapping };
        closeReviewDialog();
        const body: any = { 'importContext': JSON.stringify(jsonObj), 'importMetadata': JSON.stringify(metadata) }
        const responseData =
            await AdminService.postWithParameter(AdminService.urls.beginChunkImport + "/Document", body);
        let importId = 0;
        for (let i = 0; i < files.length; i++) {
            if (responseData != null) {
                var responseInfo = responseData.data as unknown as ResponseInfo;
                if (responseInfo.isSuccess) {
                    importId = responseInfo.data1.id;
                    //props.updateLoading(true);
                    await uploadFile(files[i], importId);
                }
                else {
                   return console.log(responseInfo.errorMessage);
                }
            }
        }
        const responseData1 =
           await AdminService.getData(AdminService.urls.endChunkImport + "/" + importId);
        if (responseData1 != null) {
            var responseInfo = responseData1.data1 as unknown as ResponseInfo;
            props.jobProgress(responseData1.data1.id);
        }
        props.sendStatus && props.sendStatus(true);
       
        //props.updateLoading(false);        
    }

    const uploadFile = async (file: any, importId: number) => {
        const chunkSize = 1024 * 1024;
        for (let start = 0; start < file.size; start += chunkSize) {
            const chunk = file.getRawFile().slice(start, start + chunkSize);
            const formData = new FormData();
            formData.append('file', chunk, file.name);
            await fetch(
                AdminService.getBaseGlobalDomainUri() + AdminService.urls.uploadChunk + "/" + importId + "/" + start,
                { method: 'post', body: formData, headers: { 'Authorization': authHeader() } }).
                then(res => {
                    res.text();
                    const chunkProgress = Math.ceil((start + chunkSize) * 100 / file.size);
                    props.updateFileProgress(file.name, chunkProgress > 100 ? 100 : chunkProgress);
                });
        }
    }

    return (
        <div>
            {visible && (<Dialog title={<CustomTitleBar />} onClose={closeReviewDialog} width={600} >
                <div className="k-form">
                    <DialogContent >
                        <div className="row field-mapping">
                            <div className="col-6"><h5>Target</h5></div>
                            <div className="col-6"><h5>Source</h5></div>
                        </div>
                        <div className="field-wrapper">
                            <div className="row field-wrapper-row">
                                <div className="col-6 field-wrapper-label">ControlNumber</div>
                                <div className="col-6"><DropDownList value={ValueControlNumber}
                                    onChange={handleChangeControlNumber} data={sizes}
                                /></div>
                            </div>

                            <div className="row field-wrapper-row">
                                <div className="col-6 field-wrapper-label">GroupIdentifier</div>
                                <div className="col-6"><DropDownList value={ValueGroupIdentifier}
                                    onChange={handleChangeGroupIdentifier} data={sizes} /></div>
                            </div>

                            <div className="row field-wrapper-row">
                                <div className="col-6 field-wrapper-label">From</div>
                                <div className="col-6"><DropDownList value={ValueFrom}
                                    onChange={handleChangeFrom} data={sizes} /></div>
                            </div>
                            <div className="row field-wrapper-row">
                                <div className="col-6 field-wrapper-label">To</div>
                                <div className="col-6"><DropDownList value={ValueTo}
                                    onChange={handleChangeTo} data={sizes} /></div>
                            </div>
                            <div className="row field-wrapper-row">
                                <div className="col-6 field-wrapper-label">CC</div>
                                <div className="col-6"><DropDownList value={ValueCc}
                                    onChange={handleChangeCc} data={sizes} /></div>
                            </div>
                            <div className="row field-wrapper-row">
                                <div className="col-6 field-wrapper-label">BCC</div>
                                <div className="col-6"><DropDownList value={ValueBcc}
                                    onChange={handleChangeBcc} data={sizes} /></div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActionsBar layout={"end"}>
                        <Button themeColor={"primary"} type="button" onClick={cancel}> Cancel</Button>
                        <Button themeColor={"primary"} onClick={uploadSubmit} type="button"> Submit</Button>
                    </DialogActionsBar>
                </div>
            </Dialog>)}
            <Popup anchor={anchor.current} show={show} popupClass={"popup-content"}>
                <FieldMappingWithWarning inValid={inValidFiles} uploadFilesAny={uploadFilesAny} fieldMappingHide={props.fieldMappingHide} />
            </Popup>
        </div>

    )
}
export default FieldMapping;