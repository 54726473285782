import React from "react";
import { GridCell } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { CircularProgress } from "@material-ui/core";
interface IProps {
    saving?: boolean;
    disableAdd?: boolean;
}
type Props = IProps;
const CommandCell = (props: any) => {

    const { dataItem } = props;
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem.id === undefined;
    return inEdit ? (
        <td className="k-command-cell">
            <Button
                icon={isNewItem ? "plus" : "save"}
                className="icon-button"
                onClick={() =>
                    isNewItem ? props.add(dataItem) : props.update(dataItem)
                }
                disabled={props.disableAdd}
            >
                {/*{isNewItem ? "Add" : "Update"}*/}
            </Button>
            <Button
                icon={isNewItem ? "delete" : "close"}
                className="icon-button"
                onClick={() =>
                    isNewItem ? props.discard(dataItem) : props.cancel(dataItem)
                }
            >
                {/*{isNewItem ? "Discard" : "Cancel"}*/}
            </Button>
        </td>
    ) : (
        <td className="k-command-cell">
            <Button
                icon="edit"
                className="icon-button"
                onClick={() => props.edit(dataItem)
                }
                disabled={props.saving}
            >
            </Button>
            <Button
                icon="delete"
                className="icon-button"

                    onClick={() =>
                   
                        confirm("Confirm deleting: " + (props.type === "ccl" ? dataItem.displayName :dataItem.domain)) &&
                    props.remove(dataItem)
                }
                disabled={props.saving}
            >
            </Button>
        </td>
    );

};
export default CommandCell