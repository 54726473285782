import * as React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import PersonIcon from "@material-ui/icons/Person";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useHistory } from "react-router-dom";
import "./header/header";
import { localStorageGetItem } from "../../store/LocalStore";
import User from "../../models/User";

interface IRightDropDownMenuOption {
    rightDropDownMenuOptions?: IDropDownMenuOption[];
    userEmail?: string;
    userName?: string;
}

export interface IDropDownMenuOption {
    onItemClick?: (event: React.MouseEvent<HTMLElement>) => void;
    title: string;
    url?: string;
}
const UserContainer = (props: IRightDropDownMenuOption) => {
    const [open, setOpen] = React.useState(false);
    const [userName, setUserName] = React.useState("");
    const [userEmail, setuserEmail] = React.useState("");
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };
    const history = useHistory();
    const logOut = () => {
        localStorage.clear();
        history.push("/login");
        window.location.reload();
    }
    const handleClose = (event: any) => {
        setOpen(false);
    };

    function handleListKeyDown(event: any) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(
        () => {
            var userDetails = localStorageGetItem("userSession", "");
            let user: User = JSON.parse(userDetails);
            var fullName = user.firstName + " " + user.lastName;
            setUserName(fullName);

            setuserEmail(user.emailAddress);
            prevOpen.current = open;
        },
        [open, userEmail, userName]
    );
    let tooltip: any = "";
    return (
        <>

            <Button
                ref={anchorRef}
                role="right-menu-expand"
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                {<PersonIcon />}
                <span className="arrow-down" />
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom"
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <div className="user-info">
                                        {userName && userName.length > 30 ? (
                                            <>
                                                <div
                                                    id="user-name"
                                                    title={userName}
                                                    onMouseOver={event =>
                                                        tooltip && tooltip.handleMouseOver(event)
                                                    }
                                                    onMouseOut={event =>
                                                        tooltip && tooltip.handleMouseOut(event)
                                                    }
                                                >
                                                    {`${userName.slice(0, 30)} ...`}
                                                </div>
                                                <Tooltip
                                                    ref={el => (tooltip = el)}
                                                    openDelay={300}
                                                    position="top"
                                                    anchorElement="target"
                                                    className="tooltip-email"
                                                />
                                            </>
                                        ) : (
                                            <div id="user-name">{userName}</div>
                                        )}

                                        {userEmail && userEmail.length > 26 ? (
                                            <>
                                                <div
                                                    id="user-email"
                                                    title={userEmail}
                                                    onMouseOver={event =>
                                                        tooltip && tooltip.handleMouseOver(event)
                                                    }
                                                    onMouseOut={event =>
                                                        tooltip && tooltip.handleMouseOut(event)
                                                    }
                                                >
                                                    {`${userEmail.slice(0, 26)} ...`}
                                                </div>
                                                <Tooltip
                                                    ref={el => (tooltip = el)}
                                                    openDelay={300}
                                                    position="top"
                                                    anchorElement="target"
                                                    className="tooltip-email"
                                                />
                                            </>
                                        ) : (
                                            <div id="user-email">{userEmail}</div>
                                        )}
                                        <button id="user-logout" onClick={logOut}> Logout</button>
                                    </div>
                                    {props.rightDropDownMenuOptions
                                        ? props.rightDropDownMenuOptions.map(item => (
                                            <MenuItem onClick={handleClose} key={item.title}>
                                                {item.url ? (
                                                    <NavLink
                                                        className="nav-link"
                                                        to={item.url}
                                                        key={"Link" + item.title}
                                                        exact
                                                    >
                                                        {item.title}
                                                    </NavLink>
                                                ) : (
                                                    <span
                                                        className="nav-link"
                                                        onClick={item.onItemClick}
                                                    >
                                                        {" "}
                                                        {item.title}
                                                    </span>
                                                )}
                                            </MenuItem>
                                        ))
                                        : ""}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default UserContainer
