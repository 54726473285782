import React from "react";
import ReactDOM from "react-dom";
import { Form, Field } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Upload } from "@progress/kendo-react-upload";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import axios from "axios";
import $ from 'jquery';
import { DialogContent } from "@material-ui/core";
import AdminService from "../../services/AdminService";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import FileUploadResponse from "../../models/FileUploadResponse";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import bgMessages from '../../Localization/en.json';
loadMessages(bgMessages, 'en-US');

interface IProps {
    pageType?: string;
    sendStatus?: (isImpSuccess: boolean) => void;
    clientId: number;
}
interface LocaleInterface {
    language: string;
    locale: string;
}
type Props = IProps;

const FileUpload = (props: Props) => {
    const [fileuploadresult, setfileuploadresult] = React.useState<FileUploadResponse>();
    const [disableSubmit, setDisableSubmit] = React.useState<boolean>(true);
    const UploadInput = (fieldRenderProps: any) => {
        const onChangeHandler = (event: any) => {
            fieldRenderProps.onChange({ value: event.newState });
            setDisableSubmit(false);
            $("#uploadSuccessLbl").css("display", "none");
        };
        const onRemoveHandler = (event: any) => {
            setDisableSubmit(true);
            fieldRenderProps.onChange({ value: event.newState });
        };
        const onProgressHandler = (event: any) => {
            fieldRenderProps.onChange({ value: event.newState });
        };
        const locales: LocaleInterface[] = [
            {
                language: "en-US",
                locale: "en",
            },
        ];

        const [currentLocale, setCurrentLocale] = React.useState<LocaleInterface>(
            locales[0]
        );

        return (
            <LocalizationProvider language={currentLocale.language}>
                <IntlProvider locale={currentLocale.locale} >
                    <Upload
                        batch={false}
                        multiple={false}
                        restrictions={{
                            allowedExtensions: [".csv"],
                        }}
                        autoUpload={false}
                        showActionButtons={false}
                        files={fieldRenderProps.value}
                        onAdd={onChangeHandler}
                        onRemove={onRemoveHandler}
                        onProgress={onProgressHandler} />
                </IntlProvider>
            </LocalizationProvider>
        );
    };
    const handleSubmit = (dataItem:any) => {
        setDisableSubmit(true);
        const { files } = dataItem;
        const formData = new FormData();
        formData.append('formFile', files[0].getRawFile());
        formData.append('fileName', files[0].name);
        // submit your formData
        const result = AdminService.uploadFile(formData, props.clientId, props.pageType)
            .then(function (response:any) {
                setfileuploadresult(response.data as FileUploadResponse);
                $("#uploadSuccessLbl").css("display", "block");
                $('.fileloading').addClass('k-i-export');
                if (response.data && response.data.isSuccess)
                    props.sendStatus && props.sendStatus(true);
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    return (
        <Form
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
                <form onSubmit={formRenderProps.onSubmit} className={"k-form"}>
                    <DialogContent>
                        <fieldset>
                            <div className="mb-3">
                                Select a file to upload
                                <Field name={"files"} component={UploadInput} />
                            </div>
                        </fieldset>
                        <div>
                            {fileuploadresult && (fileuploadresult.isSuccess?
                                <span id="uploadSuccessLbl" style={{ color: "green", display: "none" }}> <label>File Uploaded Succesfully</label></span> :
                                <span id="uploadSuccessLbl" style={{ color: "red", display: "none" }}> <label>{fileuploadresult.message}</label></span>)}
                        </div>
                    </DialogContent>
                    <DialogActionsBar layout={"end"}>

                        <Button
                            type={"submit"}
                            themeColor={"primary"}
                            disabled={disableSubmit}>
                            <span className="k-icon k-i-export k-icon-16 fileloading" style={{ marginBottom: 5 }} />Upload
                        </Button>
                    </DialogActionsBar>
                </form>
            )}
        />
    );
};
export default FileUpload;
