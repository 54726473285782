import React, { Component, useRef, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DialogContent } from '@material-ui/core';

interface IProps {
    pageType?: string;
    setVisible: (value: boolean) => void;
    confirmImportClose: (value: boolean) => void;
}
type Props = IProps;

const ConfirmImport = (props: any) => {
    const [confirmMsg, setConfirmMsg] = React.useState<String>("");
    const clickOk = () => { props.confirmImportClose(false); props.setVisible(true); }
    const clickCancel = () => { props.confirmImportClose(true); props.setVisible(false); }
    const setStaticConfirmMsg = () => {
        if (props.pageType == "GDL")
            setConfirmMsg("Uploading a new file will overwrite the existing list. All the matched Category records in the Communicator Index will be lost.");
       else if (props.pageType == "CDL")
            setConfirmMsg("Uploading a new file will overwrite the existing list. All the matched Classification records in the Communicator Index will be lost.");
       else if (props.pageType == "CCL")
            setConfirmMsg("Uploading a new file will overwrite the existing list. All the matched Title and Privilege Actor records in the Communicator Index will be lost.");
    }
    useEffect(() => { setStaticConfirmMsg(); });
   return (
    <div>
           <DialogContent><p>{confirmMsg}</p></DialogContent>
            <DialogActionsBar layout={"end"}>
                <Button themeColor={"primary"} onClick={clickOk}>
                    Ok
                </Button>
                <Button themeColor={"primary"} onClick={clickCancel}>
                    Cancel</Button>
        </DialogActionsBar>
    </div>
); 
}
export default ConfirmImport;