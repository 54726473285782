import { Button } from "@progress/kendo-react-buttons";
import React, { useRef } from "react";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import AdminService from "../../services/AdminService";
import ConfirmImport from "../files/ConfirmImport";
import CommunicatorList from '../../models/ClientCommunicatorList';
import { Dialog } from "@progress/kendo-react-dialogs";
import FileUpload from "../files/FileUpload";
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor, State } from "@progress/kendo-data-query";
import AdvancedGrid, { IGridParams } from "../grid/AdvancedGrid";
import { GridCellProps, GridColumnProps, GridFilterCellProps, GridFilterChangeEvent, GridItemChangeEvent,Grid } from "@progress/kendo-react-grid";
import { formatDate } from "@telerik/kendo-intl";
import "./ClientCommunicatorList.scss";
import '@progress/kendo-theme-material/dist/all.css';
import * as ReactDOM from "react-dom";
import { Error } from "@progress/kendo-react-labels";
import CommandCell from "../grid/columns/CommandCell";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { TextBox } from "@progress/kendo-react-inputs";
import ClientCommunicatorList from "../../models/ClientCommunicatorList";
import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import FrequentFunctions from "./../common/FrequentFunctions";
import { DropdownFilterCell } from "../common/DropdownFilterCellProps";

interface IProps {
    clientId: number,
}
const CustomTitleBar = () => {
    return (
        <div className="custom-title">
            <span><strong>File Upload Wizard</strong></span>
        </div>
    );
};
type Props = IProps;
const ClientCommuniatorList = (props: Props) => {
    const [communicatorListData, setCommunicatorListData] = React.useState<CommunicatorList[]>([]);
    const [exportCLData, setExportCLData] = React.useState<CommunicatorList[]>([]);
    const [backupItems, setBackupItems] = React.useState<CommunicatorList[]>([]);
    const [isConfirm, setIsConfirm] = React.useState<boolean>(false);
    const [visible, setVisible] = React.useState<boolean>(false);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [dataState, setDataState] = React.useState<IGridParams>({ take: 100, skip: 0, sort: [{ field: "domain", dir: "asc" }] });
    const [disableAdd, setDisableAdd] = React.useState<boolean>(true);
    const editField = "inEdit";
    const csvRefLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const gridRef = useRef<Grid>(null);
    const clientCommunicatorDownload = ["DisplayName", "LastName", "FirstName", "NickName", "EmailAddress", "Domain", "OrganizationName", "Title", "PrivilegeActor", "StartDate", "EndDate", "Notation", "SearchTerms"]
    const confirmCCImport = () => {
        if (communicatorListData.length > 0) {
            setIsConfirm(true);
        }
        else
            setVisible(true)
    }
    const confirmImportClose = (value: boolean) => {
        setIsConfirm(false);
    }
    const communicatorHeaders = [
        { label: "DisplayName", key: "displayName" },
        { label: "LastName", key: "lastName" },
        { label: "FirstName", key: "firstName" },
        { label: "NickName", key: "nickName" },
        { label: "EmailAddress", key: "emailAddress" },
        { label: "Domain", key: "domain" },
        { label: "OrganizationName", key: "organizationName" },
        { label: "Title", key: "title" },
        { label: "PrivilegeActor", key: "privilegeActor" },
        { label: "StartDate", key: "startDate" },
        { label: "EndDate", key: "endDate" },
        { label: "Category", key: "category" },
        { label: "Classification", key: "classification" },
        { label: "Notation", key: "notation" },
        { label: "SearchTerms", key: "searchTerms" }
    ]
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );
    const CustomCommandCell = (props: any) => (<CommandCell {...props}
        edit={enterEdit}
        remove={remove}
        add={add}
        discard={discard}
        update={update}
        cancel={cancel}
        editField={editField}
        disableAdd={disablePlusButton(props.dataItem)}
        type={"ccl"}
    />);

    const minMaxValidation = (value: string, min: number, max: number) => {
        if (value.length < min || value.length > max) {
            return false;
        }
        return true;
    };

    const startdatepicker = React.useMemo(() => (props: GridCellProps) => {
        const { dataItem } = props;
        const field = props.field || "";
        let dataValue = (dataItem[field] === null || dataItem[field] == '') ? null : new Date(dataItem[field]);
        return (
            <td >
                {dataItem.inEdit ? (
                    <DatePicker value={dataValue} format="MM/dd/yyyy"
                        onChange={(e: DatePickerChangeEvent) => {
                            if (props.onChange) {
                                props.onChange({
                                    dataItem: props.dataItem,
                                    dataIndex: props.dataIndex,
                                    field: props.field,
                                    syntheticEvent: e.syntheticEvent,
                                    value: e.target.value,
                                });
                            }
                        }}
                    />
                ) : (
                    (dataItem.startDate !== '' && dataItem.startDate !== null) && (
                        <>
                            {formatDate(new Date(dataItem.startDate), "MM/dd/yyyy")}
                        </>)
                )}
            </td>
        );
    }, []);

    const enddatepicker = React.useMemo(() => (props: GridCellProps) => {
        const { dataItem } = props;
        const field = props.field || "";
        let dataValue = (dataItem[field] === null || dataItem[field] == '') ? null : new Date(dataItem[field]);

        return (
            <td >

                {dataItem.inEdit ? (
                    <DatePicker
                        value={dataValue} format="MM/dd/yyyy"
                        onChange={(e: DatePickerChangeEvent) => {
                            if (props.onChange) {
                                props.onChange({
                                    dataItem: props.dataItem,
                                    dataIndex: props.dataIndex,
                                    field: props.field,
                                    syntheticEvent: e.syntheticEvent,
                                    value: e.target.value,
                                });

                            }
                        }}
                    />
                ) : (
                    (dataItem.endDate !== '' && dataItem.endDate !== null) && (

                        <>  {formatDate(new Date(dataItem.endDate), "MM/dd/yyyy")}</>
                    )
                )}
            </td>
        );
    }, []);

    const validationDomainCell = React.useMemo(() => (props: GridCellProps) => {
        const { dataItem } = props;
        const field = props.field || "";
        const dataValue = dataItem[field] === null ? "" : dataItem[field];
        return (
            <td className="k-grid-edit-cell">
                {dataItem.inEdit ? (
                    <div className="textDomainCell">
                        <TextBox
                            className="k-textbox"
                            maxLength={255}
                            value={dataValue}
                            onChange={(e) => {
                                if (props.onChange) {
                                    props.onChange({
                                        dataItem: props.dataItem,
                                        dataIndex: props.dataIndex,
                                        field: props.field,
                                        syntheticEvent: e.syntheticEvent,
                                        value: e.target.value,
                                    });
                                    disablePlusButton({ ...props.dataItem, domain: e.target.value });
                                }
                            }}
                        />

                        {!minMaxValidation(dataValue, 0, 255) && (
                            <Error>Max characters allowed 255</Error>)}
                    </div>
                ) : (
                    dataValue && dataValue.toString()
                )}

            </td>
        );
    }, []);

    const disablePlusButton = (item: any) => {
        //if (item.emailAddress) {
        //    setDisableAdd(false);
        //    return false;
        //} else {
        //    setDisableAdd(true);
        //    return true;
        //}
    }


    const privilegeActorDropDownCell = (props: GridCellProps) => {
        const localizedData = AdminService.getPrivilegeActor();

        const handleChange = (e: DropDownListChangeEvent) => {
            if (props.onChange) {
                props.onChange({
                    dataIndex: props.dataIndex,
                    dataItem: props.dataItem,
                    field: props.field,
                    syntheticEvent: e.syntheticEvent,
                    value: e.target.value.value,
                });
                disablePlusButton({ ...props.dataItem, privilegeActor: e.target.value.value });
            }
        };
        const { dataItem } = props;
        const field = props.field || "";
        const dataValue = dataItem[field] === null ? "" : dataItem[field];
        return (
            <td>
                {dataItem.inEdit ? (
                    <DropDownList
                        style={{ width: "100px" }}
                        onChange={handleChange}
                        value={localizedData.find((c) => c.value === dataValue)}
                        data={localizedData}
                        textField="text"
                    />
                ) : (
                    dataValue ? "Yes":"No"
                )}
            </td>
        );

    };

    const exportData = async () => {
        const responseData = await AdminService.getData(AdminService.urls.readClientCommunicatorListApiUrl + "?clientId=" + props.clientId + "&skip=0" + "&take=" + count);
        if (responseData != null && responseData.totalCount > 0) {
            var response = responseData.data as CommunicatorList[];
            var newData = response.map((item, i) => {
                item.displayName = FrequentFunctions.handleSplChars(item.displayName),
                    item.domain = FrequentFunctions.handleSplChars(item.domain),
                    item.emailAddress = FrequentFunctions.handleSplChars(item.emailAddress),
                    item.firstName = FrequentFunctions.handleSplChars(item.firstName),
                    item.lastName = FrequentFunctions.handleSplChars(item.lastName),
                    item.notation = FrequentFunctions.handleSplChars(item.notation),
                    item.nickName = FrequentFunctions.handleSplChars(item.nickName),
                    item.organizationName = FrequentFunctions.handleSplChars(item.organizationName),
                    item.searchTerms = FrequentFunctions.handleSplChars(item.searchTerms),
                    item.title = FrequentFunctions.handleSplChars(item.title)
                return item;
            });
            setExportCLData(newData);
            csvRefLink && csvRefLink.current && csvRefLink.current.link.click();
        }
        else
            alert("No data is available to export");
    } 
    
    const changePagination = (skip: number, take: number) => {
        gridRef.current && gridRef.current.scrollIntoView({ rowIndex: 1 });
        setDataState({ ...dataState, skip: skip, take: take });
    }

    useEffect(() => {
        getClientCommunicatorListData(dataState);
    }, [dataState, setCommunicatorListData]);

    const closeDialog = () => {
        setVisible(false)
    }

    const privilegeOptions = [{ name: "Yes", id: true }, { name: "No", id: false }];
    const PrivilegeFilterCell: any = (props: GridFilterCellProps) => (
        <DropdownFilterCell
            {...props}
            data={privilegeOptions}
            textField="name"
            defaultSelectedOption={null}
        />
    );

    const sendStatus = (isImpSuccess: boolean) => {
        if (isImpSuccess)
            setTimeout(() => { setVisible(false); }, 1000);
        getClientCommunicatorListData(dataState);
    }

    const itemChange = (event: GridItemChangeEvent) => {
        const field = event.field || "";
        const newData = communicatorListData.map((item) =>
            item.id === event.dataItem.id
                ? { ...item, [field]: event.value }
                : item
        );

        setCommunicatorListData(newData);
    };

    const columns: Array<GridColumnProps> = [

        { field: "displayName", title: "Display Name", editable: false, sortable: true, filterable: true, width: 150, cell: validationDomainCell },
        { field: "lastName", title: "Last Name", editable: false, sortable: true, filterable: true, width: 150, cell: validationDomainCell },
        { field: "firstName", title: "First Name", editable: false, sortable: true, filterable: true, width: 150, cell: validationDomainCell },
        { field: "nickName", title: "Nick Name", editable: false, sortable: true, filterable: true, width: 150, cell: validationDomainCell },
        { field: "emailAddress", title: "Email Address", editable: false, sortable: true, filterable: true, width: 200, cell: validationDomainCell },
        { field: "domain", title: "Domain", editable: false, sortable: true, filterable: true, width: 150, cell: validationDomainCell },
        { field: "organizationName", title: "Organization Name", editable: false, sortable: true, filterable: true, width: 160, cell: validationDomainCell },
        { field: "title", title: "Title", editable: false, sortable: true, filterable: true, width: 200, cell: validationDomainCell },
        { field: "privilegeActor", title: "Privilege Actor", editable: false, sortable: true, filterable: true, filter: 'boolean', width: 150, filterCell: PrivilegeFilterCell, cell: privilegeActorDropDownCell },
        { field: "startDate", title: "Start Date", editable: true, sortable: true, filterable: true, filter: "date", format: "{1:dd/MM/yyyy}", width: 250, cell: startdatepicker },
        { field: "endDate", title: "End Date", editable: true, sortable: true, filterable: true, width: 250, filter: "date", format: "{1:dd/MM/yyyy}",  cell: enddatepicker },
        { field: "category", title: "Category", editable: false, sortable: true, filterable: true, width: 150 },
        { field: "classification", title: "Classification", editable: false, sortable: true, filterable: true, width: 150 },
        { field: "notation", title: "Notation", editable: false, sortable: true, filterable: true, width: 150, cell: validationDomainCell },
        { field: "searchTerms", title: "Search Terms", editable: false, sortable: true, filterable: true, width: 200, cell: validationDomainCell },
        { width: 150, cell: CustomCommandCell, filterable: false, sortable: false, headerClassName: "no-sort" }
    ];

    const remove = (dataItem: CommunicatorList) => {
        const newData = deleteItem(dataItem);
    };

    const deleteItem = (dataItem: CommunicatorList) => {
        setLoading(true);
        const result = AdminService.deleteClientCommunicatorList(dataItem.id)
            .then(function (response: any) {
                setLoading(false);
                const _items = [...backupItems];
                let index = _items.findIndex((record) => record.id === dataItem.id);
                _items.splice(index, 1);
                setBackupItems([..._items]);
                setCommunicatorListData([..._items]);
                getClientCommunicatorListData(dataState);
            })

    };

    const getSortBy = (sort: Array<SortDescriptor>) => {
        setDataState({ ...dataState, skip: 0, take: 100, sort: sort });
    }

    const gridFilterChange = (event: GridFilterChangeEvent) => {
       
        const newState = { ...dataState, skip: 0, take: 100, filter: event.filter };
        setDataState(newState);
    };

    const getModifiedCorrectDateFilter = (
        gridFilters: Array<CompositeFilterDescriptor>,
        dateColumnName: string
    ) => {
        let isCreatedDateFilterExists = false;
        let createdDateValue!: Date | null;
        const filters = gridFilters
            ? gridFilters.map(compositeFilter => {
                const createdDateFilter: FilterDescriptor = compositeFilter.filters.find(
                    filter => (filter as FilterDescriptor).field === dateColumnName
                ) as FilterDescriptor;

                if (createdDateFilter) {
                    const dateFilterValue = createdDateFilter.value;

                    if (!isNaN(new Date(dateFilterValue).getFullYear()) && new Date(dateFilterValue).getFullYear() > 1970) {
                        createdDateFilter.operator = 'eq';
                    }
                    createdDateValue = typeof dateFilterValue === "string" ? new Date("01/01/1901") : ((createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') ? null : new Date(dateFilterValue));

                    if (createdDateFilter.operator === 'isnull' || createdDateFilter.operator === 'isnotnull') {
                        return {
                            ...compositeFilter,
                            filters: [
                                {
                                    ...createdDateFilter,
                                    field: dateColumnName,
                                    operator: typeof dateFilterValue === "string" && createdDateFilter.operator === 'isnotnull' ? "eq" : createdDateFilter.operator,
                                    value: createdDateValue
                                }
                            ]
                        };
                    }

                    isCreatedDateFilterExists = true;

                    return {
                        ...compositeFilter,
                        filters: [
                            {
                                ...createdDateFilter,
                                field: dateColumnName,
                                operator: "gte",
                                value: new Date(createdDateValue!.toUTCString())
                            }
                        ]
                    };
                }
                return compositeFilter;
            })
            : [];

        if (isCreatedDateFilterExists) {
            filters.push({
                logic: "and",
                filters: [
                    {
                        field: dateColumnName,
                        operator: "lt",
                        value: new Date(new Date(createdDateValue!.setUTCDate(createdDateValue!.getUTCDate() + 1)).toUTCString())
                    }
                ]
            });
        }

        return filters;
    };

    const getClientCommunicatorListData = async (dataState: IGridParams) => {
        setLoading(true);
        const request = { ...dataState };
        request.filters = [];

        if (request.filter) {
            request.filters.push({ logic: "and", filters: request.filter.filters });
        }
        let filters = getModifiedCorrectDateFilter(request.filters as CompositeFilterDescriptor[], "startDate");
        filters = getModifiedCorrectDateFilter(filters, "endDate");

        const ccListFilters = filters
            ? filters.map(compositeFilter => {

                const userTypeFilter: FilterDescriptor = compositeFilter.filters.find(
                    filter => (filter as FilterDescriptor).field === "privilegeActor"
                ) as FilterDescriptor;

                if (userTypeFilter) {
                    const userTypeFilterValue = userTypeFilter.value == null ? null : (userTypeFilter.value.id);

                    return {
                        ...compositeFilter,
                        filters: [
                            {
                                ...userTypeFilter,
                                field: "privilegeActor",
                                operator: "eq",
                                value: userTypeFilterValue
                            }
                        ]
                    };
                }

                return compositeFilter;
            })
            : [];

        const sortJSON = JSON.stringify(dataState.sort) || "";
        const filterJSON =  JSON.stringify(ccListFilters) || "";
        const responseData = await AdminService.getData(AdminService.urls.readClientCommunicatorListApiUrl + "?clientId=" + props.clientId + "&skip=" + dataState.skip + "&take=" + dataState.take + "&sortBy=" + sortJSON + "&filter=" + filterJSON);
        setLoading(false);
        if (responseData != null && responseData.totalCount > 0) {
            const CommunicatorListData = responseData.data as unknown as CommunicatorList[];
            if (CommunicatorListData != null) {
                setCommunicatorListData(CommunicatorListData);
                setBackupItems(CommunicatorListData);
                setCount(responseData.totalCount);
            }
        }
    }

    const add = (dataItem: CommunicatorList) => {
        dataItem.inEdit = true;
        const newData = insertItem(dataItem);
    };

    const insertItem = (dataItem: CommunicatorList) => {
        setLoading(true);
        const addClientCommunicatorListResult = AdminService.createClientCommunicatorList(dataItem)
            .then(async function (response: any) {
                setLoading(false);
                console.log(response);
                const clientCommunicatorListData = response.data;
                if (!clientCommunicatorListData.inValid) {
                    await getClientCommunicatorListData(dataState);
                    dataItem.inEdit = false;
                    dataItem.id = clientCommunicatorListData.data.id;
                    const _items = [...communicatorListData];

                    let index = _items.findIndex((p) => p === dataItem);
                    if (index >= 0) {
                        _items[index] = { ...dataItem };
                    }
                    setBackupItems([..._items]);
                    setCommunicatorListData([..._items]);
                    getClientCommunicatorListData(dataState);
                }
                else {

                    alert(clientCommunicatorListData.message);
                }
            })
    };

    const discard = (dataItem: CommunicatorList) => {
        const newData = [...communicatorListData];
        newData.splice(0, 1);
        setCommunicatorListData(newData);
    };

    const update = (dataItem: CommunicatorList) => {
        setLoading(true);
        const result = AdminService.createClientCommunicatorList(dataItem)
            .then(function (response: any) {
                setLoading(false);
                const clientDomainData = response.data;
                if (!clientDomainData.inValid) {
                    dataItem.inEdit = false;
                    const newData = [...communicatorListData]
                    let index = newData.findIndex((record) => record.id === dataItem.id);
                    newData[index] = dataItem;
                    setCommunicatorListData(newData);
                    setBackupItems(newData);
                }
                else {
                    alert(clientDomainData.message);
                }
            })
    };

    const updateItem = (dataItem: CommunicatorList) => {
        let index = communicatorListData.findIndex((record) => record.id === dataItem.id);
        communicatorListData[index] = dataItem;
        return communicatorListData;
    };

    const cancel = (dataItem: CommunicatorList) => {
        const originalItem = backupItems.find(
            (p: any) => p.id === dataItem.id
        );
        const newData = communicatorListData.map((item) =>
            originalItem ? (item.id === originalItem.id ? originalItem : item) : item
        );

        setCommunicatorListData(newData);
    };

    const enterEdit = (dataItem: CommunicatorList) => {
        let newData = communicatorListData.map((item) =>
            item.id === dataItem.id ? { ...item, inEdit: true } : item
        );
        setCommunicatorListData(newData);
    };

    const addNew = () => {
        gridRef.current && gridRef.current.scrollIntoView({ rowIndex: 1 }); 
        const newDataItem: CommunicatorList = {
            inEdit: true,
            displayName: "",
            lastName: "",
            firstName: "",
            nickName: "",
            emailAddress: "",
            domain: "",
            organizationName: "",
            title: "",
            privilegeActor: null,
            startDate: null,
            endDate: null,
            notation: "",
            searchTerms: "",
            clientId: props.clientId
        };
        setCommunicatorListData([newDataItem, ...backupItems]);
    };

    return (
        <>
            <div className="k-widget k-grid " role="grid">
                <div className="col-md-12 text-right client-list-btn-wrapper">
                    <Button
                        onClick={addNew}
                        themeColor={"primary"}
                    >
                        Add Communicator
                    </Button>
                    <CSVLink headers={clientCommunicatorDownload} filename={"Client Communicators List.csv"} data="">
                        <Button
                            themeColor={"primary"}>
                            Download Template
                        </Button>
                    </CSVLink>
                    <Button onClick={confirmCCImport}
                        themeColor={"primary"}>
                        Import
                    </Button>
                    {visible && <Dialog title={<CustomTitleBar />} onClose={closeDialog}>
                        <div>
                            <FileUpload pageType="ClientCommunicator" clientId={props.clientId} sendStatus={sendStatus} />
                        </div>
                    </Dialog>}
                    <CSVLink ref={csvRefLink} filename={"Client Communicators List.csv"} headers={communicatorHeaders} data={exportCLData}>
                    </CSVLink>
                    <Button onClick={exportData}
                        themeColor={"primary"}
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">
                        Export
                    </Button>
                    {isConfirm && <Dialog onClose={closeDialog}>
                        <ConfirmImport pageType="CCL" setVisible={setVisible} confirmImportClose={confirmImportClose} />
                    </Dialog>}
                </div>
            </div>
            <div className="client-communicator">
                {loading && loadingPanel}
                <AdvancedGrid data={communicatorListData} columns={columns}
                    changePagination={changePagination} totalCount={count}
                    gridRef={gridRef}
                    dataState={dataState}
                    itemChange={itemChange}
                    editField={editField}
                    columnVirtualization={false}
                    sortBy={getSortBy}
                    filterOperators={{
                        text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
                        date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }],
                        numeric: [{ text: "grid.filterEqOperator", operator: "eq" }],
                        boolean: [{ text: 'grid.filterEqOperator', operator: 'eq' }]
                    }}
                    onFilterChange={gridFilterChange}
                />

            </div>
        </>
    );
}
export default ClientCommuniatorList;