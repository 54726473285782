import { DialogContent } from "@material-ui/core";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { RadioButton, RadioButtonChangeEvent } from "@progress/kendo-react-inputs";
import React, { useEffect } from "react";
import { Label } from "reactstrap";
import ConfirmImport from "./ConfirmImport";
import "./ReviewImport.scss";

interface IProps {
    pageType?: string;
    setVisible: (value: boolean) => void;
    setIsConfirm: (value: boolean) => void;
    newUpload: (value: string) => void;
    gdOption: string;
    closeConfirmDialog: () => void;
}
type Props = IProps;
const CustomTitleBar = () => {
    return (
        <div className="custom-title">
            <span><strong>select an option to upload</strong></span>
        </div>
    );
};
const OptionImport = (props: any) => {
    const [confirmMsg, setConfirmMsg] = React.useState<string>();
    const [isConfirm, setIsConfirm] = React.useState<boolean>(false);
    const [showWarningMsg, setShowWarningMsg] = React.useState<boolean>(false);
    const [visible, setVisible] = React.useState<boolean>(false);
    const [selectedValue, setSelectedValue] = React.useState("add");

    const confirmImportClose = (value: boolean) => {
        setIsConfirm(false);
        props.setIsConfirm(false);
        if (value) {
            props.closeConfirmDialog();
        }
    }

    const clickOk = () => {
        setIsConfirm(false);
        props.setIsConfirm(false);
        props.setVisible(true);
        props.newUpload(selectedValue);
        
    }
    const clickCancel = () => {
        props.setIsConfirm(false);
        props.setVisible(false);
    }


    const setStaticConfirmMessage = () => {
        if (props.pageType == "GDL")
            setConfirmMsg("Select ADD, if you want to add new domains to the existing list. Select NEW, if you want to upload a new Global Domains list by overwriting the existing list.")
    }
    const closeReviewDialog = () => {
        props.setIsConfirm(false);
        props.setVisible(false);
        
    }
    const closeDialog = () => {
        setVisible(false);
    }
    useEffect(() => { setStaticConfirmMessage(); });
    

    const handleChange = React.useCallback(
        (e: RadioButtonChangeEvent) => {
            setSelectedValue(e.value);
            if (e.value === "new")
                setShowWarningMsg(true);
            else
                setShowWarningMsg(false);
        },
        [setSelectedValue]
    );
    return (
        <div>
            <Dialog title={<CustomTitleBar />} onClose={closeReviewDialog} width={600} height={315}>
            <DialogContent><p>{confirmMsg}</p>
            <RadioButton
                name="option1"
                value="add"
                checked={selectedValue === "add"}
                label="ADD"
                onChange={handleChange}
            />
                <br />
                <br/>
            <RadioButton
                name="option1"
                value="new"
                checked={selectedValue === "new"}
                label="NEW"
                onChange={handleChange}
                    />
                    {showWarningMsg && <p className="p-pop-message">Uploading a new file will overwrite the existing list. All the matched Category records in the Communicator Index will be lost.</p>}
            </DialogContent>
            <DialogActionsBar layout={"end"}>
                <Button themeColor={"primary"} onClick={clickOk}>
                    Ok
                </Button>
                <Button themeColor={"primary"} onClick={clickCancel}>
                    Cancel
                </Button>
                {isConfirm && <Dialog onClose={closeDialog}>
                    <ConfirmImport pageType="GDL" setVisible={setVisible} confirmImportClose={confirmImportClose} />
                </Dialog>}
                </DialogActionsBar>
            </Dialog>
        </div>
    );
}
export default OptionImport;