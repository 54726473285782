import React from "react";
import ReactDOM from "react-dom";
import { Form, Field } from "@progress/kendo-react-form";
import {
    Upload,
    UploadOnBeforeUploadEvent, UploadFileInfo, UploadOnAddEvent, UploadOnProgressEvent, UploadOnRemoveEvent
} from "@progress/kendo-react-upload";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import axios from "axios";
import { DialogContent } from "@material-ui/core";
import FileUploadResponse from "../../models/FileUploadResponse";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import bgMessages from '../../Localization/en.json';
import { Popup } from "@progress/kendo-react-popup";
import FieldMapping from "../fieldmapping/FieldMapping";
import Papa, { parse } from "papaparse";
import FieldMappingFileInfo from "../../models/FieldMapping";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import "./FileUploadWithFieldMapping.scss";
import AdminService from "../../services/AdminService";
import ResponseInfo from "../../models/ResponseInfo";
import authHeader from "../../services/authHeader";

loadMessages(bgMessages, 'en-US');

interface IProps {
    pageType?: string;
    sendStatus?: (isImpSuccess: boolean) => void;
    data?: string[];
    dataItem?: any;
    closeDialog: () => void;
    updateLoading: (value: boolean) => void;
    projectId: number;
    clientId: number;
    jobProgress: (value: number) => void;
    selectedValue: string;
}
interface LocaleInterface {
    language: string;
    locale: string;
}
type Props = IProps;

const FileUpload = (props: Props) => {
    const [fileuploadresult, setfileuploadresult] = React.useState<FileUploadResponse>();
    const anchor = React.useRef<HTMLButtonElement | null>(null);
    const [show, setShow] = React.useState(false);
    const [data, setData] = React.useState([] as Array<string>);
    const [disableSubmit, setDisableSubmit] = React.useState<boolean>(true);
    const [files, setFiles] = React.useState<Array<UploadFileInfo>>([]);
    const [filesHeaders, setFilesHeaders] = React.useState([] as Array<string>);
    const [fileMapping, setFileMapping] = React.useState<FieldMappingFileInfo[]>();
    const [value, setValue] = React.useState(0);
    const [progressFile, setProgressFile] = React.useState<string>();

    const closefieldMapping = () => {
        props.closeDialog();
        setShow(false);
    }
    const closeReviewDialog = () => {
        //setVisible(false);
        setShow(false);
    }
    const handleSubmit = async (e: any) => {
        if (props.selectedValue === "metadataDocuments")
        {
            const fieldMappingFileInfo: FieldMappingFileInfo[] = [];
            if (files && files.length > 0 && files.length < 4) {
                let headersData: string[] = [];
                Promise.all([...files].map((file) =>
                    new Promise((resolve, reject) => {
                        if (file.getRawFile != undefined) {
                            parse(file.getRawFile(), {
                                header: true,
                                complete: resolve, // Resolve each promise
                                error: reject,
                                step: function (results: any, parser: any) {
                                    parser.abort()
                                }
                            })
                            const fileInfo = new FieldMappingFileInfo();
                            fileInfo.uid = file.uid;
                            fileInfo.file = file.name;

                            fieldMappingFileInfo.push(fileInfo);

                        }
                    }
                    )),
                ).then((results) => {
                    let initialHeaderData: string[] = [];
                    results.forEach((result: any, index: number) => {
                        if (result.data) {
                            if (index === 0) {
                                initialHeaderData = Object.keys(result.data);
                            }
                            headersData.push(JSON.stringify(Object.keys(result.data).sort()));
                            fieldMappingFileInfo[index].headers = Object.keys(result.data).sort();
                        }
                    });
                    setFileMapping(fieldMappingFileInfo);
                    setFilesHeaders(headersData);
                    setData(initialHeaderData);
                    setShow(!show);
                    setDisableSubmit(true);
                })
                    .catch((err) =>
                        console.log('Something went wrong:', err)
                    );
            }
            else {
                alert("Please select max 3 files.");
            }
        }
        else {
            const jsonObj: any = {
                "type": 2,
                "name": "upload",
                "context": {
                    "clientid": (props.clientId).toString(),
                    "projectid": (props.projectId).toString()
                },
                "jobType": "communicatorIndexDocument"
            };
            closeReviewDialog();
            const body: any = { 'importContext': JSON.stringify(jsonObj) }
            const responseData =
                await AdminService.postWithParameter(AdminService.urls.beginChunkImport + "/Document", body);
            let importId = 0;
            for (let i = 0; i < files.length; i++) {
                if (responseData != null) {
                    var responseInfo = responseData.data as unknown as ResponseInfo;
                    if (responseInfo.isSuccess) {
                        importId = responseInfo.data1.id;
                        //props.updateLoading(true);
                        await uploadFile(files[i], importId);
                    }
                    else {
                        return console.log(responseInfo.errorMessage);
                    }
                }
            }
            const responseData1 =
                await AdminService.getData(AdminService.urls.endChunkImport + "/" + importId);
            if (responseData1 != null) {
                var responseInfo = responseData1.data1 as unknown as ResponseInfo;
                props.jobProgress(responseData1.data1.id);
            }
            props.sendStatus && props.sendStatus(true);

        }
    };
    const uploadFile = async (file: any, importId: number) => {
        const chunkSize = 1024 * 1024;
        for (let start = 0; start < file.size; start += chunkSize) {
            const chunk = file.getRawFile().slice(start, start + chunkSize);
            const formData = new FormData();
            formData.append('file', chunk, file.name);
            await fetch(
                AdminService.getBaseGlobalDomainUri() + AdminService.urls.uploadChunk + "/" + importId + "/" + start,
                { method: 'post', body: formData, headers: { 'Authorization': authHeader() } }).
                then(res => {
                    res.text();
                    const chunkProgress = Math.ceil((start + chunkSize) * 100 / file.size);
                    updateFileProgress(file.name, chunkProgress > 100 ? 100 : chunkProgress);
                });
        }
    }
    const fieldMappingHide = () => {
        setShow(!show);
        props.closeDialog();
    }
    const locales: LocaleInterface[] = [
        {
            language: "en-US",
            locale: "en",
        },
    ];
    const onBeforeUpload = (event: UploadOnBeforeUploadEvent) => {
        event.additionalData.description = "File upload";
    };
    const [currentLocale, setCurrentLocale] = React.useState<LocaleInterface>(
        locales[0]
    );
    const onAdd = (event: UploadOnAddEvent) => {
        console.log("onAdd: ", event.affectedFiles);
        setFiles(event.newState);
        setDisableSubmit(false);
    };
    const updateFileProgress = (fileName: string, progressValue: number) => {
        setProgressFile(fileName);
        setValue(progressValue);
    }
    const onRemove = (event: UploadOnRemoveEvent) => {
        console.log("onRemove: ", event.affectedFiles);
        if (files && files.length === 1) {
            setDisableSubmit(true);
        }
        else {
            setDisableSubmit(false);
        }
        setFiles(event.newState);
    };

    const onProgress = (event: UploadOnProgressEvent) => {
        console.log("onProgress: ", event.affectedFiles);
        setFiles(event.newState);
    };
    return (
        <>
            <div>
                <DialogContent>
                    <fieldset>
                        <div className="mb-3">
                            Select a file to upload
                            <LocalizationProvider language={currentLocale.language}>
                                <IntlProvider locale={currentLocale.locale} >
                                    <div className="upload-progress">
                                    <Upload
                                        batch={false}
                                        multiple={true}
                                        restrictions={{
                                            allowedExtensions: [".csv"]
                                        }}
                                        autoUpload={false}
                                        onBeforeUpload={onBeforeUpload}
                                        withCredentials={false}
                                        files={files}
                                        onAdd={onAdd}
                                        onRemove={onRemove}
                                        showActionButtons={false}
                                        onProgress={onProgress}
                                        />
                                    </div>
                                </IntlProvider>
                            </LocalizationProvider>
                        </div>
                    </fieldset>
                    <div>
                        {fileuploadresult && (fileuploadresult.isSuccess ?
                            <span id="uploadSuccessLbl" style={{ color: "green", display: "none" }}> <label>File Uploaded Succesfully</label></span> :
                            <span id="uploadSuccessLbl" style={{ color: "red", display: "none" }}> <label>{fileuploadresult.message}</label></span>)}
                    </div>
                     {progressFile && <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <ProgressBar value={value} min={0} max={100} />
                        <label>File Name: {progressFile}</label>
                    </div>}
                </DialogContent>
                <DialogActionsBar layout={"end"}>
                    <Button
                        type={"button"}
                        onClick={handleSubmit}
                        themeColor={"primary"}
                        disabled={disableSubmit}>
                        <span className="k-icon k-i-export k-icon-16" style={{ marginBottom: 5 }} /> 
                        Upload
                    </Button>
                </DialogActionsBar>
            </div>
            <Popup anchor={anchor.current} show={show} popupClass={"popup-content"}>
                <FieldMapping 
                    updateFileProgress={updateFileProgress }
                    filesHeaders={filesHeaders} 
                    fileMapping={fileMapping} 
                    closefieldMapping={closefieldMapping}
                    data={data} 
                    updateLoading={props.updateLoading} 
                    clientId={props.clientId} 
                    projectId={props.projectId}
                    fieldMappingHide={fieldMappingHide} 
                    pageType="FieldMapping" 
                    sendStatus={props.sendStatus}
                    jobProgress={props.jobProgress }
                    files={files} />
            </Popup>
        </>
    );
};
export default FileUpload;