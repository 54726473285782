import DialogContent from "@material-ui/core/DialogContent";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React from "react";
import { strict } from "assert";
import AdminService from "../../services/AdminService";
import authHeader from "../../services/authHeader";
import axios from "axios";
import { adminRoutes } from "../../models/Routing";
import Jobs from "../../models/Jobs";

interface IProps {
    status: string | undefined,
    jobId: number,
    jobStatus:number
}
const CustomTitleBar = () => {
    return (
        <h2 className="custom-title">
            Job Processing Info
        </h2>
    );
};

type Props = IProps;
const JobStatusInfo = (props: Props) => {
    const [visible, setVisible] = React.useState(true);
    const [showIcon, setShowIcon] = React.useState(false);
    const [errorFileName, setErrorFileName] = React.useState<string>("");

    const closeDialog = () => {
        setVisible(false);
    } 
    const GetErrFileName = async () => {
        const getErrorFNUrl = AdminService.urls.getFileName + "/" + props.jobId;
        const data = await AdminService.getData(getErrorFNUrl);
        if (data != null) {
            setErrorFileName(data.data1);
        }
    }
    const DownloadFile = async () => {        
        const url = AdminService.getBaseGlobalDomainUri() + AdminService.urls.downloadCIErrorFile + "/" + props.jobId;
        await fetch(url, { method: 'get', headers: { 'Authorization': authHeader() } }).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = errorFileName != '' ? errorFileName +"_ErrorLog.zip":"ErrorsLog.zip";
                alink.click();
            })
        })
    }
    React.useEffect(() => {
        getIconVisibility();
    }, [setShowIcon]);
    React.useEffect(() => {
        GetErrFileName();
    },[setErrorFileName]);
    const getIconVisibility = async () => {
        try {
            const url = AdminService.urls.showDownloadIcon + "/" + props.jobId;
            const data = await AdminService.getData(url);
            if (data != null && data.data1 != null) {
                if (data.data1 > 0 && props.jobStatus===4)
                    setShowIcon(true);
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    return (
        <div>
            {visible && (<Dialog title={<CustomTitleBar />} onClose={closeDialog} width={600} >
            <div className="k-form">
                    <DialogContent>
                        <ul className="job-status-info">
                            {props.status && props.status.split("||").map(line => <li key={line } >{line}</li>)} 
                            </ul>
                    </DialogContent>
                    <DialogActionsBar layout={"end"}>
                        {showIcon &&
                            <Button
                                title="DownLoad"
                                className="btn-download btn-secondary"
                                icon={"download"}
                                onClick={DownloadFile}>Download ErrorLog
                            </Button>
                        }
                        <Button themeColor={"primary"} type="button" onClick={closeDialog}> Close</Button>
                        
                    </DialogActionsBar>
                </div>
            </Dialog>)}
        </div>

    )
}
export default JobStatusInfo