import React, { useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Checkbox, CheckboxChangeEvent, TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import AdminService from "../../services/AdminService";
import "./CIExport.scss";
import DocumentCI from "../../models/DocumentCI";
import { CSVLink } from "react-csv";
import CommunicatorIndexList from "../../models/CommunicatorIndexList";
import FrequentFunctions from "../common/FrequentFunctions";
import {State } from '@progress/kendo-data-query';
import { Label } from "reactstrap";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";

interface IProps {
    projectName: string;
    projectId: number;
    dataState: State;
    count: number;
    cIExportDialogVisible?: (isImpSuccess: boolean) => void;
}
type Props = IProps;
const CIExport = (props: Props) => {
    const [exportType, setExportType] = React.useState<string>();
    const [txtVisible, setTxtVisible] = React.useState<boolean>(false);
    const [ddlVisible, setDdlVisible] = React.useState<boolean>(false); 
    const [formatSlected, setFormatSelected] = React.useState<number>(1); 
    const [customExpoVisibility, setCustomExpoVisibility] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [documentCIData, setDocumentCIData] = React.useState<DocumentCI[]>([]);
    const csvCIRefLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const existingCsvCIRefLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const [attorneyMarkerText, setAttorneyMarkerText] = React.useState<string>("");
    const [ciData, setCIData] = React.useState<CommunicatorIndexList[]>([]);
    const [isSyncClicked, setIsSyncClicked] = React.useState<boolean>(false);
    const [isEpidClicked, setIsEpidClicked] = React.useState<boolean>(false);
    const [exportLoading, setExportLoading] = React.useState(false);
    const [showSpan, setShowSpan] = React.useState(false);
    const [btnExport, setBtnExport] = React.useState(false);
    const ciHeaders = [
        { key: "originalValue", label: "Original Value" },
        { key: "emailAddress", label: "Email Address" },
        { key: "docCount", label: "Doc Count" },
        { key: "exchangeAddress", label: "Exchange Address" },
        { key: "domain", label: "Domain" },
        { key: "lastName", label: "Last Name" },
        { key: "firstName", label: "First Name" },
        { key: "title", label: "Title" },
        { key: "privilegeActor", label: "Privilege Actor" },
        { key: "category", label: "Category" },
        { key: "classification", label: "Classification" },
        { key: "clientNotation", label: "Notation" }
    ];
    const headers = [
        { label: "ControlNumber", key: "controlNumber" },
        { label: "GroupIdentifier", key: "groupIdentifier" },
        { label: "EmailFrom", key: "emailFrom" },
        { label: "EmailTo", key: "emailTo" },
        { label: "EmailCc", key: "emailCc" },
        { label: "EmailBcc", key: "emailBcc" }
    ];
    const [dynamicHeaders, setDynamicHeaders] = React.useState<any>(headers);

    const onExportTypeSelect = async (event: DropDownListChangeEvent )=> {
        setExportType(event.target.value);
        if (event.target.value == "CustomExport") {
            const responseData = await AdminService.getData(AdminService.urls.getByProjectIdStatus + "/" + props.projectId)
            if (responseData && responseData.data1) {
                if (responseData.data1 == "both") {
                    setIsSyncClicked(true);
                    setIsEpidClicked(true);
                } else if (responseData.data1 == "sync") {
                    setIsSyncClicked(true);
                } else {
                    setIsEpidClicked(true);
                }
            }
            setCustomExpoVisibility(true);
        }
        else if (event.target.value == "Export") {
            setCustomExpoVisibility(false);
        }
    }
    const ExportData = async () => {
        if (exportType == "Export") {
            ExportCIData();
        }
        else if (exportType == "CustomExport"){
            CustomExportData()
        }
    }

    const ExportCIData = async () => {
        setExportLoading(true);
        setShowSpan(true);
        setBtnExport(true);
        const sortJSON = JSON.stringify(props.dataState.sort) || "";
        const responseData = await AdminService.getData(AdminService.urls.communicatorIndicesApiUrl +
            "/" + props.projectId + "?skip=0" + "&take=" + props.count + "&sortBy=" + sortJSON);
        try {
            if (responseData != null && responseData.data != null) {
                setLoading(false);
                setExportLoading(false);
                setShowSpan(false);
                setBtnExport(false);
                var resCIData = responseData.data as CommunicatorIndexList[];
                if (resCIData.length > 0) {
                    var newData = resCIData.map((item, i) => {
                        item.originalValue = FrequentFunctions.handleSplChars(item.originalValue);
                        return item;
                    })
                    setCIData(newData);
                    existingCsvCIRefLink && existingCsvCIRefLink.current && existingCsvCIRefLink.current.link.click();
                    Cancel();
                } else alert("No data is available to export");
            }
        }
        catch (err) {
            console.log(err);
            setExportLoading(false);
            setShowSpan(false);
            alert("No data is available to export");
        }
    }

    const Cancel = async () => {
        props.cIExportDialogVisible && props.cIExportDialogVisible(false);
    }
    const CustomExportData = async () => {
        setExportLoading(true);
        setShowSpan(true);
        setBtnExport(true);
        const responseData = await AdminService.getData(AdminService.urls.documentCIsApiUrl +
            "/" + props.projectId + "?attorneyMarker=" + encodeURIComponent(attorneyMarkerText) + "&formatType=" + formatSlected);
        try {
            if (responseData != null && responseData.data != null) {
                setLoading(false);
                setExportLoading(false);
                setShowSpan(false);
                setBtnExport(false);
                var resDocumentCIData = responseData.data as DocumentCI[];
                if (resDocumentCIData.length > 0) {
                    var newData = resDocumentCIData.map((item, i) => {
                        item.emailFrom = FrequentFunctions.handleSplChars(item.emailFrom);
                        item.emailTo = FrequentFunctions.handleSplChars(item.emailTo);
                        item.emailCc = FrequentFunctions.handleSplChars(item.emailCc);
                        item.emailBcc = FrequentFunctions.handleSplChars(item.emailBcc);
                        item.normalizedFrom = FrequentFunctions.handleSplChars(item.normalizedFrom);
                        item.normalizedTo = FrequentFunctions.handleSplChars(item.normalizedTo);
                        item.normalizedCC = FrequentFunctions.handleSplChars(item.normalizedCC);
                        item.normalizedBcc = FrequentFunctions.handleSplChars(item.normalizedBcc);
                        return item;
                    })
                }
                setDocumentCIData(resDocumentCIData);
                csvCIRefLink && csvCIRefLink.current && csvCIRefLink.current.link.click();
                Cancel();
                } else alert("No data is available to export");
        }
        catch (err) {
            console.log(err);
            setExportLoading(false);
            setShowSpan(false);
            alert("No data is available to export");
        }
    }
    const handleDomainChange = (e: CheckboxChangeEvent) => {
        if (e.value == true) {
            setDynamicHeaders((prevHeaders: any) => [...prevHeaders, { label: "Domain", key: "domain" }]);
        }
    };
    const handleCategoryChange = (e: CheckboxChangeEvent) => {
        if (e.value) {
            setDynamicHeaders((prevHeaders: any) => [...prevHeaders, { label: "Category", key: "category" }]);
        }
    };
    const handleEpidChange = (e: CheckboxChangeEvent) => {
        if (e.value) {
            setDynamicHeaders((prevHeaders: any) => [...prevHeaders, { label: "EPIDCategory", key: "epidCategory" }]);
        }
    };
    const handleClassificationChange = (e: CheckboxChangeEvent) => {
        if (e.value) {
            setDynamicHeaders((prevHeaders: any) => [...prevHeaders, { label: "Classification", key: "classification" }]);
        }
    };
    const handleNormNamesChange = (e: CheckboxChangeEvent) => {
        setDdlVisible(e.value);
        if (e.value) {
            setDynamicHeaders((prevHeaders: any) => [...prevHeaders, { label: "NormalizedFrom", key: "normalizedFrom" }, { label: "NormalizedTo", key: "normalizedTo" }, { label: "NormalizedCC", key: "normalizedCC" }, { label: "NormalizedBcc", key: "normalizedBcc" }]);
        }
    };  
    const handleAttorneyChange = (e: CheckboxChangeEvent) => {
        setTxtVisible(e.value);
    };
    const handleAttorneyTextChange = (e:any) => {
        setAttorneyMarkerText(e.target.value);
    };
    const onFormatSelect = async (event: DropDownListChangeEvent) => {
        if (event.target.value == "First Name Last Name")
            setFormatSelected(1);
        else if (event.target.value == "Last Name, First Name")
            setFormatSelected(2);
    }
    const categories = ["Export", "CustomExport"];
    const displayFormats = ["First Name Last Name", "Last Name, First Name"];
    return (
        <div className="export-check">
            <div className="row">
                <div className="exp-lbl">
                        <Label>Export Type:</Label>
                </div>
                <div className="exp-drp">
                        <DropDownList
                            style={{ width: "200px" }}
                            defaultValue="Select" onChange={onExportTypeSelect} data={categories} />
                    </div>
            </div>
            <div hidden={!customExpoVisibility}>
                 <div className="row">
                <div className="column-half">
                    <Checkbox
                        name="import"
                        label="Domain"
                            onChange={handleDomainChange} />
                    <br />
                    <Checkbox
                        name="import"
                        label="Category"
                        onChange={handleCategoryChange}
                        disabled={!isSyncClicked} />
                        <br />
                        <Checkbox
                            name="import"
                            label="Normalized Names"
                            onChange={handleNormNamesChange}
                            disabled={!isSyncClicked} />
                        <br />
                        <div hidden={!ddlVisible}>
                            <DropDownList
                                style={{ width: "200px" }}
                                defaultValue="First Name Last Name" data={displayFormats} onChange={onFormatSelect} />
                        </div>                   
                </div>
                <div className="column-half">
                        <Checkbox
                            name="import"
                            label="Epid Category"
                            onChange={handleEpidChange}
                            disabled={!isEpidClicked} />
                    <br />
                    <Checkbox
                        name="import"
                        label="Classification"
                        onChange={handleClassificationChange}
                        disabled={!isSyncClicked} />
                    <br />
                    <Checkbox
                        name="import"
                        label="Attorney Marker"
                        onChange={handleAttorneyChange}
                        disabled={!isSyncClicked} />
                    <br />
                    <div hidden={!txtVisible}>
                            <TextBox onChange={handleAttorneyTextChange} />
                    </div>
                </div>
                </div>
            </div>
            <DialogActionsBar layout={"stretched"}>
            <div className="bottom-row">
                <div className="row" >
                    <CSVLink
                        ref={csvCIRefLink}
                        filename={props.projectName + "_Custom Communicator Index" + ".csv"}
                        headers={dynamicHeaders}
                        data={documentCIData}>
                    </CSVLink>
                    <CSVLink
                        ref={existingCsvCIRefLink}
                        filename={props.projectName + "_Communicator Index" + ".csv"}
                        headers={ciHeaders}
                        data={ciData}>
                    </CSVLink>
                    
                        <div className="column-half">
                        <Button  onClick={Cancel} style={{ width: '100px', fontSize: '12px' }}>
                            Cancel
                        </Button>
                    </div>
                        <div className="column-half">
                            <Button themeColor={"primary"} onClick={ExportData} disabled={btnExport} style={{ width: '100px', fontSize: '12px', margin: '0px' }}>
                            {showSpan &&
                                <span className={`k-icon ${exportLoading ? "k-i-loading" : ""} k-icon-16`} style={{ marginBottom: 5 }} />}

                            Export
                        </Button>
                        </div>
                    
                </div>
                
                </div>
            </DialogActionsBar>
        </div>
    )
}
export default CIExport