import { Grid } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import "./Project.scss";
import Project from "../../models/Project";
import { formatDate } from "@telerik/kendo-intl";
import { useHistory, useParams } from 'react-router-dom';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Notification } from '@progress/kendo-react-notification';
import { ProgressBar } from "@progress/kendo-react-progressbars";
import AdminService from "../../services/AdminService";
import Jobs from "../../models/Jobs";
import { isNullOrUndefined } from "util";
import { Popover } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import JobStatusInfo from "./JobStatusInfo";
import { Popup } from "@progress/kendo-react-popup";
import AuthVerify from "../../services/AuthVerify";

interface IProps {
    project: Project;
    jobId:number;
    progressVisible: boolean;
    sendStatus?: (isImpSuccess: boolean) => void;
    onJobComplete: () => void;
    projectid: number;
    makeProgressVisible: (visible: boolean) => void;
    getSavedEpidCategoryDownloaded?: () => void;
    totalCount?: number;
}

type Props = IProps;

export const BackButton = () => {
    let history = useHistory();
    const verify = () => {
        if (AuthVerify()) {
            history.goBack()
        }
        else {
            localStorage.clear();
            window.location.href = "/login";
        }
    }
    return (
        <>
            <div>
                <button onClick={() => verify() }><span className="k-icon k-icon-22 icon-color-c k-i-arrow-chevron-left" /></button>
            </div>
        </>
    );
};
const CustomTitle = () => {
    return <em>Notification</em>;
};
const ProjectInfo = (props: Props) => {
    const [value, setValue] = React.useState(0);
    const [showNotification, setShowNotification] = React.useState(false);
    const intervalRef = useRef<any>(null);
    const anchor = React.useRef<HTMLSpanElement>(null);
    const [show, setShow] = React.useState(false);
    const [showTitle, setShowTitle] = React.useState(true);
    const [statusText, setStatus] = React.useState<any>();
    const [statusInfoClass, setStatusInfoClass] = React.useState<string>();
    const [jobId, setJobId] = React.useState<number>(props.jobId);
    const [doRefresh, setRefresh] = React.useState(true);
    const [progressVisible, setProgressVisible] = React.useState(props.progressVisible);
    const [jobStatus, setJobStatus] = React.useState(0);

    const onTitleShowChange = React.useCallback((e) => {
        setShow(false);
        setShowTitle(e.value);
    }, []);
    
    const fetchJobStatus = async (fetchJobid: number) => {
        try {
            if (fetchJobid != 0) {
                setStatusInfoClass("icon-color");
                const url = AdminService.urls.getProgressStatus + "/" + fetchJobid;
                const data = await AdminService.getData(url);
                if (data) {
                    var job = data.data1 as Jobs;
                    const msg = (job.statusText != null && job.statusText != '') ? job.statusText : 'Processing the request.';
                    setStatus(msg);
                    setJobStatus(job.status);
                    const progress = job.totalCount === 0 ? 2 : Math.ceil((job.processedCount + job.failedCount) * 100 / job.totalCount);
                    setValue(progress);
                    
                    if (job.status === 4) {
                        setRefresh(false);
                        setStatusInfoClass("status-info-success");
                        try {
                            if (job.jobType == "7") {
                                props.getSavedEpidCategoryDownloaded && props.getSavedEpidCategoryDownloaded();
                            } else if (job.jobType == "6") {
                                const responseData = await AdminService.getData(AdminService.urls.MatchedReferencesList + "/" + props.projectid);
                                if (responseData != null && responseData.data1 != null) {
                                    const message =
                                        `${msg}. ||` +
                                        `Number of domains matched from Global Domains List: ${(responseData.data1.matchedGlobalDomainCount > 0 ? `${responseData.data1.matchedGlobalDomainCount} of ${responseData.data1.totalCount}` : `List not available`)} ||` +
                                        `Number of domains matched from Clients Domains List: ${(responseData.data1.matchedClientDomainCount > 0 ? `${responseData.data1.matchedClientDomainCount} of ${responseData.data1.totalCount}` : `List not available`)} ||` +
                                        `Number of Communicators matched from Client Communicators List: ${(responseData.data1.matchedClientCommunicatorCount > 0 ? `${responseData.data1.matchedClientCommunicatorCount} of ${responseData.data1.totalCount}` : `List not available`)}`

                                    setStatus(message);
                                }
                            }
                        }
                        catch (e) {

                        }
                        props.onJobComplete();
                        clearInterval(intervalRef.current);
                    } else
                        if (job.failedCount > 0) {
                            setStatusInfoClass("status-info-failed");
                        }
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
       
        intervalRef.current && clearInterval(intervalRef.current);
        if (props.jobId != 0) {
            setJobId(props.jobId);
            setProgressVisible(true);
            intervalRef.current = setInterval(() => {
                fetchJobStatus(props.jobId);
            }, 5000);
        }

        return () => { clearInterval(intervalRef.current); };
    }, [props.jobId]);

    const handleNotificationClose = () => {
        setShowNotification(false);
    };
    return (
        <div className="grid-wrapper prj-grid-wrapper papergrid-space">

            <Grid container alignItems="center">
                
                <Grid item xs={12} sm={6}>
                    <Grid className="button-wrapper projectInfoBack">
                        <BackButton />
                    </Grid>
                    <h3 className="section-head">{props.project.projectName} </h3>
                </Grid>
                <Grid item xs={12} sm={2}>
                    </Grid>
                {
                    progressVisible &&
                <Grid item xs={12} sm={4}>
                    <ProgressBar style={{ height: '20px' }} className="process-bar" labelVisible={false} value={value} min={0} max={100} />
                    <span className={`k-icon k-i-info k-icon-16 info-icon ${statusInfoClass}`} onClick={() => setShow(!show)} ref={anchor} />
                    <Popup
                        show={show}
                                anchor={anchor && anchor.current}>
                                <JobStatusInfo status={statusText} jobId={jobId} jobStatus={jobStatus} />
                    </Popup>
                </Grid>
                }
                
                
            </Grid>
            <div className="prj-info">
                <Grid container>
                    <Grid item sm={3} xs={8}>
                        <div className="prj-info-row">
                            <strong>Project Status: </strong> {props.project.status}
                        </div>
                    </Grid>
                    <Grid item sm={3} xs={8}>
                        <div className="prj-info-row">
                            <strong>Client: </strong> {props.project.clientName}
                        </div>
                    </Grid>
                    <Grid item sm={3} xs={8}>
                        <div className="prj-info-row">
                            <strong>Start Date: </strong> {formatDate(new Date(props.project.startDate), "MM/dd/yyyy")}
                        </div>
                    </Grid>
                    <Grid item sm={3} xs={8}>
                        <div className="prj-info-row">
                            <strong>End Date: </strong> {formatDate(new Date(props.project.endDate), "MM/dd/yyyy")}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
export default ProjectInfo